import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NewRegionComponent} from '../new-region/new-region.component';
import {Region} from '../../../../../../core/ek-e-commerce/ek-models/region';
import {WilayaListPopUpComponent} from '../wilaya-list-pop-up/wilaya-list-pop-up.component';
import {RegionService} from '../../../../../../core/ek-e-commerce/ek-services/region.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PosListPopUpComponent} from '../pos-list-pop-up/pos-list-pop-up.component';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'kt-region-list',
    templateUrl: './region-list.component.html',
    styleUrls: ['./region-list.component.scss']
})

export class RegionListComponent implements OnInit {

    displayedColumns: string[] = ['id', 'name', 'code', 'user', 'wilaya', 'pos', 'action'];
    dataSource = new MatTableDataSource<Region>([]);
    currentRole = '';
    wilayaSets = [];
    constructor(
                private dialog: MatDialog,
                private regionService: RegionService,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.getListing();

    }

    getListing() {
        this.regionService.getAllRegions().subscribe({
            next: (regions) => {
                this.dataSource.data = regions;
                regions.forEach(region => {
                    region.wilayaList.forEach(wilaya =>  this.wilayaSets.push(wilaya));
                });
                localStorage.setItem('preSelectedWilaya', JSON.stringify(this.wilayaSets));
            }
        });
    }

    addNewRegion() {
        const dialogRef = this.dialog.open(NewRegionComponent,
            {
                data: {
                    idRegion: null
                },
                width: '38rem',
                height: 'auto'
            });
        dialogRef.afterClosed().subscribe((data) => {
            this.getListing();
        });
    }

    editRegion(tempRegion: Region) {
        const dialogRef = this.dialog.open(NewRegionComponent,
            {
                data: {
                    idRegion: tempRegion.id
                },
                width: '38rem',
                height: 'auto'
            });
        dialogRef.afterClosed().subscribe((data) => {
            this.getListing();
        });
    }

    deleteRegion(tempRegion: Region) {
        this.regionService.deleteRegion(tempRegion.id).subscribe({
            next: () => {
                this.getListing();
                const msg = 'la région a été supprimée avec succès';
                this.snackBar.open(msg, 'ok', {
                    panelClass: 'success-snackbar',
                });
            },
            error: () => {

            }
        });
    }

    wilayaList(List: string []) {
        const dialogRef = this.dialog.open(WilayaListPopUpComponent,
            {
                data: {
                    wilayaList: List,
                    fromListing: true
                },
                width: '70rem',
                height: 'auto'
            });
    }
    posList(id: number) {
        const dialogRef = this.dialog.open(PosListPopUpComponent,
            {
                data : {
                    idRegion: id
                },
                width: '40rem',
                height: 'auto'
            });
    }


}
