import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activityTranslate'
})
export class ActivityTranslatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'AGRICULTURE':
        return 'Agriculture Chasse Services Annexes';
      case 'FORESTRY':
        return 'Sylviculture Exploitation Forestière Services Annexes';
      case 'FISHING':
        return 'Pêche Aquaculture';
      case 'COAL_EXTRACTION':
        return 'Extraction de Houille de Lignite et de Tourbe';
      case 'HYDROCARBON_EXTRACTION':
        return 'Extraction d\'Hydrocarbures Services Annexes';
      case 'URANIUM_EXTRACTION':
        return 'Extraction de Minerais d\'Uranium';
      case 'METAL_ORE_EXTRACTION':
        return 'Extraction de Minerais Métalliques';
      case 'OTHER_EXTRACTION':
        return 'Autres Industries Extractives';
      case 'FOOD_INDUSTRIES':
        return 'Industries Alimentaires';
      case 'TOBACCO':
        return 'Industrie du Tabac';
      case 'TEXTILE':
        return 'Industrie Textile';
      case 'CLOTHING':
        return 'Industrie de l\'Habillement et des Fourrures';
      case 'LEATHER':
        return 'Industrie du Cuir et de la Chaussure';
      case 'WOOD':
        return 'Travail du Bois et Fabrication d\'Articles en Bois';
      case 'PAPER':
        return 'Industrie du Papier et du Carton';
      case 'PRINTING':
        return 'Édition Imprimerie Reproduction';
      case 'REFINING':
        return 'Cokéfaction Raffinage Industries Nucléaires';
      case 'CHEMICAL':
        return 'Industrie Chimique';
      case 'RUBBER':
        return 'Industrie de Caoutchouc et des Tiques';
      case 'NON_METALLIC_MINERALS':
        return 'Fabrication d\'Autres Produits Minéraux Non Métalliques';
      case 'METALLURGY':
        return 'Métallurgie';
      case 'METAL_PRODUCTS':
        return 'Travail des Métaux';
      case 'MACHINERY':
        return 'Fabrication de Machines et Équipements';
      case 'OFFICE_MACHINERY':
        return 'Fabrication de Machines de Bureau et de Matériel Informatique';
      case 'ELECTRICAL_MACHINERY':
        return 'Fabrication de Machines et Appareils Électriques';
      case 'RADIO_TV_COMMUNICATION':
        return 'Fabrication d\'Équipements de Radio Télévision et Communication';
      case 'MEDICAL_PRECISION':
        return 'Fabrication d\'Instruments Médicaux de Précision d\'Optique et d\'Horlogerie';
      case 'AUTOMOBILES':
        return 'Industrie Automobile';
      case 'TRANSPORT_EQUIPMENT':
        return 'Fabrication d\'Autres Matériels de Transport';
      case 'FURNITURE':
        return 'Fabrication de Meubles Industries Diverses';
      case 'RECYCLING':
        return 'Récupération';
      case 'ELECTRICITY_GAS':
        return 'Production et Distribution d\'Électricité de Gaz et de Chaleur';
      case 'WATER_SUPPLY':
        return 'Captage Traitement et Distribution d\'Eau';
      case 'CONSTRUCTION':
        return 'Construction';
      case 'AUTO_REPAIR':
        return 'Commerce et Réparation Automobile';
      case 'WHOLESALE':
        return 'Commerce de Gros et Intermédiaires du Commerce';
      case 'RETAIL':
        return 'Commerce de Détail et Réparation d\'Articles Domestiques';
      case 'HOTELS_RESTAURANTS':
        return 'Hôtels et Restaurants';
      case 'LAND_TRANSPORT':
        return 'Transports Terrestres';
      case 'WATER_TRANSPORT':
        return 'Transports par Eau';
      case 'AIR_TRANSPORT':
        return 'Transports Aériens';
      case 'TRANSPORT_AUXILIARY':
        return 'Services Auxiliaires des Transports';
      case 'POST_TELECOMMUNICATIONS':
        return 'Postes et Télécommunications';
      case 'FINANCIAL_INTERMEDIATION':
        return 'Intermédiation Financière';
      case 'INSURANCE':
        return 'Assurance';
      case 'FINANCIAL_AUXILIARY':
        return 'Auxiliaires Financiers et d\'Assurance';
      case 'REAL_ESTATE':
        return 'Activités Immobilières';
      case 'RENTAL':
        return 'Location sans Opérateur';
      case 'INFORMATION_TECH':
        return 'Activités Informatiques';
      case 'RESEARCH_DEVELOPMENT':
        return 'Recherche et Développement';
      case 'BUSINESS_SERVICES':
        return 'Services Fournis Principalement aux Entreprises';
      case 'PUBLIC_ADMINISTRATION':
        return 'Administration Publique';
      case 'EDUCATION':
        return 'Éducation';
      case 'HEALTH_SOCIAL':
        return 'Santé et Action Sociale';
      case 'WASTE_MANAGEMENT':
        return 'Assainissement Voirie et Gestion des Déchets';
      case 'ASSOCIATIONS':
        return 'Activités Associatives';
      case 'CULTURAL_SPORTS':
        return 'Activités Récréatives Culturelles et Sportives';
      case 'PERSONAL_SERVICES':
        return 'Services Personnels';
      case 'DOMESTIC_SERVICES':
        return 'Services Domestiques';
      case 'EXTRA_TERRITORIAL':
        return 'Activités Extra Territoriales';
      case 'INDIVIDUAL':
        return 'Particulier';
      case 'Agriculture Chasse Services Annexes':
        return 'AGRICULTURE';
      case 'Sylviculture Exploitation Forestière Services Annexes':
        return 'FORESTRY';
      case 'Pêche Aquaculture':
        return 'FISHING';
      case 'Extraction de Houille de Lignite et de Tourbe':
        return 'COAL_EXTRACTION';
      case 'Extraction d\'Hydrocarbures Services Annexes':
        return 'HYDROCARBON_EXTRACTION';
      case 'Extraction de Minerais d\'Uranium':
        return 'URANIUM_EXTRACTION';
      case 'Extraction de Minerais Métalliques':
        return 'METAL_ORE_EXTRACTION';
      case 'Autres Industries Extractives':
        return 'OTHER_EXTRACTION';
      case 'Industries Alimentaires':
        return 'FOOD_INDUSTRIES';
      case 'Industrie du Tabac':
        return 'TOBACCO';
      case 'Industrie Textile':
        return 'TEXTILE';
      case 'Industrie de l\'Habillement et des Fourrures':
        return 'CLOTHING';
      case 'Industrie du Cuir et de la Chaussure':
        return 'LEATHER';
      case 'Travail du Bois et Fabrication d\'Articles en Bois':
        return 'WOOD';
      case 'Industrie du Papier et du Carton':
        return 'PAPER';
      case 'Édition Imprimerie Reproduction':
        return 'PRINTING';
      case 'Cokéfaction Raffinage Industries Nucléaires':
        return 'REFINING';
      case 'Industrie Chimique':
        return 'CHEMICAL';
      case 'Industrie de Caoutchouc et des Tiques':
        return 'RUBBER';
      case 'Fabrication d\'Autres Produits Minéraux Non Métalliques':
        return 'NON_METALLIC_MINERALS';
      case 'Métallurgie':
        return 'METALLURGY';
      case 'Travail des Métaux':
        return 'METAL_PRODUCTS';
      case 'Fabrication de Machines et Équipements':
        return 'MACHINERY';
      case 'Fabrication de Machines de Bureau et de Matériel Informatique':
        return 'OFFICE_MACHINERY';
      case 'Fabrication de Machines et Appareils Électriques':
        return 'ELECTRICAL_MACHINERY';
      case 'Fabrication d\'Équipements de Radio Télévision et Communication':
        return 'RADIO_TV_COMMUNICATION';
      case 'Fabrication d\'Instruments Médicaux de Précision d\'Optique et d\'Horlogerie':
        return 'MEDICAL_PRECISION';
      case 'Industrie Automobile':
        return 'AUTOMOBILES';
      case 'Fabrication d\'Autres Matériels de Transport':
        return 'TRANSPORT_EQUIPMENT';
      case 'Fabrication de Meubles Industries Diverses':
        return 'FURNITURE';
      case 'Récupération':
        return 'RECYCLING';
      case 'Production et Distribution d\'Électricité de Gaz et de Chaleur':
        return 'ELECTRICITY_GAS';
      case 'Captage Traitement et Distribution d\'Eau':
        return 'WATER_SUPPLY';
      case 'Construction':
        return 'CONSTRUCTION';
      case 'Commerce et Réparation Automobile':
        return 'AUTO_REPAIR';
      case 'Commerce de Gros et Intermédiaires du Commerce':
        return 'WHOLESALE';
      case 'Commerce de Détail et Réparation d\'Articles Domestiques':
        return 'RETAIL';
      case 'Hôtels et Restaurants':
        return 'HOTELS_RESTAURANTS';
      case 'Transports Terrestres':
        return 'LAND_TRANSPORT';
      case 'Transports par Eau':
        return 'WATER_TRANSPORT';
      case 'Transports Aériens':
        return 'AIR_TRANSPORT';
      case 'Services Auxiliaires des Transports':
        return 'TRANSPORT_AUXILIARY';
      case 'Postes et Télécommunications':
        return 'POST_TELECOMMUNICATIONS';
      case 'Intermédiation Financière':
        return 'FINANCIAL_INTERMEDIATION';
      case 'Assurance':
        return 'INSURANCE';
      case 'Auxiliaires Financiers et d\'Assurance':
        return 'FINANCIAL_AUXILIARY';
      case 'Activités Immobilières':
        return 'REAL_ESTATE';
      case 'Location sans Opérateur':
        return 'RENTAL';
      case 'Activités Informatiques':
        return 'INFORMATION_TECH';
      case 'Recherche et Développement':
        return 'RESEARCH_DEVELOPMENT';
      case 'Services Fournis Principalement aux Entreprises':
        return 'BUSINESS_SERVICES';
      case 'Administration Publique':
        return 'PUBLIC_ADMINISTRATION';
      case 'Éducation':
        return 'EDUCATION';
      case 'Santé et Action Sociale':
        return 'HEALTH_SOCIAL';
      case 'Assainissement Voirie et Gestion des Déchets':
        return 'WASTE_MANAGEMENT';
      case 'Activités Associatives':
        return 'ASSOCIATIONS';
      case 'Activités Récréatives Culturelles et Sportives':
        return 'CULTURAL_SPORTS';
      case 'Services Personnels':
        return 'PERSONAL_SERVICES';
      case 'Services Domestiques':
        return 'DOMESTIC_SERVICES';
      case 'Activités Extra Territoriales':
        return 'EXTRA_TERRITORIAL';
      case 'Particulier':
        return 'INDIVIDUAL';

        //activity domain one
      case 'PUBLIC':
        return 'Publique';
      case 'PRIVATE':
        return 'Privée';
      case 'NOT_CONCERNED':
        return 'Non concerné';

      case 'Publique':
        return 'PUBLIC';
      case 'Privée':
        return 'PRIVATE';
      case 'Non concerné':
        return 'NOT_CONCERNED';

      default:
        return null; // Return null for cases where translation is not found
    }
}

}
