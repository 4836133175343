import {BaseModel} from "../../_base/crud";
import {OrderItemModel} from "./OrderItem.model";
import {DeliveryAddressModel} from "../../e-commerce/_models/delivery-address.model";
import {PointOfSaleModel} from "./point-of-sale.model";

type MonthlyPayment = 'THREE_YEARS' | 'ONE_YEARS' | 'TWO_YEARS';

type Delivery = {
    deliveryType: 'DOMICILE' | 'POINT_DE_RELAIT' | 'NO_DELIVERY' | 'RECUPERATION_AU_NIVEAU_DU_MAGASIN';
    deliveryAddress: DeliveryAddressModel;
    livreurId: number;
    deliveryPrice: number;
}

type totalByConfiguration = {
    itemId: number;
    price: number;
}

export class OrderSplit extends BaseModel {
    id: number;
    clientId: number;
    annuityByOrderSplit: number;
    clientFirstname?: string;
    clientLastname?: string;
    state: string;
    orderItems: OrderItemModel[];
    totalByConfiguration: totalByConfiguration[];
    total: number;
    salarySimulation: number;
    paymentReceipt?: string;
    dateOfBirth: string;
    numberOfMonths: number;
    refundAmount: number;
    folderId: number
    dateOfBirthSimulation?: string;
    pointOfSaleTo?: PointOfSaleModel;
    email?: string;
    phone?: string;
    manufactureName?:string;
}
