import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {OrderSplit} from '../../../../../../core/ek-e-commerce/ek-models/orderSplit';
import {FolderModel, FolderStates} from '../../../../../../core/ek-e-commerce/ek-models/folder.model';
import {FoldersService} from '../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {OrderSplitService} from '../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {FormControl} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {MatDialog} from '@angular/material/dialog';
import {AddCommentComponent} from '../folder-steps/folder-step-eight/add-comment/add-comment.component';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ShippingAddressFolderModel} from '../../../../../../core/ek-e-commerce/ek-models/shipping-address-folder.model';
import {DocumentService} from '../../../../../../core/services/document.service';
import {ocrInfo} from '../../../../../../core/ek-e-commerce/ek-models/document-ocr-information';
import {FolderVerificationService} from '../../../../../../core/services/folder-verification.service';
import {DocumentModel} from '../../../../../../core/ek-e-commerce/ek-models/document.model';
import {MenuConfigService} from '../../../../../../core/_base/layout';


@Component({
    selector: 'kt-folder-process',
    templateUrl: './folder-process.component.html',
    styleUrls: ['./folder-process.component.scss']
})
export class FolderProcessComponent implements OnInit, OnDestroy {

    @Input() orderSplit: OrderSplit;
    @Input() folder: FolderModel;
    @Input() isNewOrder: boolean = false;

    picExists$: Observable<boolean>;

    folderState: FolderStates;
    remarkStatus = new FormControl();
    idFolder = 0;
    idOrder = 0;
    OrdersTitle: string;
    step: number;
    public folderStateObs$: Observable<FolderStates>;

    folderStateLoadObs$ = new BehaviorSubject<boolean>(false);
    readonly loadingState$ = this.folderStateLoadObs$.asObservable();

    shippingAdr: ShippingAddressFolderModel = null;

    currentRole = '';

    ocrInfos: ocrInfo = null;

    bankDocHasCheked = false;

    allOneByOneIsChecked = false;

    documentStates: string [] = [];

    constructor(private route: ActivatedRoute,
                public folderService: FoldersService,
                private orderSplitService: OrderSplitService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
                private documentService: DocumentService,
                public folderVerificationService: FolderVerificationService,
                private menuConfigService: MenuConfigService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.documentService.allOneByOneIsCheckedSubject.subscribe(data => {
            this.allOneByOneIsChecked = data;
        });

        this.documentService.isBankDocCheckedSubject.subscribe(data => {
            this.bankDocHasCheked = data;
        });

        this.documentService.ocrSubject.subscribe(data => {
            this.ocrInfos = data;
        });

        this.route.paramMap.subscribe((res: ParamMap) => {
            this.OrdersTitle = this.route.snapshot.data['title'];
        });

        this.route.queryParams.subscribe((params: any) => {
            this.step = params['step'] ? Number(params['step']) : 1;
        });

        // Empty Order - adding new order
        this.route.paramMap.subscribe(params => {
            this.idFolder = Number(params.get('idFolder'));
            this.idOrder = Number(params.get('idOrder'));
        });

        this.folderVerificationService.documents$.subscribe(docs => {
            if (docs) {
                this.picExists$ = of(!!(docs.find(doc => doc.documentsGender === 'PHOTO_IDENTITY')))
            }
        })

        this.folderService.selectedFolderSubject$.subscribe((res) => {
            if (res) {
                this.folder = res;
                if (!this.isNewOrder) this.folderService.folderStateSubj$.next(res.folderState);
                this.folderStateObs$ = this.folderService.folderStateSubj$.asObservable();
                this.folderState = res.folderState;
            }
        });

        this.folderService.folderStateSubj$.subscribe(state => this.folderState = state);

        //list of documents states which have type different from 'GUARANTEES' & BANK
        this.folderVerificationService.documents$.subscribe({
            next: (documents) => {
                if (documents) {
                    this.documentStates = documents.filter((document: DocumentModel) => document.documentsType !== 'GUARANTEES'
                        && document.documentsType !== 'BANK' && document.documentsType !== 'INVOICE' && document.documentsGender !== 'OTHER').map(item => item.documentsState);
                }
            }
        });

        this.getShippingAddress();
    }


    isValidateButtonDisabled() {
        //if documents are not uploaded and accepted , role credit can not validate folder ...
        if (this.documentStates.length === 0 || this.documentStates.includes('PENDING') || this.documentStates.includes('REFUSED')) {
            return true;
        }
        return false;
    }

    isButtonHidden(status: string) {

        if (this.folderState) {
            switch (status) {
                case 'CANCEL':
                    return this.folderState === 'EK_ADMIN_FOLDER_CANCELED' || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED'
                case 'RETURN':
                    //
                    return !(this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.folderState === 'EK_WAITING_FOR_CLIENT'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
                        || this.folderState === 'CENTRAL_RISK_CONSULTATION') || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole === 'ROLE_GUEST_ADMIN';

                case 'REFUSE':
                    return !(this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PENDING'
                        || this.folderState === 'BANK_NOTIFICATION'
                        || this.folderState === 'CENTRAL_RISK_CONSULTATION') || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole === 'ROLE_GUEST_ADMIN';
                case 'VALIDATE':

                    //if document non verified , pos can not validate folder
                    if (this.currentRole === 'ROLE_POS_EK' && this.folderState === 'EK_FOLDER_CREATED' && (!this.ocrInfos || !this.allOneByOneIsChecked)
                        || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole === 'ROLE_GUEST_ADMIN') {
                        return true;
                        //|| !this.bankDocHasCheked
                    }
                    if (this.currentRole === 'ROLE_BANKER_EK' && this.folderState === 'EK_BANK_RESERVE') {
                        return true;
                    }

                    if (this.currentRole === 'ROLE_CONFORMITY_MANAGER' && this.folderState != 'EK_PHYSICAL_FOLDER_IN_PROGRESS' && this.folderState != 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY' && this.folderState != 'EK_PHYSICAL_FOLDER_IN_DELIVERY') {
                        return true;
                    }

                    if ((this.currentRole === 'ROLE_ADMIN' || this.currentRole === 'ROLE_SUPERADMIN') &&
                        (
                            this.folderState === 'CLIENT_CENTRAL_RISK_NOTIFICATION' ||
                            this.folderState === 'BANK_PAYMENT_CONFIRMATION'
                        )
                    ) {
                        return false;
                    }
                    return !(
                        this.folderState === 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
                        || this.folderState === 'FINANCIAL_FOLDER'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_REFUSED'
                        || this.folderState === 'EK_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_IN_PROGRESS'
                        || this.folderState === 'EK_FOLDER_RESERVE'
                        || this.folderState === 'EK_BANK_RESERVE'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_RESERVE'
                        || this.folderState === 'EK_FOLDER_CREATED'
                        || this.folderState === 'EK_GUARTENTEES_PENDING_SIGNATURE'
                        || this.folderState === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PENDING'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_DELIVERED'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_PICKEDUP'
                        || this.folderState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.folderState === 'CENTRAL_RISK_CONSULTATION'
                        || this.folderState === 'BANK_NOTIFICATION'
                        // || this.folderState === 'BANK_PAYMENT_CONFIRMATION'
                        || this.folderState === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING');
            }

        }
    }


    getShippingAddress() {
        this.folderService.shippingSbj$.subscribe(value => {
            this.shippingAdr = value;
        });
    }

    //first state : EK_FOLDER_IN_PROGRESS
    changeOrderStatus(status: string) {

        this.folderStateLoadObs$.next(true);

        switch (status) {

            //refuse folder
            case 'REFUSE' :

                switch (this.folder.folderState) {
                    case 'CENTRAL_RISK_CONSULTATION' :
                        if (this.folderService.bankFeedback$.value) this.sendFeedback(this.folderService.bankFeedback$.value);
                        break;
                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        // supposed res :
                        this.changeState('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_REFUSED');
                        break;
                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res :
                        this.changeState('EK_BANK_REFUSE_FOLDER');
                        break;
                    default :
                        this.openDialog();
                        break;
                }
                break;

            //return folder
            case 'RETURN' :
                switch (this.folder.folderState) {

                    case 'EK_FOLDER_IN_PROGRESS':
                        //supposed res : EK_FOLDER_RESERVE 
                        // this.folderService.changeState(this.folder.id, 'EK_RETURN_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_RETURN_FOLDER');

                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : EK_BANK_RESERVE
                        // this.folderService.changeState(this.folder.id, 'EK_BANK_RETURN_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_BANK_RETURN_FOLDER');
                        break;

                    case 'CENTRAL_RISK_CONSULTATION':
                        // supposed res : EK_BANK_RESERVE
                        this.sendFeedback('COMPLEMENT_REQUEST')
                        // this.openDialog('CENTRAL_RISK_CONSULTATION_TO_EK_BANK_RESERVE');
                        break;

                    case 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK':
                        //supposed res : EK_WAITING_FOR_CLIENT
                        // this.folderService.changeState(this.folder.id, 'EK_WAIT_FOR_CLIENT').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_WAIT_FOR_CLIENT');
                        break;

                    case 'EK_WAITING_FOR_CLIENT':
                        //supposed res : EK_CLIENT_NO_RESPONSE
                        // this.folderService.changeState(this.folder.id, 'EK_CLIENT_NOT_RESPONDING').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_CLIENT_NOT_RESPONDING');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_RESERVE
                        // this.folderService.changeState(this.folder.id, 'EK_BANK_RETURN_PHYSICAL_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_RESERVE');
                        break;

                    case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
                        // supposed res : EK_BANK_RESERVE
                        // this.folderService.changeState(this.folder.id, 'EK_BANK_RETURN_FOLDER').subscribe((e) => this.updateOrderState(e));
                        this.openDialog('EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS');
                        break;
                }
                break;

            //validate folder
            case 'VALIDATE' :
                switch (this.folder.folderState) {

                    case 'EK_FOLDER_CREATED':
                        //supposed res : EK_FOLDER_IN_PROGRESS
                        this.changeState('EK_IN_PROGRESS_FOLDER');
                        break;

                    case 'EK_FOLDER_IN_PROGRESS':
                        //supposed res : CENTRAL_RISK_CONSULTATION
                        this.changeState('EK_FOLDER_IN_PROGRESS_TO_CENTRAL_RISK_CONSULTATION');
                        // this.folderService.changeState(this.folder.id, 'EK_VALIDATE_FOLDER').subscribe((e) => this.updateOrderState(e));
                        break;

                    case 'CENTRAL_RISK_CONSULTATION':
                        //supposed res : CLIENT_CENTRAL_RISK_NOTIFICATION
                        this.sendFeedback('FINANCEABLE_CLIENT');
                        break;

                    case 'CLIENT_CENTRAL_RISK_NOTIFICATION':
                        //supposed res : EK_BANK_IN_PROGRESS
                        this.changeState('CLIENT_CENTRAL_RISK_NOTIFICATION_TO_EK_BANK_IN_PROGRESS');
                        break;

                    case 'EK_FOLDER_RESERVE':
                        //supposed res : EK_FOLDER_IN_PROGRESS
                        this.changeState('EK_FOLDER_RESERVE_TO_EK_FOLDER_IN_PROGRESS');
                        // this.folderService.changeState(this.folder.id, 'EK_VALIDATE_FOLDER').subscribe((e) => this.updateOrderState(e));
                        break;

                    // case 'EK_BANK_IN_PROGRESS':
                    //     //supposed res : EK_GUARTENTEES_PENDING_SIGNATURE
                    //     this.changeState('EK_WAITING_FOR_GUARANTIES_SIGNATURE');
                    //     break;

                    // case 'EK_BANK_RESERVE':
                    //     // supposed res : EK_BANK_IN_PROGRESS
                    //     this.changeState('EK_MODIFY_FOLDER');
                    //     break;

                    case 'EK_BANK_RESERVE':
                        // supposed res : CENTRAL_RISK_CONSULTATION
                        this.folder.folderPreviousState === 'CENTRAL_RISK_CONSULTATION' ?
                            this.changeState('EK_BANK_RESERVE_TO_CENTRAL_RISK_CONSULTATION') :
                            this.changeState('EK_REVERSE_BANK_RESERVE_TO_BANK_IN_PROGRESS')
                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : BANK_NOTIFICATION
                        this.changeState('EK_BANK_IN_PROGRESS_TO_BANK_NOTIFICATION');
                        break;

                    case 'BANK_NOTIFICATION':
                        // supposed res : EK_CONTROL_BEFORE_GUARANTEES_PRINTING
                        this.changeState('BANK_NOTIFICATION_TO_EK_CONTROL_BEFORE_GUARANTEES_PRINTING ');
                        break;

                    case 'BANK_PAYMENT_CONFIRMATION':
                        // supposed res : FINANCIAL_FOLDER
                        this.changeState('BANK_PAYMENT_CONFIRMATION_TO_FINANCIAL_FOLDER');
                        break;

                    case 'FINANCIAL_FOLDER':
                        // supposed res : EK_PHYSICAL_FOLDER_IN_DELIVERY
                        this.changeState('FINANCIAL_FOLDER_TO_EK_PHYSICAL_FOLDER_IN_DELIVERY');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_REFUSED':
                        // supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.changeState('EK_BANK_PHYSICAL_FOLDER_REFUSED_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;

                    case 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING':
                        // supposed res : EK_GUARTENTEES_PENDING_SIGNATURE
                        this.changeState('EK_CONTROL_BEFORE_GUARANTEES_PRINTING_TO_EK_GUARTENTEES_PENDING_SIGNATURE')
                        break;

                    case 'EK_GUARTENTEES_PENDING_SIGNATURE':
                        //supposed res : EK_GUARTENTEES_SIGNED_IN_PROGRESS
                        this.changeState('EK_WAITING_FOR_GUARANTIES_VALIDATION');
                        break;

                    case 'EK_GUARTENTEES_SIGNED_IN_PROGRESS':
                        //supposed res : EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS
                        this.changeState('EK_GUARTENTEES_SIGNED_IN_PROGRESS_TO_EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS');
                        break;

                    case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
                        //supposed res : BANK_PAYMENT_CONFIRMATION
                        this.changeState('EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS_TO_BANK_PAYMENT_CONFIRMATION');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_RESERVE':
                        //supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.changeState('EK_REVERSE_BANK_PHYSICAL_FOLDER_RESERVE_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;

                    case 'EK_PHYSICAL_FOLDER_PENDING':

                        if (this.shippingAdr) {

                            //treat folder by YALIDINE service
                            if (this.folder.shippingAddressFolderId && this.shippingAdr.fileShippingType === 'RELAY_POINT' || this.shippingAdr.fileShippingType === 'RESIDENCE') {

                                //supposed res : EK_PHYSICAL_FOLDER_WAITING_FOR_PICK
                                this.changeState('EK_WAITING_FOR_PHYSICAL_FOLDER_PICKUP');

                            } else {
                                //supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                                this.changeState('EK_PHYSICAL_FOLDER_PROCESSING');
                            }

                        } else {

                            this.folderStateLoadObs$.next(false);
                            const message = `Vous devez d'abord sélectionner la méthode d'expédition du dossier physique !`;
                            this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 5000, false, false);
                        }

                        break;

                    case 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK':
                        //supposed res : EK_PHYSICAL_FOLDER_PICKEDUP
                        this.changeState('EK_PHYSICAL_FOLDER_PICKING');
                        break;

                    case 'EK_PHYSICAL_FOLDER_PICKEDUP':
                        //supposed res : EK_PHYSICAL_FOLDER_IN_DELIVERY
                        this.changeState('EK_PHYSICAL_FOLDER_TO_DELIVERY');
                        break;

                    case 'EK_PHYSICAL_FOLDER_IN_DELIVERY':
                        //supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.changeState('EK_PHYSICAL_FOLDER_IN_DELIVERY_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;

                    case 'EK_PHYSICAL_FOLDER_DELIVERED':
                        //supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.changeState('EK_DELIVERED_PHYSICAL_FOLDER_PROCESSING');
                        break;

                    case 'EK_PHYSICAL_FOLDER_IN_PROGRESS':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY
                        this.changeState('EK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.changeState('EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY_TO_EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_ACCEPTED
                        this.changeState('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_ACCEPTED');
                        break;
                }
                break;
        }
    }


    changeState(state: string) {
        this.folderService.changeState(this.folder.id, state).subscribe({
            next: (res) => {
                this.updateOrderState(res);
                this.folderStateLoadObs$.next(false);

                //reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();
            },
            error: (error) => {

                if (error.error.code === 401) {
                    this.folderStateLoadObs$.next(false);
                    const message = `Vous pouvez pas faire cette opération !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
                if (error.status === 403) {
                    this.folderStateLoadObs$.next(false);
                    const message = `Vous pouvez pas changer le statut !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            },
        });
    }

    sendFeedback(feedback: string) {
        this.folderService.sendFeedback(this.folder.id, feedback).subscribe({
            next: (res) => {
                this.folderService.folderStateSubj$.next(res as FolderStates)
                this.folderStateLoadObs$.next(false);
                this.refresh();
            },
            error: (error) => {

                if (error.error.code === 401) {
                    this.folderStateLoadObs$.next(false);
                    const message = `vous pouvez pas faire cette opération !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }

                if (error.status === 403) {
                    this.folderStateLoadObs$.next(false);
                    const message = `vous pouvez pas changer le statut !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            },
        });
    }

    updateOrderState(folderState: FolderStates) {
        //change order split state
        switch (folderState) {
            case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED':
                //prepare order
                this.changeStateOrder('EK_PREPARE_ORDER');
                break;
            case 'EK_BANK_REFUSED' || 'EK_FOLDER_REFUSED' || 'EK_PHYSICAL_FOLDER_REFUSED':
                //cancel order split
                this.cancelOrderSplit();
                break;
        }

        this.refresh();
    }

    refresh() {
        this.folderService.getFolderById(this.folder.id).subscribe(res => {
            if (res) {
                this.folderService.folderStateSubj$.next(res.folderState)
                this.folderState = res.folderState;
                this.folderService.selectedFolderSubject$.next(res);
                this.folderStateLoadObs$.next(false);
                this.router.navigateByUrl('ek-ecommerce/folder/edit/' + this.idOrder + '/' + this.idFolder + '?step=' + this.step);
            }
        });

        //reload aside left folders list
        this.getFoldersStatisticsForMenuConfigs();
    }

    changeStateOrder(orderState) {
        this.orderSplitService.changeStateOrderSplit(this.idOrder, orderState).subscribe(res => {
            if (res) {
                const message = `le statut de la commande a été bien modifié!`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        });
    }

    getFoldersStatisticsForMenuConfigs() {

        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.menuConfigService.statisticsSubject$.next(null as any);
                this.menuConfigService.statisticsSubject$.next(res.countFolderState);
            }
        });

        if (this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_BANKER_EK'
        ) {
            this.folderService.GlobalFolderStatistics().subscribe({
                next: (res) => {
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
                }
            });
        }


    }


    cancelOrderSplit() {

        let date = new Date();

        if (this.remarkStatus.value) {
            let cancelObj = {
                cancellingDate: date.toISOString(),
                cancellingReason: this.remarkStatus.value
            }

            //return status : EK_CLIENT_ORDER_CANCELED
            this.orderSplitService.cancelOrderSplit(this.idOrder, cancelObj).subscribe(res => {
                if (res) {
                    const message = `le statut de la commande a été bien annulée!`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            });
        }

    }

    openDialog(nextFolderState?: string) {
        this.folderStateLoadObs$.next(true);
        let title = 'Motif d\'annulation';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: true,
                    idFolder: this.idFolder,
                    idOrder: this.idOrder,
                    title,
                    folderState: this.folder.folderState,
                    nextFolderState,
                }
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            this.folderStateLoadObs$.next(false);
            this.refresh();
        });
    }

    cancelFolder(): void {

        this.folderStateLoadObs$.next(true);
        const title = 'Motif d\'annulation';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: false,
                    idFolder: this.idFolder,
                    idOrder: this.idOrder,
                    title,
                    folderState: this.folder.folderState,
                    nextFolderState: null,
                    isCancelFolder: true
                }
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            this.folderStateLoadObs$.next(false);
            this.refresh();
        });

    }

    ngOnDestroy() {
        this.folderService.folderStateSubj$.next(null);
        this.picExists$ = null as any
    }
}
