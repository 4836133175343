import {createAction, props} from "@ngrx/store";
import {ConfigurationModel} from "../ek-models/configuration.model";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";

export const search = createAction('[Configuration2 List Page] Configurations22 Page Requested ',props<{page: QueryParamsModel,active:boolean,categoryId:number, includeInOrderSplit: boolean}>());

export const ConfigurationsPageRequested = createAction('[Configuration2 List Page] Configurations Page Requested ',props<{page: QueryParamsModel, categoryIdFilter:number}>());
export const ConfigurationsPageLoadedSuccessfully = createAction('[Configuration2 API] Configurations Page Loaded Successfully ',props<{configurations: ConfigurationModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const ConfigurationsPageLoadFailed = createAction('[Configuration2 API] Configurations Page Request Failed ',props<{error: any}>());

export const ConfigurationCreated = createAction('[Configuration2 List Page] Configuration Created ',props<{configuration: ConfigurationModel}>());
export const ConfigurationCreatedSuccessfully = createAction('[Configuration2 List Page] Configuration Created Successfully ',props<{configuration: ConfigurationModel}>());
export const ConfigurationCreationFailed = createAction('[Configuration2 List Page] Configuration Creation Failed ',props<{error: any}>());

export const ConfigurationUpdated = createAction('[Configuration2 List Page] Configuration Updated ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const ConfigurationUpdatedSuccessfully = createAction('[Configuration2 List Page] Configuration Updated Successfully ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const ConfigurationUpdateFailed = createAction('[Configuration2 List Page] Configuration Update Failed ',props<{error: any}>());

export const ConfigurationUpdatedActivate = createAction('[Configuration2 List Page] Configuration Updated',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>, activate:boolean,updater:string,event: any}>());
export const ConfigurationUpdatedActivateSuccessfully = createAction('[Configuration2 List Page] Configuration Updated Successfully',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>,activate:boolean,updater:string}>());
export const ConfigurationUpdateActivateFailed = createAction('[Configuration2 List Page] Configuration Update Failed',props<{error: any}>());

export const ConfigurationDeleted = createAction('[Configuration2 List Page] Configuration Deleted ',props<{configurationId: number}>());
export const ConfigurationDeletedSuccessfully = createAction('[Configuration2 List Page] Configuration deleted Successfully ',props<{configurationId: number}>());
export const ConfigurationDeleteFailed = createAction('[Configuration2 List Page] Configuration delete Failed ',props<{error: any}>());

export const ConfigurationsFilterByDateRequested = createAction('[Configuration2 List Page] Configurations Page Filtered By Date Requested ',props<{page: QueryParamsModel, startDate: string, endDate: string}>());
export const ConfigurationsFilterByDateLoadedSuccessfully = createAction('[Configuration2 API] Configurations Page Filtered By Date Loaded Successfully ',props<{configurations: ConfigurationModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const ConfigurationsFilterByDateLoadFailed = createAction('[Configuration2 API] Configurations Page Filtered By Date Request Failed ',props<{error: any}>());

export const DiscountPageRequested = createAction(' [Discount List Page] Discount n Page Requested ', props<{ page: QueryParamsModel ,endingDate:string,startingDate:string,state:string}>());
export const DiscountLoadedSuccessfully = createAction('[Discount API] Discount n Page Loaded Successfully ',props<{Discount: ConfigurationModel[],totalCount: number, page: number}>());
export const DiscountLoadFailed = createAction('[Discount API] Discount Page n Request Failed ',props<{error: any}>());

export const VenteFlashPageRequested = createAction(' [VenteFlash List Page] VenteFlash n Page Requested ', props<{ page: QueryParamsModel ,endingDate:string,startingDate:string,active:string}>());
export const VenteFlashLoadedSuccessfully = createAction('[VenteFlash API] VenteFlash n Page Loaded Successfully ',props<{VenteFlash: ConfigurationModel[],totalCount: number, page: number}>());
export const VenteFlashLoadFailed = createAction('[VenteFlash API] VenteFlash Page n Request Failed ',props<{error: any}>());

export const PromotionUpdatedActivate = createAction('[Promotion List Page] Promotion Updated activate',props<{Promotion: ConfigurationModel, partialPromotion: Update<ConfigurationModel>, activate:boolean,}>());
export const PromotionUpdatedActivateSuccessfully = createAction('[Promotion List Page] Promotion Updated Successfully ',props<{Promotion: ConfigurationModel, partialPromotion: Update<ConfigurationModel>,activate:boolean,}>());
export const PromotionUpdateActivateFailed = createAction('[Promotion List Page] Promotion Update Failed ',props<{error: any}>());

export const displayConfiguration = createAction('[Configuration2 List Page] Configuration Display Update',props<{configurationId:number, display:boolean}>());
export const displayConfigurationSuccessfully = createAction('[Configuration2 List Page] Configuration Display Updated Successfully',props<{display:boolean}>());
export const displayConfigurationFailed = createAction('[Configuration2 List Page] Configuration Display Update Failed',props<{error: any}>());