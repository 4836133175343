import {BaseModel} from "../../_base/crud";

export class EkFooterInformationModel extends BaseModel {
      id: number;
      address:string;
      addressArabic:string;
      legalMentionUrl:string;
      generalConditionUrl:string;
      faqFileName:string;
      ekInfoEmail:	string;
      ekPhoneNumber	:string;
      ekPhoneNumber2:	string;
      urlGps:string;
}
