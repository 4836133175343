import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ChangeHistoryState} from "../ek-reducers/change-history.reducers";
import {ChangeHistoryModel} from "../ek-models/change-history-model";


export const selectCHState = createFeatureSelector<ChangeHistoryState>('change-history');

export const selectPOSById = (posId: number) => createSelector(
    selectCHState,
    CHState => {
        if (CHState) return CHState.entities[posId];
    }
);

export const selectCHPageLoading = createSelector(
    selectCHState,
    CHState => CHState.listLoading
);

export const selectError = createSelector(
    selectCHState,
    CHState => CHState.error
);

export const selectLastAction = createSelector(
    selectCHState,
    CHState => CHState.lastAction
);


export const selectCHActionLoading = createSelector(
    selectCHState,
    CHState => CHState.actionsLoading
);

export const selectLastCreatedPOSId = createSelector(
    selectCHState,
    CHState => CHState.lastCreatedPOSId
);

export const selectCHShowInitWaitingMessage = createSelector(
    selectCHState,
    CHState => CHState.showInitWaitingMessage
);

export const selectCHPageLastQuerys = createSelector(
    selectCHState,
    CHState => CHState.lastQuery
);

export const selectCHInStore = createSelector(
    selectCHState,
    CHState => {
        const items: ChangeHistoryModel[] = [];
        each(CHState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ChangeHistoryModel[] = httpExtension.sortArray(items, CHState.lastQuery.sortField, CHState.lastQuery.sortOrder);
        return new QueryResultsModel(result, CHState.totalCount, '');
    }
);
