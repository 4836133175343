import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormControl} from "@angular/forms";
import {fromEvent, merge, Subscription} from "rxjs";
import {User} from "../../../../../core/auth";
import {MatDialog} from "@angular/material/dialog";
import {NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Actions, ofType} from "@ngrx/effects";
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../core/reducers";
import {QueryParamsModel} from "../../../../../core/_base/crud";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import * as UserActions from "../../../../../core/auth/_actions/user.actions";
import {SmsHistoryModel} from "../../../../../core/ek-e-commerce/ek-models/sms-history-model";
import {SmsHistoryDetailsComponent} from "../sms-history-details/sms-history-details.component";
import {SmsHistoryDatasource} from "../../../../../core/ek-e-commerce/ek-data-sources/smsHistory.datasource";
import {SmsHistoryPageRequested} from "../../../../../core/ek-e-commerce/ek-actions/sms-history.actions";

@Component({
  selector: 'kt-history-sms',
  templateUrl: './history-sms.component.html',
  styleUrls: ['./history-sms.component.scss']
})
export class HistorySmsComponent implements OnInit {
  viewLoading$ = null;
  listingTitle = "";
  dataSource: SmsHistoryDatasource;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  //paging table
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  //sorting table
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['id', 'phone', 'createdAt', 'responseApi', 'action'];

  filterByDateActivated = false;
  selectedFolderState = new FormControl();
  selectedUser = new FormControl();
  dateRange = new FormControl({begin: null, end: null});

  private subscriptions: Subscription[] = [];
  users: User[] = [];
  currentRole: string = '';

  constructor(public dialog: MatDialog,
              public formatter: NgbDateParserFormatter,
              private activatedRoute: ActivatedRoute,
              private actions$: Actions,
              private datePipe: DatePipe,
              private store: Store<AppState>
              ) {
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
  }
  ngOnInit(): void {
    //init dataSource
    this.dataSource = new SmsHistoryDatasource(this.store);
    this.paginator._changePageSize(50);

    this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
      this.listingTitle = this.activatedRoute.snapshot.data['title'];
      this.getAllPaged();
    });

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        map(() => {
          this.paginator.pageIndex = 0;
          this.getAllPaged();
        })).subscribe();
    this.subscriptions.push(searchSubscription);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => {
          this.getAllPaged();
        })
    ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    this.fieldListener();

    this.loadUsers();

  }
  getAllPaged() {

    const user = this.users.find(i => i.id === this.selectedUser.value);

    const queryParams = new QueryParamsModel(
        this.searchInput.nativeElement.value,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize,
    );

    this.store.dispatch(SmsHistoryPageRequested({
      page: queryParams,
      firstDate: this.dateRange.value?.begin ? this.formatDate(this.dateRange.value.begin) : null,
      lastDate: this.dateRange.value?.end ? this.formatDate(this.dateRange.value.end) : null,
    }));

  }
  loadUsers() {

    const queryParams = new QueryParamsModel(
        "",
        "",
        "",
        0,
        1000
    );

    this.store.dispatch(
        UserActions.AllUsersRequested({
          page: queryParams,
          role: "",
          key: ""
        }));

    this.actions$.pipe(ofType(UserActions.AllUsersLoaded)).subscribe((data) => {
      if (data) {
        this.users = data.users;
      }
    });
  }
  fieldListener() {

    this.selectedFolderState.valueChanges.subscribe(value => {
      this.getAllPaged();
    });

    this.dateRange.valueChanges.subscribe(value => {
      this.getAllPaged();
    });

    this.selectedUser.valueChanges.subscribe(value => {
      this.getAllPaged();
    });

  }
  formatDate(date_: Date) {
    const date = this.datePipe.transform(date_, 'yyyy-MM-dd');
    return date;
  }

  openHistorySmsDetails(item: SmsHistoryModel) {

    let dialogRef = this.dialog.open(SmsHistoryDetailsComponent, {
      data: {
        folderId: item.folderId,
/*        responseApi: item.responseApi,
        createdAt: item.createdAt,
        phone:item.phone,*/
      },
      width: "80rem",
      // panelClass: 'history-details'
    });
  }

}
