import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ConfigurationService} from "../../ek-e-commerce/ek-services/confguration.service";
import * as ConfigurationsActions from "../_actions/products-best-seller.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class ProductsBestSellerEffects {


    constructor(private actions$: Actions, private configurationService: ConfigurationService) {
    }
    BestSellerPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.BestSellerPageRequested),
            switchMap((action) =>
                this.configurationService.getBestSellers(action.page.pageNumber, action.page.pageSize, action.page.filter)
                    .pipe(
                        map(bestSellerPage => ConfigurationsActions.BestSellerPageLoadedSuccessfully(
                            {
                                bestSeller: bestSellerPage.body.content,
                                page: bestSellerPage.body.totalElements,
                                totalCount: bestSellerPage.body.totalElements
                            })),
                        catchError(error => of(ConfigurationsActions.BestSellerPageLoadFailed({error})))
                    ))));

    BestSellerUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.BestSellerUpdated),
            switchMap((action) =>
                this.configurationService.changeDisplay(action.bestSellerId, action.display, action.updater)
                    .pipe(
                        map(bestSeller => ConfigurationsActions.BestSellerUpdatedSuccessfully({
                            bestSeller: bestSeller,
                            partialBestSeller: action.partialBestSeller
                        })),
                        catchError(error => of(ConfigurationsActions.BestSellerUpdateFailed({error})))
                    )
            )
        ));
}
