import {createAction, props} from "@ngrx/store";
import {ImageModel} from "../ek-models/image.model";
import {Update} from "@ngrx/entity";

export const ImagesPageRequested = createAction('[Image List Page] Images Page Requested ',props<{page: number, size: number}>());
export const ImagesPageLoadedSuccessfully = createAction('[Image API] Images Page Loaded Successfully ',props<{images: ImageModel[],totalCount: number, page: number}>());
export const ImagesPageLoadFailed = createAction('[Image API] Images Page Request Failed ',props<{error: any}>());

export const ImageCreated = createAction('[Image List Page] Image Created ',props<{image: ImageModel}>());
export const ImageCreatedSuccessfully = createAction('[Image List Page] Image Created Successfully ',props<{image: ImageModel}>());
export const ImageCreationFailed = createAction('[Image List Page] Image Creation Failed ',props<{error: any}>());
export const ImageUpdatedOrder = createAction('[Image List Page] Image Updated order',props<{image: ImageModel, partialImage: Update<ImageModel>, order:number, position : number}>());
export const ImageUpdatedOrderSuccessfully = createAction('[Image List Page] Image Updated Successfully ',props<{image: ImageModel, partialImage: Update<ImageModel>,order:number}>());
export const ImageUpdatedOrderFailed = createAction('[Image List Page] Image Update Failed ',props<{error: any}>());


export const ImageUpdated = createAction('[Image List Page] Image Updated ',props<{image: ImageModel, partialImage: Update<ImageModel>}>());
export const ImageUpdatedSuccessfully = createAction('[Image List Page] Image Updated Successfully ',props<{image: ImageModel, partialImage: Update<ImageModel>}>());
export const ImageUpdateFailed = createAction('[Image List Page] Image Update Failed ',props<{error: any}>());

export const ImageDeleted = createAction('[Image List Page] Image Deleted ',props<{imageId: number}>());
export const ImageDeletedSuccessfully = createAction('[Image List Page] Image deleted Successfully ',props<{imageId: number}>());
export const ImageDeleteFailed = createAction('[Image List Page] Image delete Failed ',props<{error: any}>());


