import {Component, Input, OnInit} from '@angular/core';
import {ScoringModel} from "../../../../../../../core/ek-e-commerce/ek-models/scoring.model";

@Component({
    selector: 'kt-statistics-scoring',
    templateUrl: './statistics-scoring.component.html',
    styleUrls: ['./statistics-scoring.component.scss']
})
export class StatisticsScoringComponent implements OnInit {

    @Input() folderScore: ScoringModel;

    red = "#ff6261";
    orange = "#ff893c";
    yellow = "#ffd127";
    blue = "#0294fa";
    green = "#3eda91";

    applicantIdentificationScoreColor = "";
    professionalSituationScoreColor = "";
    StateInstructionScoreColor = "";
    financialSituationColor = "";

    constructor() {
    }

    ngOnInit(): void {
    }


    applicantIdentificationScore(score: any) {
        if (score <= 5) {
            this.applicantIdentificationScoreColor = this.red;
            return "FAIBLE";
        } else if (score <= 10) {
            this.applicantIdentificationScoreColor = this.orange;
            return "MOYENNE";
        } else if (score <= 15) {
            this.applicantIdentificationScoreColor = this.blue;
            return "BIEN";
        } else if (score <= 20) {
            this.applicantIdentificationScoreColor = this.green;
            return "EXCELLENT";
        }
        return "Invalid Score"; // outside the range 1-20 if needed.
    }

    professionalSituationScore(score: any) {
        if (score <= 6) {
            this.professionalSituationScoreColor = this.red;
            return "FAIBLE";
        } else if (score <= 12) {
            this.professionalSituationScoreColor = this.orange;
            return "MOYENNE";
        } else if (score <= 18) {
            this.professionalSituationScoreColor = this.blue;
            return "BIEN";
        } else if (score <= 24) {
            this.professionalSituationScoreColor = this.green;
            return "EXCELLENT";
        }
        return "Invalid Score"; // outside the range 1-24
    }

    financialSituationScore(score: any) {
        if (score <= 13) {
            this.financialSituationColor = this.red;
            return "FAIBLE";
        } else if (score <= 26) {
            this.financialSituationColor = this.orange;
            return "MOYENNE";
        } else if (score <= 39) {
            this.financialSituationColor = this.blue;
            return "BIEN";
        } else if (score <= 52) {
            this.financialSituationColor = this.green;
            return "EXCELLENT";
        }
        return "Invalid Score"; // outside the range 1-52
    }


    setStateInstructionScore(score: number) {

        let result = "Invalid Score"; // Default value

        // Use a switch statement to evaluate the value of 'score'
        switch (score) {
            case 1:
                this.StateInstructionScoreColor = this.red; // Set color to red
                result = "FAIBLE"; // Update result to "FAIBLE"
                break;
            case 2:
                this.StateInstructionScoreColor = this.orange; // Set color to orange
                result = "MOYENNE"; // Update result to "MOYENNE"
                break;
            case 3:
                this.StateInstructionScoreColor = this.blue; // Set color to blue
                result = "BIEN"; // Update result to "BIEN"
                break;
            case 4:
                this.StateInstructionScoreColor = this.green; // Set color to green
                result = "EXCELLENT"; // Update result to "EXCELLENT"
                break;
        }

        return result; // Add this line to return the result
    }

}