export const BANKER_STATES = [
    'CENTRAL_RISK_CONSULTATION',
    'EK_BANK_IN_PROGRESS',
    'BANK_NOTIFICATION',
    'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY',
    'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS',
    'EK_BANK_PHYSICAL_FOLDER_ACCEPTED',
    'EK_BANK_PHYSICAL_FOLDER_RESERVE',
    'EK_BANK_PHYSICAL_FOLDER_REFUSED',
    'EK_BANK_RESERVE',
    'EK_BANK_REFUSED',
    'BANK_PAYMENT_CONFIRMATION'
];


