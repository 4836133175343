import {EkSnackBarService} from '../../../../../../core/services/ek-snackBar.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DatePipe} from '@angular/common';
import {ApplicationRef, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {BehaviorSubject, fromEvent, merge, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';

import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
import {Wilaya, WilayaService} from '../../../../../../core/ek-e-commerce/ek-services/wilaya.service';
import {foldersListingPageRequested} from '../../../../../../core/ek-e-commerce/ek-actions/folder.action';
import {FoldersDatasource} from '../../../../../../core/ek-e-commerce/ek-data-sources/folders.datasource';
import {ListingModel} from '../../../../../../core/ek-e-commerce/ek-models/listing-model';
import {PointOfSaleModel} from '../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model';
import {FolderStatesSelected} from '../../../../../../core/ek-e-commerce/ek-models/folders-states-selected.model';
import {OrderSplitService} from '../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {PointOfSaleService} from '../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service';
import {AppState} from '../../../../../../core/reducers';
import {ClientsListPopUpComponent} from '../clients-list-pop-up/clients-list-pop-up.component';
import {OrderSplitChangeStateComponent} from '../order-split-change-state/order-split-change-state.component';
import {CancelingReasonPopUpComponent} from './canceling-reason-pop-up/canceling-reason-pop-up.component';
import {AnalystListPopupComponent} from './analyst-list-popup/analyst-list-popup.component';
import {FolderStateChangeComponent} from '../folder-state-change/folder-state-change.component';
import {ORDER_SPLIT_STATE} from '../../../Shared/Constants/orderSplit-states';
import {FoldersService} from '../../../../../../core/ek-e-commerce/ek-index';
import {FolderStates} from '../../../../../../core/ek-e-commerce/ek-models/folder.model';
import {MenuConfigService} from '../../../../../../core/_base/layout';
import {
    DownloadFileDialoguComponent
} from '../folder-steps/folder-step-two/download-file-dialogu/download-file-dialogu.component';
import {AddCommentComponent} from '../folder-steps/folder-step-eight/add-comment/add-comment.component';
import {
    FolderStateHistoryDetailsComponent
} from '../change-history/folder-state-history-details/folder-state-history-details.component';
import {CommentsPopUpComponent} from '../comments-pop-up/comments-pop-up.component';
import {BANKER_STATES} from "../../../../../../../assets/constancies/banker.states";
import {ENUMERATE_FOLDER_STATES} from "../../../Shared/Constants/enumerateFolderStates";
import {ExcelExportService} from "../../../../../../core/ek-e-commerce/ek-services/excel-export.service";


@Component({
    selector: 'kt-folders-list',
    templateUrl: './folders-list.component.html',
    styleUrls: ['./folders-list.component.scss'],
})
export class FoldersListComponent implements OnInit {
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // paging table
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    // sorting table
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    viewLoading$ = null;
    public listingTitle: String;
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;
    filterByDateActivated = false;
    showDatePicker = true;
    loadingData = false;
    // mat table
    dataSource: FoldersDatasource;
    selection = new SelectionModel<ListingModel>(true, []);
    foldersResult: ListingModel[] = [];
    wilayas: Wilaya[];
    folderStatesSelected: FolderStatesSelected[] = [];
    selectedFolderIds: number[] = [];
    selectedState = '';
    currentClickedFolderId: number | null = null;
    commentsNbr = 22;
    folderState: string | null = null;
    selectedPosId : number | null;


    loadingReceptSubject = new BehaviorSubject<boolean>(false);
    readonly loadingRecap$ = this.loadingReceptSubject.asObservable();

    loadingBulkSubject = new BehaviorSubject<boolean>(false);
    readonly loadingBulk$ = this.loadingBulkSubject.asObservable();

    displayedColumns: string[] = ['select', 'folderId', 'Docs', 'Client', 'createdBy', 'attributeTo', 'folderState', 'createAt', 'updatedAt', 'state', 'Wilaya', 'updater', 'actions'];

    private subscriptions: Subscription[] = [];

    selectedShippingType = new FormControl();
    selectedFolderState = new FormControl();
    selectedOrderState = new FormControl();
    selectedPOS = new FormControl();
    selectedArchive = new FormControl();
    selectedCreatorType = new FormControl();

    lastQuery: QueryParamsModel;

    selectedFoldersIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    selectedFoldersCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);


    creatorTypeList = [
        {name: 'Client', value: 'CLIENT'},
        {name: 'Admin', value: 'ADMIN'},
        {name: 'Point Vente', value: 'POS'}
    ]

    folderStateList = ENUMERATE_FOLDER_STATES;

    orderStateList = ORDER_SPLIT_STATE;

    currentRole = '';
    userPointOfSaleList: PointOfSaleModel[] = [];
    pointOfSaleList: PointOfSaleModel[] = [];
    currentUserId: number = null;


    routeFolderState = '';


    selectedFoldersIds: number[];
    sameStates: boolean;
    loadingStateChange: boolean;

    constructor(public formatter: NgbDateParserFormatter,
                private calendar: NgbCalendar,
                private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private wilayaService: WilayaService,
                private router: Router,
                public dialog: MatDialog,
                private orderSplitService: OrderSplitService,
                private pointOfSaleService: PointOfSaleService,
                private EkSnackBar: EkSnackBarService,
                public folderService: FoldersService,
                private layoutUtilsService: LayoutUtilsService,
                private menuConfigService: MenuConfigService,
                private cdr: ChangeDetectorRef,
                private ref: ApplicationRef,
                private excelExport: ExcelExportService
    ) {

        // Access the current query parameters
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            // Retrieve the folder state from queryParams
            this.routeFolderState = queryParams.folderState;
            this.selectedFolderState.patchValue(this.routeFolderState);
        });

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
        this.getPOSByIdUser();
        this.getAllPOS();

        if ((this.currentRole === 'ROLE_SUPERADMIN') ||
            (this.currentRole === 'ROLE_ADMIN') ||
            (this.currentRole === 'ROLE_BANKER_EK')) {
            this.displayedColumns = ['select', 'folderId', 'Docs', 'Client', 'createdBy', 'attributeTo', 'folderState', 'createdAt', 'updatedAt', 'state', 'Wilaya', 'updater', 'actions'];
        } else {
            this.displayedColumns = ['select', 'folderId', 'Client', 'createdBy', 'attributeTo', 'folderState', 'createdAt', 'updatedAt', 'state', 'Wilaya', 'updater', 'actions'];
        }
    }


    ngOnInit(): void {

        // init dataSource
        this.dataSource = new FoldersDatasource(this.store);

        this.wilayas = this.wilayaService.getWilayasItems();

        this.paginator._changePageSize(50);

        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.listingTitle = this.activatedRoute.snapshot.data.title;
            this.getAllPaged();
        });

        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                this.getAllPaged();
            })).subscribe();
        this.subscriptions.push(searchSubscription);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.getAllPaged();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.orderSplitService.orderSplitState$.subscribe(change => {
            if (change) {
                this.getAllPaged();
            }
        });

        this.fieldListener();

        this.selection.changed.subscribe(selectedRows => {
            const folderIds: number[] = [];
            const folderStates: FolderStatesSelected[] = [];
            selectedRows.source.selected.forEach(folder => {
                if (folder && selectedRows.source.selected.length > 0) {
                    const folderId = folder.folderId;
                    const status = folder.folderState;
                    folderIds.push(folder.folderId);
                    folderStates.push({folderId, folderState: status});
                }
            });
            const numberOfFolders = folderIds.length;
            this.selectedFoldersIds$.next(folderIds);
            this.selectedFolderIds = folderIds;
            this.selectedFoldersCount$.next(numberOfFolders);
            this.folderStatesSelected = folderStates;
            this.folderStatesSelected[0]?.folderState ? this.selectedState = this.folderStatesSelected[0]?.folderState : this.selectedState = '';
            this.isButtonHidden(this.selectedState);
            this.sameStates = this.areAllFolderStatesSame(this.folderStatesSelected);
            this.selectedFoldersIds = folderIds;
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.folderState = params['folderState'] || null;
        });

    }


    downloadDocs(folder: ListingModel) {
        const dialogRef = this.dialog.open(DownloadFileDialoguComponent, {
                width: '51rem',
                height: '45rem',
                data: {
                    idFolder: folder.folderId,
                    requestNumber: folder.requestNumber,
                    client: folder.firstNameClient + ' ' + folder.lastNameClient,
                }
            }
        );
    }

    areAllFolderStatesSame(data: FolderStatesSelected[]): boolean {
        if (data.length === 0) {
            return true;
        }

        const firstFolderState = data[0].folderState;

        for (let i = 1; i < data.length; i++) {
            if (data[i].folderState !== firstFolderState) {
                return false;
            }
        }

        return true;
    }

    openAnalystsList() {
        this.dialog.open(AnalystListPopupComponent, {
            width: '950px',
            height: '600px',
            panelClass: 'analysts-popup',
            data: this.selectedFoldersIds
        }).afterClosed().subscribe(() => {
            this.getAllPaged();
        });
    }

    closeAtt() {
        this.selection.clear();
        // this.selectedFoldersCount$.next(0);
        // this.selectedFoldersIds$.next([]);
    }


    fieldListener() {
        this.selectedShippingType.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.selectedFolderState.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.selectedOrderState.valueChanges.subscribe(value => {
            this.getAllPaged();
        });


        this.selectedPOS.valueChanges.subscribe(value => {
            this.selectedPosId = this.selectedPOS.value;
            this.getAllPaged();
        });
        this.selectedArchive.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedCreatorType.valueChanges.subscribe(value => {
            this.getAllPaged();
        })

    }

    filterConfiguration(): string {
        return this.searchInput.nativeElement.value;
    }

    getAllPaged() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        this.store.dispatch(foldersListingPageRequested({
            page: queryParams,
            folderState: this.selectedFolderState.value,
            orderState: this.selectedOrderState.value,
            fileShippingType: this.selectedShippingType.value,
            firstDate: this.fromDate ? this.formatDate(this.fromDate) : null,
            lastDate: this.toDate ? this.formatDate(this.toDate) : null,
            archived: this.selectedArchive.value,
            idPos: this.selectedPOS.value,
            creatorType: this.selectedCreatorType.value
        }));
    }

    formatDate(date: NgbDate) {
        let month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.filterByDateActivated = true;
            this.showDatePicker = false;
            this.getAllPaged();
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    disableFilterByDate(event) {
        if (event.checked == false) {
            this.loadingData = false;
            this.filterByDateActivated = false;
            this.toDate = null;
            this.fromDate = null;
            this.getAllPaged();
        }
    }

    // Check all rows are selected
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.entitySubject.value.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.entitySubject.value.forEach(row => this.selection.select(row));
        }
    }

    editFolder(c: ListingModel) {
        // folder/edit/:idOrder/:idFolder
        this.router.navigateByUrl('/ek-ecommerce/folder/edit/' + c.id + '/' + c.folderId + '?step=1');
    }

    openClientPopUp() {
        this.dialog.open(ClientsListPopUpComponent, {
            width: '92rem',
        });
    }

    downloadExcelFile() {
        this.excelExport.downloadExcel(this.folderState);
    }

    downloadExcelFolder(){
        this.excelExport.downloadExcelFolder(this.folderState,this.selectedPosId);
    }


    openOrderSplitChangeStatePopUp(folder: ListingModel) {

        if (folder.state === 'EK_CLIENT_ORDER_CANCELED' || folder.state === 'EK_ORDER_DELIVERED' || this.currentRole === 'ROLE_BANKER_EK' || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' || this.currentRole === 'ROLE_CONFORMITY_MANAGER') {
            return;

        } else {

            const dialogRef = this.dialog.open(OrderSplitChangeStateComponent, {
                data: {
                    idOrderSplit: folder.id,
                    idFolder: folder.folderId,
                    stateOrderSplit: folder.state
                },
                width: '37rem',
            });

            dialogRef.afterClosed().subscribe(res => {
                if (res && res === true) {
                    this.getAllPaged();
                }
            });
        }

    }


    openFolderChangeStatePopUp(folder: ListingModel) {


        if (this.currentRole !== 'ROLE_SUPERADMIN' || folder.folderState === 'EK_FOLDER_CREATED' || folder.folderState === 'EK_ADMIN_FOLDER_CANCELED') {
            return;

        } else {

            const dialogRef = this.dialog.open(FolderStateChangeComponent, {
                data: {
                    idFolder: folder.folderId,
                    stateFolder: folder.folderState
                },
                width: '37rem',
            });

            dialogRef.afterClosed().subscribe(res => {
                if (res && res === true) {
                    this.getAllPaged();
                }
            });
        }

    }

    getPOSByIdUser() {
        this.pointOfSaleService.getAllPOSByIdUser(this.currentUserId).subscribe({
            next: (points) => {
                this.userPointOfSaleList = points ? points : [];
            },
            error: () => {
                this.userPointOfSaleList = [];
            }
        });
    }

    getAllPOS() {
        this.pointOfSaleService.getPosListForFilter().subscribe({
            next: (points) => {
                if (points) {
                    //if any item in the list does not match the expected format
                    if (this.isListValid(points)) {
                        this.pointOfSaleList = this.sortItemsByPrefix(points);
                    } else {
                        this.pointOfSaleList = points;
                    }
                }
            },
            error: () => {
                this.pointOfSaleList = [];
            }
        });
    }

    receiptBill(idOrder) {

        this.loadingReceptSubject.next(true);

        this.orderSplitService.downloadReceptBillPdf(idOrder).subscribe((response: any) => {
            if (response.message == 'success' && response.body) {
                const byteCharacters = atob(response.body);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `receiptBill ` + idOrder + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                // this.isExporting = false;
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();
                }, 100);

                this.loadingReceptSubject.next(false);

            }
        });
    }

    deliveryBill(idOrder) {

        this.loadingReceptSubject.next(true);

        this.orderSplitService.downloadDeliveryBillPdf(idOrder).subscribe((response: any) => {
            if (response.message == 'success' && response.body) {
                const byteCharacters = atob(response.body);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `DeliveryBill ` + idOrder + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                // this.isExporting = false;
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();
                }, 100);

                this.loadingReceptSubject.next(false);

            }
        });
    }

    facture(idOrder) {

        this.loadingReceptSubject.next(true);

        this.orderSplitService.downloadFacturePdf(idOrder).subscribe((response: any) => {
            if (response.message == 'success' && response.body) {
                const byteCharacters = atob(response.body);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `facture ` + idOrder + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                // this.isExporting = false;
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();
                }, 100);

                this.loadingReceptSubject.next(false);

            }
        });
    }

    displayCancelReason(reason: string) {

        this.dialog.open(CancelingReasonPopUpComponent, {
                width: '480PX',
                height: '200px',
                data: {
                    reasonForCanceliong: reason
                }
            }
        );
    }


    // first state : EK_FOLDER_IN_PROGRESS
    changeOrderStatus(status: any) {

        // this.folderStateLoadObs$.next(true);

        switch (status) {

            // refuse folder
            case 'REFUSE' :

                switch (this.selectedState) {
                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        // supposed res : EK_BANK_PHYSICAL_FOLDER_REFUSED
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_REFUSED');
                        break;
                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res :
                        this.multiStateChange('EK_BANK_REFUSE_FOLDER');
                        break;

                    default :
                        // this.openDialog();
                        break;
                }
                break;

            // return folder
            case 'RETURN' :

                this.openCommentDialog();

                break;

            // validate folder
            case 'VALIDATE' :
                switch (this.selectedState) {

                    case 'EK_FOLDER_IN_PROGRESS':
                        // supposed res : CENTRAL_RISK_CONSULTATION
                        this.multiStateChange('EK_FOLDER_IN_PROGRESS_TO_CENTRAL_RISK_CONSULTATION');
                        // this.multiStateChange(   'EK_VALIDATE_FOLDER').subscribe().subscribe((e) => this.updateOrderState(e));
                        break;

                    case 'EK_BANK_RESERVE':
                        // supposed res : CENTRAL_RISK_CONSULTATION
                        this.multiStateChange('EK_BANK_RESERVE_TO_CENTRAL_RISK_CONSULTATION');
                        break;

                    // case 'EK_BANK_RESERVE':
                    //     // supposed res : CENTRAL_RISK_CONSULTATION
                    //     this.folder.folderPreviousState === 'CENTRAL_RISK_CONSULTATION' ?
                    //     this.changeState('EK_BANK_RESERVE_TO_CENTRAL_RISK_CONSULTATION') :
                    //     this.changeState('EK_REVERSE_BANK_RESERVE_TO_BANK_IN_PROGRESS')
                    //     break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : BANK_PAYMENT_CONFIRMATION
                        this.multiStateChange('EK_BANK_IN_PROGRESS_TO_BANK_NOTIFICATION');
                        break;

                    case 'BANK_NOTIFICATION':
                        // supposed res : BANK_PAYMENT_CONFIRMATION
                        this.multiStateChange('BANK_NOTIFICATION_TO_EK_CONTROL_BEFORE_GUARANTEES_PRINTING');
                        break;

                    // case 'BANK_PAYMENT_CONFIRMATION':
                    //     // supposed res : EK_CONTROL_BEFORE_GUARANTEES_PRINTING
                    //     this.multiStateChange( 'BANK_PAYMENT_CONFIRMATION_TO_CONTROL_BEFORE_GUARANTEES_PRINTING')
                    //

                    case 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING':
                        // supposed res : EK_CONTROL_BEFORE_GUARANTEES_PRINTING
                        this.multiStateChange('EK_CONTROL_BEFORE_GUARANTEES_PRINTING_TO_EK_GUARTENTEES_PENDING_SIGNATURE');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        // supposed res : EK_BANK_PHYSICAL_FOLDER_ACCEPTED
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_ACCEPTED');
                        break;

                    // case 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK':
                    //     // supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                    //     this.multiStateChange('GUARTENTEES_SIGNED_IN_PROGRESS_BANK_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS');
                    //     break;

                    case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED':
                        // supposed res : BANK_PAYMENT_CONFIRMATION
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_ACCEPTED_TO_BANK_PAYMENT_CONFIRMATION');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_RESERVE':
                        // supposed res : EK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.multiStateChange('EK_REVERSE_BANK_PHYSICAL_FOLDER_RESERVE_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;
                    case 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY':
                        // supposed res : EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY_TO_EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        // supposed res : EK_BANK_PHYSICAL_FOLDER_ACCEPTED
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_ACCEPTED');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED':
                        // supposed res : BANK_PAYMENT_CONFIRMATION
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_ACCEPTED_TO_BANK_PAYMENT_CONFIRMATION');
                        break;

                    case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
                        //supposed res : BANK_PAYMENT_CONFIRMATION
                        this.multiStateChange('EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS_TO_BANK_PAYMENT_CONFIRMATION');
                        break;
                }
                break;
        }
    }

    multiStateChange(event: string, comment?: string) {
        this.loadingStateChange = true;
        this.folderService.multiStateChange(this.selectedFolderIds, event, comment).subscribe({
            next: () => {
                this.getAllPaged();
                this.selectedFoldersCount$.next(0);
                this.selectedState = '';
                this.folderStatesSelected = [];
                this.selection.clear();
                this.loadingStateChange = false;
                this.selectedFolderIds = [];
                const message = `les statuts des dossiers ont bien été modifié !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Create,
                    5000,
                    false,
                    false
                );

                // reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();
            },
            error: () => {
                this.loadingStateChange = false;
            }
        });
        this.cdr.detectChanges();
        this.ref.tick();
    }

    getFoldersStatisticsForMenuConfigs() {

        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.menuConfigService.statisticsSubject$.next(null as any);
                this.menuConfigService.statisticsSubject$.next(res.countFolderState);
                this.cdr.detectChanges();
                this.ref.tick();
            }
        });

        if (this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_BANKER_EK'
        ) {
            this.folderService.GlobalFolderStatistics().subscribe({
                next: (res) => {
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
                    this.cdr.detectChanges();
                    this.ref.tick();
                }
            });
        }
    }

    sendFeedback(feedback: string) {
        this.loadingStateChange = true;
        this.selectedFolderIds.forEach(folderId => {
            this.folderService.sendFeedback(folderId, feedback).subscribe({
                next: (res) => {
                    this.folderService.folderStateSubj$.next(res as FolderStates);
                    this.getAllPaged();
                    this.loadingStateChange = false;
                    this.selectedFoldersCount$.next(0);
                    this.selectedState = '';
                    this.folderStatesSelected = [];
                    this.selectedFolderIds = [];
                    this.selection.clear();
                    const message = `le statut de dossier a été bien modifié!`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Create,
                        5000,
                        false,
                        false
                    );
                    // reload aside left folders list
                    this.getFoldersStatisticsForMenuConfigs();

                },
                error: (error) => {
                    this.loadingStateChange = false;
                    this.selectedFoldersCount$.next(0);
                    this.selectedState = '';
                    this.selectedFolderIds = [];
                    this.folderStatesSelected = [];
                    this.selection.clear();
                    if (error.error.code === 401) {
                        const message = `vous pouvez pas faire cette opération !`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                    }

                    if (error.status === 403) {
                        const message = `vous pouvez pas changer le statu !`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                    }
                },
            });
        });
    }

    sendBulkFeedBack(feedBack: string) {

        this.loadingBulkSubject.next(true);

        this.folderService.sendBulkFeedback(this.selectedFolderIds, feedBack).subscribe({
            next: (res) => {

                // reload listing with new states
                this.getAllPaged();

                // reset variables
                this.selectedFoldersCount$.next(0);
                this.selectedState = '';
                this.folderStatesSelected = [];
                this.selectedFolderIds = [];
                this.selection.clear();

                const message = this.selectedFolderIds.length > 1 ? `les statuts des dossier ont été bien modifié!` : `le statut de dossier a été bien modifié!`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Create,
                    5000,
                    false,
                    false
                );

                this.loadingBulkSubject.next(false);

                // reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();


            },
            error: (error) => {
                this.loadingBulkSubject.next(false);
                this.selectedFoldersCount$.next(0);
                this.selectedState = '';
                this.selectedFolderIds = [];
                this.folderStatesSelected = [];
                this.selection.clear();
                if (error.error.code === 401) {
                    const message = `vous pouvez pas faire cette opération !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }

                if (error.status === 403) {
                    const message = `vous pouvez pas changer le statu !`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                }
            }
        });

    }


    isButtonHidden(status: any) {

        if (this.selectedState) {
            switch (status) {
                case 'CANCEL':
                    return false;
                    break;
                case 'RETURN':
                    //
                    if (this.selectedState === 'EK_BANK_IN_PROGRESS'
                        || this.selectedState === 'EK_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.selectedState === 'EK_WAITING_FOR_CLIENT'
                        || this.selectedState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'CENTRAL_RISK_CONSULTATION'
                        || this.selectedState === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING'
                        || this.selectedState === 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                    break;

                case 'REFUSE':
                    if (this.selectedState === 'EK_BANK_IN_PROGRESS'
                        || this.selectedState === 'EK_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.selectedState === 'EK_WAITING_FOR_CLIENT'
                        || this.selectedState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'CENTRAL_RISK_CONSULTATION'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                    break;

                case 'VALIDATE':

                    // if document non verified , pos can not validate folder
                    if (this.currentRole === 'ROLE_POS_EK' && this.selectedState === 'EK_FOLDER_CREATED') {
                        return true;
                    }

                    if (this.selectedState === 'EK_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'EK_BANK_IN_PROGRESS'
                        || this.selectedState === 'EK_FOLDER_RESERVE'
                        || this.selectedState === 'EK_BANK_RESERVE'
                        || this.selectedState === 'EK_BANK_PHYSICAL_FOLDER_RESERVE'
                        || this.selectedState === 'EK_FOLDER_CREATED'
                        || this.selectedState === 'EK_GUARTENTEES_PENDING_SIGNATURE'
                        || this.selectedState === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_PENDING'
                        || this.selectedState === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_DELIVERED'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_PICKEDUP'
                        || this.selectedState === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
                        || this.selectedState === 'CENTRAL_RISK_CONSULTATION'
                        || this.selectedState === 'BANK_NOTIFICATION'
                        || this.selectedState === 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
                        // || this.selectedState === 'BANK_PAYMENT_CONFIRMATION'
                        || this.selectedState === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
            }

        }
    }

    checkFolderStates() {
        return this.folderStatesSelected.every(
            (item) => item.folderState === 'CENTRAL_RISK_CONSULTATION'
        );
    }

    openCommentDialog() {

        const title = 'Commentaire';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: false,
                    reserveBulk: true,
                    idFolder: null,
                    idOrder: null,
                    title,
                    folderState: '',
                    nextFolderState: ''
                }
            }
        );

        dialogRef.afterClosed().subscribe((comment: string) => {

            if (comment) {

                switch (this.selectedState) {

                    case 'EK_FOLDER_IN_PROGRESS':
                        // supposed res : EK_FOLDER_RESERVE
                        this.multiStateChange('EK_RETURN_FOLDER', comment);
                        // this.openDialog('EK_RETURN_FOLDER');

                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : EK_BANK_RESERVE
                        this.multiStateChange('EK_BANK_RETURN_FOLDER', comment);
                        // this.openDialog('EK_BANK_RETURN_FOLDER');
                        break;

                    case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                        // supposed res : EK_BANK_PHYSICAL_FOLDER_RESERVE
                        this.multiStateChange('EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_RESERVE', comment);
                        // this.openDialog('EK_BANK_RETURN_PHYSICAL_FOLDER');
                        break;

                    case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
                        // supposed res : EK_GUARTENTEES_SIGNED_IN_PROGRESS
                        this.multiStateChange('EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS');
                        break;
                }

            } else {
                const message = `vous devez insérer un commentaire avant de faire cette opération !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        });
    }

    openFolderDetails(item: ListingModel) {
        const dialogRef = this.dialog.open(FolderStateHistoryDetailsComponent, {
            data: {
                folderId: item.folderId,
                clientFirstName: item.firstNameClient,
                clientLastName: item.lastNameClient,
                requestNumber: item.requestNumber,
            },
            width: '60rem',
            // panelClass: 'history-details'
        });
    }

    commentFolder(folder: ListingModel) {
        const commentDialogRef = this.dialog.open(CommentsPopUpComponent, {
            data: {
                folderId: folder.folderId,
                requestNumber: folder.requestNumber,
            },
            width: '80rem',
        });

        commentDialogRef.afterClosed().subscribe(data => {
            this.getAllPaged();
        });
    }

    canManageComments(folder: ListingModel) {

        if (this.currentRole === 'ROLE_BANKER_EK' && !BANKER_STATES.includes(folder.folderState)) {
            return false;
        }
        return true;
    }

    canAddNewFolder() {
        return this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN' || this.currentRole === 'ROLE_POS_EK';
    }

    isListValid(items: PointOfSaleModel[]): boolean {
        return items.every(item => this.extractNumber(item.namePos) !== Infinity);
    }

    sortItemsByPrefix(items: PointOfSaleModel[]): PointOfSaleModel[] {
        return items.sort((a, b) => {
            const numA = this.extractNumber(a.namePos);
            const numB = this.extractNumber(b.namePos);
            return numA - numB;
        });
    }

    extractNumber(item: string): number {
        const match = item.match(/^(\d+)-/);
        return match ? parseInt(match[1], 10) : Infinity;
    }
}
