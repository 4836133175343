import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appArabicInput]'
})
export class ArabicInputDirective {
    private arabicAlphabetPattern = /^[\u0600-\u06FF\s]+$/;

    @HostListener('input', ['$event']) onInput (event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;

        if (!this.arabicAlphabetPattern.test(inputValue)) {
            // Invalid input, clear it or display an error message
            inputElement.value = '';
        }
    }
}