import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PointOfSaleModel} from "../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {BehaviorSubject, Observable, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {selectPOSById} from "../../../../../../core/ek-e-commerce/ek-selectors/point-of-sale.selector";
import {PointOfSaleService} from "../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service";
import {SubheaderService} from "../../../../../../core/_base/layout";

@Component({
  selector: 'kt-point-of-sale-edit',
  templateUrl: './point-of-sale-edit.component.html',
  styleUrls: ['./point-of-sale-edit.component.scss']
})
export class PointOfSaleEditComponent implements OnInit {
  pos: PointOfSaleModel;
  hasFormErrors = false;
  posForm: FormGroup;
  loading$: Observable<boolean>;
  posId: number;
  posId$: Observable<number>;
  private oldPOS: PointOfSaleModel;
  loadingSubject = new BehaviorSubject<boolean>(true);
  PHONE_REGEX = /^(00213|\+213|0)(5|6|7|9)(\s*?[0-9]\s*?){5,8}$/;

  constructor(private activatedRoute: ActivatedRoute,
              private store: Store<AppState>,
              private cdr: ChangeDetectorRef,
              private posService: PointOfSaleService,
              private posFB: FormBuilder,
              private subheaderService: SubheaderService,
              ) { }

  ngOnInit(): void {
    this.posForm = this.posFB.group({
      namePos: ['', Validators.required],
      posCommune: ['', Validators.required],
      addressPos: ['', Validators.required],
      posPhoneOne: ['', [Validators.required , Validators.pattern(this.PHONE_REGEX)]],
      posPhoneTwo: ['', [Validators.required , Validators.pattern(this.PHONE_REGEX)]],
      posWilaya: ['', Validators.required],
    });
   /* this.posForm = this.posFB.group({
      namePos: [this.pos.namePos, Validators.required],
      posCommune: [this.pos.posCommune, Validators.required],
      addressPos: [this.pos.addressPos, Validators.required],
      posPhoneOne: [this.pos.posPhoneOne, Validators.required],
      posPhoneTwo: [this.pos.posPhoneTwo, Validators.required],
      posWilaya: [this.pos.posWilaya, Validators.required],
    });*/
    this.posId = this.activatedRoute.snapshot.params.id;
    if (this.posId && this.posId > 0) {
      this.store.pipe(
          select(selectPOSById(this.posId))
      ).subscribe(result => {
        if (result == undefined) {
          this.loadPOSFromService(this.posId);
          return;
        }
        this.loadPOS(result);
      });
    }
  }

  phoneNumberValidator(): ValidatorFn {
    const PHONE_REGEX = /^(00213|\+213|0)(5|6|7|9)(\s*?[0-9]\s*?){5,8}$/;
  
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = PHONE_REGEX.test(control.value);
      return valid ? null : { 'invalidPhoneNumber': { value: control.value } };
    };
}
  loadPOS(_pos, fromService: boolean = false) {
    this.pos = _pos;
    this.posId$ = of(_pos.id);
    this.oldPOS = Object.assign({}, _pos);
    this.initPOS();
    if (fromService) {
      this.cdr.detectChanges();
    }
  }
  initPOS() {
    this.loadingSubject.next(false);
    if (!this.pos.id) {
      this.subheaderService.setBreadcrumbs([
        {title: 'eCommerce', page: `/ek-ecommerce`},
        {title: 'Point de ventes', page: `/ek-ecommerce/point-of-sale`},
        {title: 'Créer Point de vente', page: `/ek-ecommerce/point-of-sale/add`}
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit Point de ventes');
    this.subheaderService.setBreadcrumbs([
      {title: 'Ek eCommerce', page: `/ek-ecommerce`},
      {title: 'Point de ventes', page: `/ek-ecommerce/point-of-sale`},
      {title: 'Modifier Point de vente', page: `/ek-ecommerce/point-of-sale/edit`, queryParams: {id: this.pos.id}}
    ]);
  }

  loadPOSFromService(posId: number) {
    this.posService.getPOSById(posId).subscribe((res) => {
      this.loadPOS(res, true);
})
  }
  getComponentTitle() {
    let result = 'Créer un point de vente';
    if (!this.pos || !this.pos.id) {
      return result;
    }

    result = `Modifier point de vente - ${this.pos.namePos}`;
    return result;
  }

  onSumbit(b: boolean) {
    
  }
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
