export const FOLDER_STATES = [
    {name: 'En cours de Création', value: 'EK_FOLDER_CREATED' , order : 1},
    {name: 'Dossier en cours de traitement Ekiclik', value: 'EK_FOLDER_IN_PROGRESS', order : 2},
    {name: 'Dossier Réserve Ekiclik', value: 'EK_FOLDER_RESERVE', order : 3},
    {name: 'Dossier Rejeté Ekiclik', value: 'EK_FOLDER_REFUSED', order : 4},
    {name: 'Consultation Central Risque', value: 'CENTRAL_RISK_CONSULTATION', order : 5},
    {name: 'Notification Client Centrale des risques', value: 'CLIENT_CENTRAL_RISK_NOTIFICATION', order : 6},
    {name: 'Pré Notification Banque', value: 'EK_BANK_IN_PROGRESS', order : 7},
    {name: 'Notification Banque (Attente accord)', value: 'BANK_NOTIFICATION', order : 8},
    {name: 'Dossier Réserve Banque', value: 'EK_BANK_RESERVE', order : 9},
    {name: 'Dossier rejeté Banque', value: 'EK_BANK_REFUSED', order : 10},
    {name: 'Control avant impression des garanties Ekiclik', value: 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING', order : 11},
    {name: 'Garanties en cours de signature', value: 'EK_GUARTENTEES_PENDING_SIGNATURE', order : 12},
    {name: 'Garanties signés en cours validation Ekiclik', value: 'EK_GUARTENTEES_SIGNED_IN_PROGRESS', order : 13},
    {name: 'Garanties signés en cours validation Banque', value: 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS', order : 14},
    {name: 'Confirmation Paiement Banque', value: 'BANK_PAYMENT_CONFIRMATION', order : 15},
    {name: 'Dossiers financés', value: 'FINANCIAL_FOLDER', order : 16},
    {name: 'Dossier physique en cours de livraison', value: 'EK_PHYSICAL_FOLDER_IN_DELIVERY', order : 17},
    {name: 'Dossier physique en traitement Ekiclik', value: 'EK_PHYSICAL_FOLDER_IN_PROGRESS', order : 18},
    {name: 'Dossier physique en cours d\'envoie banque', value: 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY', order : 19},
    {name: 'Control dossier Physique Banque', value: 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS', order : 20},
    {name: 'Dossier physique accepté banque ', value: 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED', order : 21},
    {name: 'Dossier physique en préparation', value: 'EK_PHYSICAL_FOLDER_PENDING', order : 22},
    {name: 'Dossier physique en attente de recupération', value: 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK', order : 23},
    {name: 'Dossier physique en attente du client', value: 'EK_WAITING_FOR_CLIENT', order : 24},
    {name: 'Client ne reponds pas', value: 'EK_CLIENT_NO_RESPONSE', order : 25},
    {name: 'Dossier physique ramassé', value: 'EK_PHYSICAL_FOLDER_PICKEDUP', order : 26},
    {name: 'Dossier physique livré ', value: 'EK_PHYSICAL_FOLDER_DELIVERED', order : 27},
    {name: 'Echec de recuperation du dossier physique', value: 'EK_FOLDER_PICKUP_FAILED', order : 28},
    {name: 'Dossier physique réserve banque', value: 'EK_BANK_PHYSICAL_FOLDER_RESERVE', order : 29},
    {name: 'Dossier physique rejeté banque', value: 'EK_BANK_PHYSICAL_FOLDER_REFUSED', order : 30},
    {name: 'Dossier annulé (client)', value: 'EK_CLIENT_FOLDER_CANCELED', order : 31},
    {name: 'Dossier annulé (admin)', value: 'EK_ADMIN_FOLDER_CANCELED', order : 32}
]