import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mensual'
})
export class MonthlyPaymentPipe implements PipeTransform {

    transform(value: number, numberOfMonths: number): number {
        numberOfMonths = numberOfMonths - 2;
        const newNumberOfMonths = numberOfMonths + 1;

        const interestAmount = this.calculateInterestAmount(value);
        const R = 0.0104125;
        const R_PLUS = 1.0104125;
        const total = ((interestAmount * Math.pow(R_PLUS, numberOfMonths)) * R) / (Math.pow(R_PLUS, newNumberOfMonths) - 1);

        return total;
    }
    calculateInterestAmount(totalSellingPrice: number): number {
        const R = 0.0104125;
        return totalSellingPrice + (totalSellingPrice * R * 2);
    }

}
