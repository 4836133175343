// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Partials
import {PartialsModule} from '../partials/partials.module';
// Pages
import {CoreModule} from '../../core/core.module';
import {ECommerceModule} from './apps/e-commerce/e-commerce.module';
import {EkECommerceModule} from './apps/ek-e-commerce/ek-e-commerce.module';
import {ErrorPageComponent} from "./error-page/error-page.component";
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import {MatSortModule, MatTableModule} from "@angular/material";
import { EkUserManagementComponent } from './ek-user-management/ek-user-management.component';
import {RouterModule} from "@angular/router";
import {EkUserManagementModule} from "./ek-user-management/ek-user-management.module";

@NgModule({
    declarations: [ErrorPageComponent],
    exports: [],
    imports: [
        MatTableModule,
        MatSortModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        ECommerceModule,
        EkUserManagementModule,
        EkECommerceModule,
        RouterModule,
    ],
    providers: [{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}]
})
export class PagesModule {
}
