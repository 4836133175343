import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as BrandActions from "../ek-actions/brand.action";
import {BrandModel} from "../ek-models/brand.model";

export interface BrandState extends EntityState<BrandModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedBrandId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<BrandModel> = createEntityAdapter<BrandModel>();

export const initialBrandState: BrandState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedBrandId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const brandReducer = createReducer(
    initialBrandState,
    on(BrandActions.BrandsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(BrandActions.BrandsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.brands,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(BrandActions.BrandsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(BrandActions.BrandCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BrandActions.BrandCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.brand, {
            ...state,
            actionsLoading: true,
            lastCreatedBrandId: action.brand.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(BrandActions.BrandCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(BrandActions.BrandUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BrandActions.BrandUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialBrand, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(BrandActions.BrandUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(BrandActions.BrandDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BrandActions.BrandDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.brandId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS'
        }))),
    on(BrandActions.BrandDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function brandsReducer(state: BrandState | undefined, action: Action) {
    return brandReducer(state, action)
}
