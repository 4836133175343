import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {EkCategoryModel} from "../ek-models/ek-category.model";
import {CategoryPopularModel} from "../../e-commerce/_models/category-popular.model";

@Injectable()
export class EkCategoriesService extends AbstractService<EkCategoryModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}categories`;
    }

    public getAllSorted(page: number = 0, size: number = 100, sorting: string, direction: string, label: string): Observable<Page<EkCategoryModel>> {
        const options = {
            params: new HttpParams()
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)
                .append('direction', direction)
                .append('label', label)
        };
        const url = `${this.url}/find`;
        return this.http.get<Response<Page<EkCategoryModel>>>(url, options).pipe(map(({body}) => body));
    }


    public getSubCategoriesSearch(page: number = 0, size: number = 100, sorting: string = '', direction: string = '', label: string = ''): Observable<Page<EkCategoryModel>> {
        const options = {
            params: new HttpParams()
                .append('direction', direction)
                .append('label', label)
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)

        };
        const url = `${this.url}/subCategory`;
        return this.http.get<Response<Page<EkCategoryModel>>>(url, options).pipe(map(({body}) => body));
    }

    public getCategoriesByIds(categoriesIds: number[]): Observable<EkCategoryModel[]> {
        const options = {
            params: new HttpParams()
                .append('ids', categoriesIds.toString())

        };
        const url = `${this.url}/categoriesByIds`;
        return this.http.get<Response<EkCategoryModel[]>>(url, options).pipe(map(({body}) => body));
    }

    public createCategory(category: EkCategoryModel): Observable<HttpEvent<EkCategoryModel>> {
        const formData = new FormData();
        for (let categoryKey in category) {
            if (categoryKey != 'constructor' && categoryKey != 'clear' && category[categoryKey] != null) {
                formData.append(categoryKey.toString(), category[categoryKey.toString()]);
            }
        }
        const options = {
            reportProgress: true
        };
        const req = new HttpRequest(
            'POST',
            `${this.url}`,
            formData,
            options
        );
        return this.http.request(req);
    }

    public updateCategory(category: EkCategoryModel): Observable<HttpEvent<EkCategoryModel>> {
        const formData = new FormData();
        for (let categoryKey in category) {
            if (categoryKey != 'constructor' && categoryKey != 'clear' && category[categoryKey] != null) {
                formData.append(categoryKey.toString(), category[categoryKey.toString()]);
            }
        }
        const options = {
            reportProgress: true
        };
        const req = new HttpRequest(
            'PUT',
            `${this.url}`,
            formData,
            options
        );
        return this.http.request(req);
    }
    public changeActivateCategory(id: number, activate: boolean, updater: string): Observable<Response<EkCategoryModel>> {
        const url = `${this.url}/${id}/${activate}/${updater}`;
        return this.http.patch<Response<EkCategoryModel>>(url,"").pipe(
            map((response: Response<EkCategoryModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public getParentCategoryById(categoryId: number): Observable<string>{
        const url = `${this.url}/parent-name/${categoryId}`;
        return this.http.get<Response<string>>(url).pipe(map(({body}) => body));
    }

    public getPopular(): Observable<Response<CategoryPopularModel[]>> {
        const url = `${this.url}/popular`;
        return this.http.get<Response<CategoryPopularModel[]>>(url);
    }

}
