import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ImageModel} from "../ek-models/image.model";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ImageActions from "../ek-actions/image.action";


export interface ImageState extends EntityState<ImageModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedImageId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ImageModel> = createEntityAdapter<ImageModel>();

export const initialImageState: ImageState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedImageId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const imageReducer = createReducer(
    initialImageState,
    on(ImageActions.ImagesPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ImageActions.ImagesPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.images,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(ImageActions.ImagesPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(ImageActions.ImageCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ImageActions.ImageCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.image, {
            ...state,
            actionsLoading: true,
            lastCreatedImageId: action.image.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(ImageActions.ImageCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(ImageActions.ImageUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ImageActions.ImageUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialImage, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ImageActions.ImageUpdatedOrderFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    // Update order
    on(ImageActions.ImageUpdatedOrder,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ImageActions.ImageUpdatedOrderSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialImage, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ImageActions.ImageUpdatedOrderFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(ImageActions.ImageDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ImageActions.ImageDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.imageId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS'
        }))),
    on(ImageActions.ImageDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function imagesReducer(state: ImageState | undefined, action: Action) {
    return imageReducer(state, action)
}
