import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ConfigurationModel} from "../ek-models/configuration.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ConfigurationState} from "../ek-reducers/configuration.reducer";
import {selectProductsState} from "./ekproduct.selectors";


export const selectConfigurationsState = createFeatureSelector<ConfigurationState>('configuration');

export const selectConfigurationById = (configurationId: number) => createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.entities[configurationId]
);

export const selectConfigurationsPageLoading = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.listLoading
);

export const selectError = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.error
);

export const selectlastAction = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.lastAction
);

export const selectConfigurationsActionLoading = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.actionsLoading
);


export const selectProductsPageLastQuerys = createSelector(
    selectProductsState,
    configurationsState => configurationsState.lastQuery
);
export const selectLastCreatedConfigurationId = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.lastCreatedConfigurationId
);

export const selectConfigurationsShowInitWaitingMessage = createSelector(
    selectConfigurationsState,
    configurationsState => configurationsState.showInitWaitingMessage
);

export const selectConfigurationsInStore = createSelector(
    selectConfigurationsState,
    configurationsState => {
        const items: ConfigurationModel[] = [];
        each(configurationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ConfigurationModel[] = httpExtension.sortArray(items, configurationsState.lastQuery.sortField, configurationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, configurationsState.totalCount, '');
    }
);
