export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: [

                {
                    title: 'Applications',
                    root: true,
                    alignment: 'left',
                    toggle: 'click',
                    submenu: [
                        {
                            title: 'Catalogue produits',
                            bullet: 'dot',
                            icon: 'list_alt',
                            // permission: 'accessToECommerceModule',
                            submenu: [
                                {
                                    title: 'Categories',
                                    page: '/ecommerce/categories',
                                    permission: 'ALL_CATEGORY'
                                },
                                {
                                    title: 'Products',
                                    page: '/ecommerce/products2'
                                },
                                {
                                    title: 'Archive',
                                    page: '/ecommerce/archive',

                                },
                                {
                                    title: 'Code promo',
                                    page: '/ecommerce/promotionCode',
                                },

                                // {
                                // 	title: 'Products',
                                // 	page: '/ecommerce/products',
                                // 	permission: 'ALL_PRODUCT'
                                // },
                            ]
                        },
                        {
                            title: 'Commandes',
                            bullet: 'dot',
                            icon: 'local_mall',
                            // permission: 'accessToECommerceModule',
                            submenu: [
                                {
                                    title: 'Commandes',
                                    page: '/ecommerce/orders',
                                    permission: 'ALL_FOLDER',
                                },
                                {
                                    title: ' Suivie de livraison',
                                    page: '/ecommerce/parcels',
                                    permission: 'ROLE_ADMIN'
                                },
                                {
                                    title: 'Clients',
                                    page: '/ecommerce/customers',
                                    permission: ['ALL_CLIENT', 'READ_CLIENT']
                                },
                                {
                                    title: 'Point Relais',
                                    page: '/ecommerce/PointRelait',
                                    permission: 'ROLE_ADMIN'
                                },
                                // {
                                // 	title: 'Region',
                                // 	page: '/ecommerce/region',
                                // 	permission: 'ROLE_ADMIN'
                                // },
                                // {
                                // 	title: 'Parcels',
                                // 	page: '/ecommerce/parcels',
                                // 	permission: 'ROLE_ADMIN'
                                // },

                            ]
                        },
                        {
                            title: 'Gestion D\'utilisateurs',
                            bullet: 'dot',
                            icon: 'perm_identity',
                            submenu: [
                                {
                                    title: 'Users',
                                    page: '/user-management/users',
                                    permission: ['ALL_WSUSER', 'READ_WSUSER'],
                                },
                                {
                                    title: 'Région/commercial',
                                    page: '/user-management/region',
                                    permission: 'ROLE_ADMIN'
                                },
                            ]
                        },
                        {
                            title: 'Page d\'accueil',
                            bullet: 'dot',
                            icon: 'place',
                            permission: 'ROLE_ADMIN',
                            submenu: [
                                {
                                    title: 'Images Carousel',
                                    page: '/ecommerce/home-page/carousel'
                                },
                                {
                                    title: 'Produits en tendance',
                                    page: '/ecommerce/home-page/trending'
                                },
                                {
                                    title: 'Meilleures ventes',
                                    page: '/ecommerce/home-page/best-sellers'
                                },
                                {
                                    title: 'Nouveaux arrivés',
                                    page: '/ecommerce/home-page/new-arrivals'
                                },
                                {
                                    title: 'Meilleures categories',
                                    page: '/ecommerce/home-page/relevant-categories'
                                },
                                {
                                    title: 'Footer',
                                    page: '/ecommerce/home-page/footer'
                                },
                                {
                                    title: 'Affichage des sections ',
                                    page: '/ecommerce/home-page/display'
                                },

                            ]
                        }
                    ]
                },

            ]
        },
        aside: {
            self: {},
            items: [

                {
                    title: 'Tableau de bord',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'insert_chart',
                    root: true,
                    page: '/dashboard',
                    permission: ['ALL_HOME', 'ONLY_READ']
                },
                {
                    title: 'Catalogue produits',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'list_alt',
                    root: true,
                    permission: ['ALL_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT', 'READ_CATEGORY'],
                    submenu: [
                        {
                            title: 'Categories',
                            page: '/ek-ecommerce/categories',
                            disabled: false,
                            permission: ['ALL_CATEGORY', 'READ_CATEGORY']
                        },
                        {
                            title: 'Produits',
                            page: '/ek-ecommerce/products',
                            disabled: false,
                            permission: ['ALL_PRODUCT', 'READ_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        }
                    ]
                },
                {
                    title: 'Statuts Global',
                    disabled: false,
                    bullet: 'number',
                    icon: 'folder',
                    submenu: [],
                    permission: ['ALL_HOME','ONLY_READ']
                },
                {
                    title: 'Dossiers',
                    disabled: false,
                    bullet: 'number',
                    icon: 'folder',
                    submenu: []
                },
                {
                    title: 'Clients',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'perm_identity',
                    root: true,
                    page: '/ek-ecommerce/customers',
                    permission: ['ALL_CLIENT', 'READ_CLIENT']
                },
                {
                    title: 'Historique des Modifications',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'history',
                    root: true,
                    page: '/ek-ecommerce/changeHistory',
                    permission: ['CREATE_HISTORYSTATEFOLDER', 'READ_HISTORYSTATEFOLDER']
                },
                {
                    title: 'Historique des SMS',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'textsms',
                    root: true,
                    page: '/ek-ecommerce/smsHistory',
                   permission: ['ALL_HOME', 'READ_HISTORYSMS']
                },
                {
                    title: 'Notifications',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'notifications',
                    root: true,
                    page: '/ek-ecommerce/settingsNotification',
                   permission: ['ALL_HOME','ONLY_READ']
                },
                {
                    title: 'Taux de banque',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'rate_review',
                    root: true,
                    page: '/ek-ecommerce/globalVariables',
                    permission: ['ALL_HOME','ONLY_READ']
                },
                {
                    title: 'Réseaux de distribution',
                    disabled: false,
                    bullet: 'dot',
                    icon: 'local_shipping',
                    permission: ['ALL_POINTRELAIT', 'READ_POINTRELAIT', 'ALL_POINTOFSALE'],
                    submenu: [
                        {
                            title: 'Regions',
                            page: '/ek-ecommerce/regions',
                            disabled: false,
                            permission: ['ALL_POINTRELAIT', 'READ_POINTRELAIT']
                        },
                        {
                            title: 'Points de vente',
                            page: '/ek-ecommerce/point-of-sale',
                            disabled: false,
                            permission: ['ALL_POINTRELAIT', 'READ_POINTRELAIT', 'ALL_POINTOFSALE']
                        },
                        // {
                        //     title: 'Yalidine',
                        //     page: '/ek-ecommerce/PointRelait',
                        //     disabled: true,
                        //     permission: ['ALL_POINTRELAIT', 'READ_POINTRELAIT']
                        // }

                    ]
                },
                {
                    title: 'Gestion D\'utilisateurs',
                    disabled: false,
                    root: true,
                    bullet: 'dot',
                    icon: 'perm_identity',
                    permission: ['ALL_WSUSER', 'READ_WSUSER'],
                    submenu: [
                        {
                            title: 'Utilisateurs',
                            page: '/ek-user-management/users',
                            disabled: false,
                            permission:  ['ALL_WSUSER', 'READ_WSUSER'],
                        },
                        // {
                        //     title: 'Région/commercial',
                        //     page: '/ek-user-management/region',
                        //     disabled: false,
                        //     permission: 'ALL_WSUSER',
                        //     //permission: ['ALL_POINTRELAIT', 'READ_POINTRELAIT']
                        // },
                    ]
                },
                {
                    title: 'Page d\'accueil',
                    root: true,
                    disabled: false,
                    bullet: 'dot',
                    icon: 'place',
                    permission: ['ALL_HOME', 'READ_HOME','ONLY_READ'],
                    submenu: [
                        {
                            title: 'Images Carousel',
                            page: '/ek-ecommerce/ek-home-page/carousel',
                            disabled: false,
                        },
                        // {
                        // 	title: 'Affichage des sections',
                        // 	page: '/ecommerce/home-page/display'
                        // },
                        // {
                        // 	title: 'Vente Flash',
                        // 	page: '/ecommerce/home-page/VenteFlash'
                        // },
                        // {
                        // 	title: 'Produits en tendance',
                        // 	page: '/ecommerce/home-page/trending'
                        // },
                        // {
                        // 	title: 'Meilleures ventes',
                        // 	page: '/ecommerce/home-page/best-sellers'
                        // },
                        /*{
                            title: 'Nouvel arrivage',
                            page: '/ecommerce/home-page/new-arrivals',
                            disabled: false,
                        },*/
                        {
                            title: 'Affichage des sections ',
                            page: '/ek-ecommerce/ek-home-page/display'
                        },
                        // {
                        // 	title: 'Meilleures categories',
                        // 	page: '/ecommerce/home-page/relevant-categories'
                        // },
                        {
                            title: 'Footer',
                            page: '/ek-ecommerce/ek-home-page/ek-footer',
                            disabled: false,
                        },

                    ]
                },
            ]
        },
    };

    public get configs(): any {
        return this.defaults;
    }

}
