import {FolderModel} from "./../../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {
  HistoryStateFolderService
} from "./../../../../../../../core/ek-e-commerce/ek-services/history-state-folder.service";
import {FoldersService} from "./../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {CreditModel} from "./../../../../../../../core/ek-e-commerce/ek-models/credit.model";


import {Component, Input, OnInit,} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import fontkit from "@pdf-lib/fontkit";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";

import {ToWords} from "to-words";

import {DocumentModel} from "../../../../../../../core/ek-e-commerce/ek-models/document.model";
import {OrderSplitService} from "../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ClientModel} from "./../../../../../../../core/ek-e-commerce/ek-models/client.model";
import {ocrInfo} from "./../../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {OrderSplit} from "./../../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {DocumentService} from "./../../../../../../../core/services/document.service";
import {BehaviorSubject} from "rxjs";
import {DatePipe} from "@angular/common";
import {OrderItemModel} from "../../../../../../../core/ek-e-commerce/ek-models/OrderItem.model";
import {ProfessionTranslatePipe} from "../../../../../../../core/_base/layout/pipes/profession-translate.pipe";
import {JiraModel} from "../../../../../../../core/ek-e-commerce/ek-models/jira.model";
import {EkCategoriesService} from "../../../../../../../core/ek-e-commerce/ek-services/ek-categories.service";
import {EkProductsService} from "../../../../../../../core/ek-e-commerce/ek-services/ek-product.service";

interface condition {
  main: string;
  secondary: string[];
}

@Component({
  selector: "kt-folder-step-six",
  templateUrl: "./folder-step-six.component.html",
  styleUrls: ["./folder-step-six.component.scss"],
  providers: [DatePipe, ProfessionTranslatePipe],
})
export class FolderStepSixComponent implements OnInit {
  @Input() information!: ocrInfo;
  @Input() client!: ClientModel;
  @Input() order!: OrderSplit;
  @Input() folder!: FolderModel;
  @Input() requestNumber: string;


  orderSplit: OrderSplit;
  loading: boolean;
  checkAccountAgreement: any;
  financingAgreement: any;
  salesContract: any;
  math = Math;
  credit: CreditModel;
  orderId: number;
  folderId: number;
  categories: OrderItemModel[] = [];
  notificationDate: Date;

  guaranteesDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
  invoicessDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
  downLoadFiles$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.downLoadFiles$.asObservable();

  ticketToOrder: any;
  currentRole: string = "";
  canDownload: boolean;
  categoriess: string[];

  jira!: JiraModel;
  arabicDate: string = "";
  normalDate: string = "";

  constructor(
    private documentService: DocumentService,
    private orderService: OrderSplitService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public folderService: FoldersService,
    private professionTranslatePipe: ProfessionTranslatePipe,
    private categoryService: EkCategoriesService,
    private productsService: EkProductsService,
    private historyStateFolderService: HistoryStateFolderService
  ) {
    this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
  }

  async createPdf() {
    this.loading = true;
    this.downLoadFiles$.next(true);
    const toWords = new ToWords({
      localeCode: "fr-FR",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        currencyOptions: {
          name: "Dinar",
          plural: "Dinars",
          symbol: "",
          fractionalUnit: {
            name: "Centime",
            plural: "Centimes",
            symbol: "",
          },
        },
      },
    });
    let totalToWords = toWords.convert(
      this.jira?.mtRmb ? this.jira?.mtRmb : this.credit?.reimbursementAmount
    );

    const myDate = new Date();
    const datePipe = new DatePipe("en-US");
    const formattedDate = datePipe.transform(myDate, "dd/MM/yyyy");

    const url =
      "https://ekiclik-docs.s3.eu-west-2.amazonaws.com/NotoSansArabic-Black.ttf";
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

    // Ticket To Order --------------------------------------------------
    const ticketToOrder = await fetch(this.ticketToOrder).then((res) =>
      res.arrayBuffer()
    );

    const TOR = await PDFDocument.load(ticketToOrder);
    TOR.registerFontkit(fontkit);
    const arabicFont = await TOR.embedFont(fontBytes);
    const helveticaFont = await TOR.embedFont(StandardFonts.HelveticaBold);

    const TORpages = TOR.getPages();
    const TORfirstPage = TORpages[0];

    TORfirstPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 275,
        y: 505,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    TORfirstPage.drawText(
      `${
        this.information?.personnelInformation?.firstName
          ? this.information?.personnelInformation?.firstName!
          : ""
      }     ${
        this.information?.personnelInformation?.lastName
          ? this.information?.personnelInformation?.lastName
          : ""
      }`,
      {
        x: 150,
        y: 280,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    TORfirstPage.drawText(
      `${
        this.jira?.mtRmb
          ? this.addSeparation(Math.round(100 * this.jira.mtRmb) / 100)
          : this.credit?.reimbursementAmount
          ? this.addSeparation(
              Math.round(100 * this.credit.reimbursementAmount) / 100
            )
          : ""
      }`,
      {
        x: 650,
        y: 455,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    TORfirstPage.drawText(`${totalToWords ? totalToWords : ""}`, {
      x: 180,
      y: 352,
      size: 11,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    TORfirstPage.drawText(
      `${this.jira?.cptAgence ? this.jira?.cptAgence : ""}`,
      {
        x: 130,
        y: 200,
        size: 11,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    TORfirstPage.drawText(
        `${this.jira?.cpt ? this.jira?.cpt : ""}`,
        {
          x: 130,
          y: 180,
          size: 11,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
    );


    TORfirstPage.drawText(`${this.normalDate}`, {
      x: 160,
      y: 455,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    // Convention de compte de cheque --------------------------------------------------

    const checkAccountAgreement = await fetch(
      this.checkAccountAgreement
    ).then((res) => res.arrayBuffer());

    const CCA = await PDFDocument.load(checkAccountAgreement);
    CCA.registerFontkit(fontkit);
    const arabicFont2 = await CCA.embedFont(fontBytes);
    const helveticaFont2 = await CCA.embedFont(StandardFonts.HelveticaBold);

    const CCApages = CCA.getPages();
    const CCAfirstPage = CCApages[0];
    const CCAsecondPage = CCApages[1];
    const CCAthirdPage = CCApages[2];
    const CCAfourthPage = CCApages[3];

    CCAfirstPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 43,
        y: 752,
        size: 12,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );
    CCAsecondPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 470,
        y: 775,
        size: 12,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );
    CCAthirdPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 470,
        y: 785,
        size: 12,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );
    CCAfourthPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 470,
        y: 775,
        size: 12,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${this.jira?.cptAgenceResp ? this.jira?.cptAgenceResp : ""}`,
      {
        x: 90,
        y: 691.5,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(`Directeur d’Agence`, {
      x: 120,
      y: 683.5,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    // CCAfirstPage.drawText(
    //   `بوروبة محمد`,
    //   {
    //     x: 500,
    //     y: 686,
    //     size: 7,
    //     font: arabicFont2,
    //     color: rgb(0, 0, 0),
    //   }
    // );

    CCAfirstPage.drawText(`مدير وكالة`, {
      x: 500,
      y: 675,
      size: 7,
      font: arabicFont2,
      color: rgb(0, 0, 0),
    });

    CCAfirstPage.drawText(
      `${this.information?.personnelInformation?.firstName!}     ${
        this.information?.personnelInformation?.lastName
      }`,
      {
        x: 120,
        y: 630,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${
        this.information?.personnelInformation?.fistNameEnArab
          ? this.information?.personnelInformation?.fistNameEnArab
          : ""!
      } ${
        this.information?.personnelInformation?.lastNameEnArab
          ? this.information?.personnelInformation?.lastNameEnArab
          : ""
      }`,
      {
        x: 450,
        y: 630,
        size: 7,
        font: arabicFont2,
        color: rgb(0, 0, 0),
      }
    );

    // left side

    CCAfirstPage.drawText(
      `${
        this.information?.personnelInformation?.birthDay
          ? this.information?.personnelInformation?.birthDay
          : ""
      }`,
      {
        x: 130,
        y: 622,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${
        this.information?.personnelInformation?.placeOfBrith
          ? this.information?.personnelInformation?.placeOfBrith
          : ""
      }`,
      {
        x: 200,
        y: 622,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    // right side

    CCAfirstPage.drawText(
      `${
        this.information?.personnelInformation?.birthDay
          ? this.information?.personnelInformation?.birthDay
          : ""
      }`,
      {
        x: 470,
        y: 619,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${
        this.information?.personnelInformation?.placeOfBrithArabic
          ? this.information?.personnelInformation?.placeOfBrithArabic
          : ""
      }`,
      {
        x: this.getX(
          460,
          this.information?.personnelInformation?.placeOfBrithArabic,
          arabicFont2,
          9
        ),
        y: 619,
        size: 7,
        font: arabicFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${
        this.information?.personnelSituation?.personnelSituationAddress
          ? this.invertNumbers(
              this.information?.personnelSituation?.personnelSituationAddress!
            )
          : ""
      }`,
      {
        x: this.getX(
          540,
          String(
            this.information?.personnelSituation?.personnelSituationAddress
          ),
          arabicFont2,
          7
        ),
        y: 597,
        size: 7,
        font: arabicFont2,
        color: rgb(0, 0, 0),
      }
    );

    // left

    CCAfirstPage.drawText(
      `${
        this.information?.professionalSituation?.profession
          ? this.professionTranslatePipe.transform(
              this.information?.professionalSituation?.profession
            )
          : ""
      }`,
      {
        x: 80,
        y: 608.5,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfirstPage.drawText(
      `${
        this.information?.personnelSituation?.personnelSituationAddressAscii
          ? this.information?.personnelSituation?.personnelSituationAddressAscii
          : ""
      }`,
      {
        x: 80,
        y: 600,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    //French

    CCAfourthPage.drawText(
      `${
        this.order.pointOfSaleTo?.posCommune
          ? this.order.pointOfSaleTo?.posCommune
          : ""
      }`,
      {
        x: 73,
        y: 743,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfourthPage.drawText(`${this.normalDate}`, {
      x: 140,
      y: 743,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    CCAfourthPage.drawText(
      `${this.jira?.cptAgence ? this.jira?.cptAgence : ""}`,
      {
        x: 170,
        y: 640,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfourthPage.drawText(`${this.jira?.cpt ? this.jira?.cpt : ""}`, {
      x: 170,
      y: 628,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    CCAfourthPage.drawText(`DZD`, {
      x: 170,
      y: 616,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    // Arabic

    CCAfourthPage.drawText(
      `${
        this.order.pointOfSaleTo?.posCommune
          ? this.order.pointOfSaleTo?.posCommune
          : ""
      }`,
      {
        x: 535,
        y: 743.5,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfourthPage.drawText(`${this.arabicDate}`, {
      x: 483,
      y: 743.5,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    CCAfourthPage.drawText(
      `${this.jira?.cptAgence ? this.jira?.cptAgence : ""}`,
      {
        x: 380,
        y: 638.5,
        size: 7,
        font: helveticaFont2,
        color: rgb(0, 0, 0),
      }
    );

    CCAfourthPage.drawText(`${this.jira?.cpt ? this.jira?.cpt : ""}`, {
      x: 380,
      y: 626.5,
      size: 7,
      font: helveticaFont2,
      color: rgb(0, 0, 0),
    });

    // CCAfourthPage.drawText(
    //   `${this.jira?.cpt ? this.jira?.cpt : ''}`,
    //   {
    //     x: 380,
    //     y: 628,
    //     size: 7,
    //     font: helveticaFont2,
    //     color: rgb(0, 0, 0),
    //   }
    // );

    CCAfourthPage.drawText(`دج`, {
      x: 400,
      y: 616,
      size: 7,
      font: arabicFont2,
      color: rgb(0, 0, 0),
    });

    const date = new Date();
    const dateString = date.toLocaleDateString();

    // Convention de financement --------------------------------------------------
    const ConvensionDeFinancement = await fetch(
      this.financingAgreement
    ).then((res) => res.arrayBuffer());

    const CDF = await PDFDocument.load(ConvensionDeFinancement);
    CDF.registerFontkit(fontkit);
    const arabicFont3 = await CDF.embedFont(fontBytes);
    const helveticaFont3 = await CDF.embedFont(StandardFonts.HelveticaBold);

    const CDFpages = CDF.getPages();
    const CDFZeroPage = CDFpages[0];
    const CDFfirstPage = CDFpages[1];
    //const CDFthirdPage = CDFpages[2];

    CDFZeroPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 37,
        y: 778,
        size: 12,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );
    CDFfirstPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 37,
        y: 750,
        size: 12,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );
    // CDFthirdPage.drawText(
    //   `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
    //   {
    //     x: 245,
    //     y: 810,
    //     size: 12,
    //     font: helveticaFont,
    //     color: rgb(0, 0, 0),
    //   }
    // );

    CDFZeroPage.drawText(`${this.arabicDate}`, {
      x: 80,
      y: 60,
      size: 10,
      font: helveticaFont3,
      color: rgb(0, 0, 0),
    });
    // CDFthirdPage.drawText(`${this.arabicDate}`, {
    //   x: 355,
    //   y: 588.5,
    //   size: 10,
    //   font: helveticaFont3,
    //   color: rgb(0, 0, 0),
    // });

    CDFfirstPage.drawText(
      `${this.jira?.cptAgenceResp ? this.jira?.cptAgenceResp : " "}`,
      {
        x: 170,
        y: 672,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );
    CDFfirstPage.drawText(
      `${this.jira?.cptAgence ? this.jira?.cptAgence : ""}`,
      {
        x: 73,
        y: 674,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );

    CDFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.fistNameEnArab
          ? this.information?.personnelInformation?.fistNameEnArab
          : ""!
      } ${
        this.information?.personnelInformation?.lastNameEnArab
          ? this.information?.personnelInformation?.lastNameEnArab
          : ""
      }`,
      {
        x: this.getX(
          475,
          this.information?.personnelInformation?.lastNameEnArab! +
            this.information?.personnelInformation?.fistNameEnArab,
          arabicFont3,
          10
        ),
        y: 633,
        size: 10,
        font: arabicFont3,
        color: rgb(0, 0, 0),
      }
    );

    CDFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.placeOfBrithArabic
          ? this.information?.personnelInformation?.placeOfBrithArabic
          : ""
      }`,
      {
        x: this.getX(
          335,
          this.information?.personnelInformation?.placeOfBrithArabic,
          arabicFont3,
          9
        ),
        y: 634,
        size: 9,
        font: arabicFont3,
        color: rgb(0, 0, 0),
      }
    );

    CDFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.birthDay
          ? this.information?.personnelInformation?.birthDay
          : ""
      }`,
      {
        x: this.getX(
          240,
          this.information?.personnelInformation?.birthDay,
          helveticaFont3,
          9
        ),
        y: 634,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );

    CDFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.idCardNumber
          ? this.information?.personnelInformation?.idCardNumber
          : ""
      }`,
      {
        x: 405,
        y: 615,
        size: 10,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );

    CDFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.deliveryDate
          ? this.information?.personnelInformation?.deliveryDate
          : ""
      }`,
      {
        x: 260,
        y: 615,
        size: 10,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );
    // CDFfirstPage.drawText(
    //   `${this.information?.personnelInformation?.deliveryDaira}`,
    //   {
    //     x: 380,
    //     y: 600,
    //     size: 10,
    //     font: helveticaFont3,
    //     color: rgb(0, 0, 0),
    //   }
    // );
    CDFfirstPage.drawText(
      `${
        this.information?.personnelSituation?.personnelSituationAddress
          ? this.invertNumbers(
              this.information?.personnelSituation?.personnelSituationAddress!
            )
          : ""
      }`,
      {
        x: this.getX(
          490,
          String(
            this.information?.personnelSituation?.personnelSituationAddress
          ),
          arabicFont3,
          9
        ),
        y: 598,
        size: 10,
        font: arabicFont3,
        color: rgb(0, 0, 0),
      }
    );
    let categoriess = Array.from(new Set(this.categoriess));
    categoriess = categoriess.map(s => s.toLowerCase());
    if (categoriess?.length < 3) {
      const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = `${8}px ${helveticaFont3}`;

      const firstLineTextWidth = context.measureText(firstLineCategories).width;
      const adjustedXFirstLine = 300 - firstLineTextWidth / 2;

      CDFfirstPage.drawText(firstLineCategories ? firstLineCategories : "", {
        x: adjustedXFirstLine,
        y: 490,
        size: 10,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      });
    } else {
      const firstLineCategories = categoriess?.slice(0, 2).join(", "); // Join the first four categories with commas
      const remainingCategories = categoriess?.slice(2).join(", "); // Join the remaining categories with commas

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = `${10}px ${helveticaFont3}`;

      const firstLineTextWidth = context.measureText(firstLineCategories).width;
      const secondLineTextWidth = context.measureText(remainingCategories)
        .width;
      const adjustedXFirstLine = 310 - firstLineTextWidth / 2;
      const adjustedXSecondLine =290 - secondLineTextWidth / 2;

      CDFfirstPage.drawText(firstLineCategories ? firstLineCategories : "", {
        x: adjustedXFirstLine,
        y: 490,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      });
      CDFfirstPage.drawText(remainingCategories ? remainingCategories : "", {
        x: adjustedXSecondLine,
        y: 482,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      });
    }

    CDFfirstPage.drawText(
      `${
        this.jira?.mtRmb
          ? this.addSeparation(Math.round(100 * this.jira.mtRmb) / 100)
          : this.credit?.reimbursementAmount
          ? this.addSeparation(
              Math.round(100 * this.credit.reimbursementAmount) / 100
            )
          : ""
      }`,
      {
        x: 355,
        y: 473,
        size: 9,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );
    CDFfirstPage.drawText(`${totalToWords ? totalToWords : ""}`, {
      x: 70,
      y: 453,
      size: 10,
      font: helveticaFont3,
      color: rgb(0, 0, 0),
    });

    CDFfirstPage.drawText(`${" 0,00"}`, {
      x: 210,
      y: 437,
      size: 10,
      font: helveticaFont3,
      color: rgb(0, 0, 0),
    });

    CDFfirstPage.drawText(
      `${this.credit.numberOfMonths ? this.credit.numberOfMonths : ""}`,
      {
        x: 230,
        y: 417,
        size: 10,
        font: helveticaFont3,
        color: rgb(0, 0, 0),
      }
    );

    //let conditions: condition[] = [];
   // conditions = this.createConditionsArray(this.jira?.conditions);
    let x = 50;
    let y = 345;
    if (this.jira?.conditions.includes("\n" || "\r\n" || "\r")) {
      let conditions = this.jira?.conditions.split(/\r\n|\r|\n/);
      conditions?.forEach((condition, index) => {
        if (condition.trim().split(/\s+/).length >= 12) {
          let firstLine = condition.split(" ").slice(0, 11).join(" ");
          let secondLine = condition.split(" ").slice(11).join(" ");
          console.log("====================================");
          console.log(firstLine);
          // console.log(secondLine);
          console.log("====================================");
          CDFfirstPage.drawText(
            `${index + 1}- ${firstLine
              //.replace(/(\b\d(?![.,])\b|-)/g, "")
              .trimLeft()}`,
            {
              x: x,
              y: y,
              size: 10,
              font: helveticaFont3,
              color: rgb(0, 0, 0),
            }
          );
          y = y - 15;

          CDFfirstPage.drawText(
            `    ${
              secondLine
              //.replace(/(\b\d(?![.,])\b|-)/g, "")
              // .trimLeft()
            }.`,
            {
              x: x,
              y: y,
              size: 10,
              font: helveticaFont3,
              color: rgb(0, 0, 0),
            }
          );
          y = y - 15;
        } else {
          CDFfirstPage.drawText(
            `${index + 1}- ${
              condition
              //.replace(/(\b\d(?![.,])\b|-)/g, "")
            }.`,
            {
              x: x,
              y: y,
              size: 10,
              font: helveticaFont3,
              color: rgb(0, 0, 0),
            }
          );
          y = y - 15;
        }
      });

      let guarantees: string[] = [];
      guarantees = this.jira?.guarantees.split(/\r\n|\r|\n/);
      let guaranteesFiltered = guarantees?.filter(
          (guarantee) => guarantee !== " ");
      guaranteesFiltered = guaranteesFiltered?.filter(item => isNaN(Number(item)));
      let xG = 50;
      let yG = 240;

      // if(condition.trim().split(/\s+/).length >= 12) {
      //   let firstLine = condition.split(' ').slice(0,11).join(' ')
      //   let secondLine = condition.split(' ').slice(11).join(' ')
      guaranteesFiltered?.forEach((guarantee, index) => {
        if (guarantee !== "") {
          if (guarantee.trim().split(/\s+/).length >= 12) {
            let firstLine = guarantee.split(" ").slice(0, 11).join(" ");
            let secondLine = guarantee.split(" ").slice(11).join(" ");
            CDFfirstPage.drawText(
              `${index + 1}- ${
                firstLine
                // .replace(/(\b\d(?![.,])\b|-)/g, "")
              }`,
              {
                x: xG,
                y: yG,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
              }
            );
            yG = yG - 15;

            CDFfirstPage.drawText(
              `${
                secondLine
                // .replace(/(\b\d(?![.,])\b|-)/g, "")
              }.`,
              {
                x: xG,
                y: yG,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
              }
            );
            yG = yG - 15;
          } else {
            CDFfirstPage.drawText(
              `${index + 1}- ${
                guarantee
                // .replace(/(\b\d(?![.,])\b|-)/g, "")
              }.`,
              {
                x: xG,
                y: yG,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
              }
            );
            yG = yG - 15;
          }
        }
        // guarantee.secondary.forEach((secondary, indexG) => {
        //   if(secondary !== '') {
        //     CDFthirdPage.drawText(`${index + 1}- ${secondary.replace(/(\b\d(?![.,])\b|-)/g, '')}`,{
        //       x: x + 15,
        //       y: yG,
        //       size: 10,
        //       font: helveticaFont3,
        //       color: rgb(0, 0, 0),
        //     })
        //     yG = yG - 15
        //   }
        // })
      });
    } else {
      let conditions : string [] = [];
      conditions = this.jira?.conditions.split(/[.-]/);
      let conditionsFiltered = conditions?.filter(
          (condition) => condition !== " ");
      conditionsFiltered = conditionsFiltered?.filter(item => isNaN(Number(item)));
      conditionsFiltered?.forEach((condition, index) => {
        if (condition !== "") {
          if (condition.trim().split(/\s+/).length >= 12) {
            let firstLine = condition.split(" ").slice(0, 13).join(" ");
            let secondLine = condition.split(" ").slice(13).join(" ");
            CDFfirstPage.drawText(
                `${index + 1}- ${
                    firstLine
                    // .replace(/(\b\d(?![.,])\b|-)/g, "")
                }`,
                {
                  x: x,
                  y: y,
                  size: 10,
                  font: helveticaFont3,
                  color: rgb(0, 0, 0),
                }
            );
            y = y - 10;

            CDFfirstPage.drawText(
                `${
                    secondLine
                    // .replace(/(\b\d(?![.,])\b|-)/g, "")
                }.`,
                {
                  x: x +12,
                  y: y,
                  size: 10,
                  font: helveticaFont3,
                  color: rgb(0, 0, 0),
                }
            );
            y = y - 15;
          }
          else {
            CDFfirstPage.drawText(
                `${index + 1}- ${
                    condition
                    // .replace(/(\b\d(?![.,])\b|-)/g, "")
                }.`,
                {
                  x: x,
                  y: y,
                  size: 10,
                  font: helveticaFont3,
                  color: rgb(0, 0, 0),
                }
            );
            y = y - 15;
          }
         // y = y - 15;
          /*let secondaries = condition?.secondary?.filter(
              (secondary) => secondary !== ""
          );
          secondaries?.forEach((secondary, index) => {
            if (secondary !== "") {
              CDFfirstPage.drawText(
                  `${index + 1}- ${secondary.replace(/(\b\d(?![.,])\b|-)/g, "")}`,
                  {
                    x: x + 15,
                    y: y,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                  }
              );
              y = y - 15;
            }
          });*/
        }});

      let guarantees: string[] = [];
      guarantees = this.jira?.guarantees.split(/[.-]/);

      let xG = 50;
      let yG = 260;

      let guaranteesFiltered = guarantees?.filter(
        (guarantee) => guarantee !== " ");
      guaranteesFiltered = guaranteesFiltered?.filter(item => isNaN(Number(item)));
      guaranteesFiltered?.forEach((guarantee, index) => {
        if (guarantee !== "") {
          CDFfirstPage.drawText(
            `${index + 1}- ${guarantee
              .replace(/(\b\d(?![.,])\b|-)/g, "")
              .trimLeft()}.`,
            {
              x: xG,
              y: yG,
              size: 10,
              font: helveticaFont3,
              color: rgb(0, 0, 0),
            }
          );
          yG = yG - 15;
        }
        // guarantee.secondary.forEach((secondary, indexG) => {
        //   if(secondary !== '') {
        //     CDFthirdPage.drawText(`${index + 1}- ${secondary.replace(/(\b\d(?![.,])\b|-)/g, '')}`,{
        //       x: x + 15,
        //       y: yG,
        //       size: 10,
        //       font: helveticaFont3,
        //       color: rgb(0, 0, 0),
        //     })
        //     yG = yG - 15
        //   }
        // })
      });
    }
    CDFfirstPage.drawText(
        `${this.arabicDate}`,
        {
          x: 364,
          y: 173,
          size: 10,
          font: helveticaFont3,
          color: rgb(0, 0, 0),
        }
    );

    // Contrat de vente par facilité --------------------------------------------------

    const salesContract = await fetch(this.salesContract).then((res) =>
      res.arrayBuffer()
    );

    const CDVF = await PDFDocument.load(salesContract);
    CDVF.registerFontkit(fontkit);
    const arabicFont4 = await CDVF.embedFont(fontBytes);
    const helveticaFont4 = await CDVF.embedFont(StandardFonts.HelveticaBold);

    const CDVFpages = CDVF.getPages();
    const CDVFfirstPage = CDVFpages[0];

    CDVFfirstPage.drawText(
      `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
      {
        x: 7,
        y: 780,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    CDVFfirstPage.drawText(
      `${this.jira?.cptAgenceResp ? this.jira?.cptAgenceResp : ""}`,
      {
        // x: 400,
        x: 380,
        y: 680,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    CDVFfirstPage.drawText(
      `${this.jira?.cptAgence ? this.jira?.cptAgence : ""}`,
      {
        // x: 400,
        x: 270,
        y: 680,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );
    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.fistNameEnArab
          ? this.information?.personnelInformation?.fistNameEnArab!
          : ""
      }     ${
        this.information?.personnelInformation?.lastNameEnArab
          ? this.information?.personnelInformation?.lastNameEnArab
          : ""
      }`,
      {
        // x: 400,
        x: this.getX(
          470,
          this.information?.personnelInformation?.lastNameEnArab! +
            this.information?.personnelInformation?.fistNameEnArab,
          arabicFont4,
          9
        ),
        y: 626,
        size: 9,
        font: arabicFont4,
        color: rgb(0, 0, 0),
      }
    );
    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.placeOfBrithArabic
          ? this.information?.personnelInformation?.placeOfBrithArabic
          : ""
      }`,
      {
        x: this.getX(
          480,
          this.information?.personnelInformation?.placeOfBrithArabic,
          arabicFont4,
          9
        ),
        y: 608,
        size: 9,
        font: arabicFont4,
        color: rgb(0, 0, 0),
      }
    );
    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.birthDay
          ? this.information?.personnelInformation?.birthDay
          : ""
      }`,
      {
        x: this.getX(
          390,
          this.information?.personnelInformation?.birthDay,
          helveticaFont4,
          9
        ),
        y: 608,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.idCardNumber
          ? this.information?.personnelInformation?.idCardNumber
          : ""
      }`,
      {
        x: this.getX(
          495,
          String(this.information?.personnelInformation?.idCardNumber),
          helveticaFont4,
          7
        ),
        y: 590,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );
    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.deliveryDate
          ? this.information?.personnelInformation?.deliveryDate
          : ""
      }`,
      {
        x: this.getX(
          385,
          String(this.information?.personnelInformation?.deliveryDate),
          helveticaFont4,
          9
        ),
        y: 590,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );
    CDVFfirstPage.drawText(
      `${
        this.information?.personnelInformation?.deliveryDaira
          ? this.information?.personnelInformation?.deliveryDaira
          : ""
      }`,
      {
        x: this.getX(
          300,
          String(this.information?.personnelInformation?.deliveryDate),
          helveticaFont4,
          9
        ),
        y: 590,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    CDVFfirstPage.drawText(
      `${
        this.information?.personnelSituation?.personnelSituationAddress
          ? this.invertNumbers(
              this.information?.personnelSituation?.personnelSituationAddress!
            )
          : ""
      }`,
      {
        x: this.getX(
          465,
          String(
            this.information?.personnelSituation?.personnelSituationAddress
          ),
          arabicFont4,
          9
        ),
        y: 571,
        size: 9,
        font: arabicFont4,
        color: rgb(0, 0, 0),
      }
    );

    CDVFfirstPage.drawText(
      `${
        this.jira?.mtRmb
          ? this.addSeparation(Math.round(100 * this.jira.mtRmb) / 100)
          : this.credit?.reimbursementAmount
          ? this.addSeparation(
              Math.round(100 * this.credit.reimbursementAmount) / 100
            )
          : ""
      }`,
      {
        x: 360,
        y: 270,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    const totalWordsTable = totalToWords.split(" ");
    if (totalWordsTable.length > 5) {
      let firstLine = totalWordsTable.slice(0, 5);
      let secondLine = totalWordsTable.slice(5);

      CDVFfirstPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
        x: 310,
        y: 255,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      });

      CDVFfirstPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
        x: 310,
        y: 240,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      });
    } else {
      CDVFfirstPage.drawText(`${totalToWords ? totalToWords : ""}`, {
        x: 310,
        y: 250,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      });
    }

    CDVFfirstPage.drawText(
      `${
        this.jira?.mtRmb
          ? this.addSeparation(Math.round(100 * this.jira.mtRmb) / 100)
          : this.credit?.reimbursementAmount
          ? this.addSeparation(
              Math.round(100 * this.credit.reimbursementAmount) / 100
            )
          : ""
      }`,
      {
        x: 360,
        y: 205,
        size: 9,
        font: helveticaFont4,
        color: rgb(0, 0, 0),
      }
    );

    // CDVFfirstPage.drawText(
    //   `${this.arabicDate}`,
    //   {
    //     x: 235,
    //     y: 155,
    //     size: 10,
    //     font: helveticaFont4,
    //     color: rgb(0, 0, 0),
    //   }
    // );

    //Saving Files To Download Them Modified

    const pdfBytes1 = await TOR.save();
    this.saveByteArray(`Billet à ordre - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes1);
    const pdfBytes2 = await CCA.save();
    this.saveByteArray(`Convention de compte de cheque - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes2);
    const pdfBytes3 = await CDF.save();
    this.saveByteArray(`Convention de financement - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes3);
    const pdfBytes4 = await CDVF.save();
    this.saveByteArray(`Contrat de vente par facilité - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes4);

    this.loading = false;
    this.downLoadFiles$.next(false);
  }

  saveByteArray(reportName: any, byte: any) {
    const blob = new Blob([byte], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
  }

  addSeparation(value: number): string {
    if (value) {
      const stringValue = value.toString();
      return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      return "";
    }
  }

  downloadInvoices() {
    this.orderService.downloadInvoices(this.orderId , this.requestNumber);
  }

  downloadDocuments() {
    this.createPdf();
    this.downloadInvoices();
  }

  getX(x: number, value: string, font: any, fontSize: number) {
    if (value) {
      const textWidth = font.widthOfTextAtSize(value, fontSize);
      return x - textWidth;
    } else {
      return 0;
    }
  }

  // Dates Manipulating

  isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 5 || day === 6; // Friday (5) or Saturday (6)
  }

  isHoliday(date: Date): boolean {
    const holidays = [
      new Date("2023-07-19"),
      new Date("2023-07-28"),
      new Date("2023-09-27"),
      new Date("2023-04-21"),
      new Date("2023-04-22"),
      new Date("2023-06-28"),
      new Date("2023-06-29"),
      new Date("2023-01-01"),
      new Date("2023-01-12"),
      new Date("2023-03-01"),
      new Date("2023-07-05"),
      new Date("2023-11-01"),
    ];
    return holidays.some(
      (holiday) => holiday.toDateString() === date.toDateString()
    );
  }

  // ChatGPT is for NEWBIES

  addDays(): string {
    if (this.notificationDate) {
      let currentDate = this.notificationDate;
      // console.log("Old Date: ", currentDate);
      // currentDate.setDate(currentDate.getDate() + 2);

      // while (this.isWeekend(currentDate) || this.isHoliday(currentDate)) {
      //   currentDate.setDate(currentDate.getDate() + 1);
      // }
      // console.log(
      //   "New Date: ",
      //   this.datePipe.transform(currentDate, "dd/MM/yyyy")
      // );

      this.arabicDate = this.datePipe.transform(currentDate, "yyyy/MM/dd");
      this.normalDate = this.datePipe.transform(currentDate, "dd/MM/yyyy");
    } else {
      return;
    }
  }

  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  invertNumbers(input: string): string {
    return input.replace(/\d+/g, (match) => {
      return match.split("").reverse().join("");
    });
  }

  createConditionsArray(inputString: string): condition[] {
    if (inputString) {
      const sentences = inputString.split(". ");
      const conditions = [];
      let main = "";
      let secondary = [];

      sentences.forEach((sentence, index) => {
        const colonIndex = sentence.indexOf(":");
        if (colonIndex !== -1) {
          if (main !== "") {
            conditions.push({ main, secondary });
          }
          let sentenceArray = sentence.split(" ");

          if (colonIndex > 20) {
            main = sentence.slice(0, colonIndex + 1);
            secondary = [sentence.slice(colonIndex + 2)];
          } else {
            main =
              sentences[index - 1] + " " + sentence.slice(0, colonIndex + 1);
            secondary = [sentence.slice(colonIndex + 2)];
          }
        } else {
          secondary.push(sentence);
        }
      });

      if (main !== "") {
        conditions.push({ main, secondary });
      }
      return conditions as condition[];
    } else {
      return [] as condition[];
    }
  }

  ngOnInit(): void {



    this.orderService.orderSplit$.subscribe((res) => {
      if (res) {
        this.orderSplit = res;
        this.categories = this.orderSplit.orderItems;
      }
    });

    this.folderService.folderStateSubj$.subscribe((state) => {
      this.canDownload = state === "EK_GUARTENTEES_PENDING_SIGNATURE" ||
          state === "EK_CONTROL_BEFORE_GUARANTEES_PRINTING" ||
          state === "EK_GUARTENTEES_SIGNED_IN_PROGRESS" ||
          state === "EK_PHYSICAL_FOLDER_PENDING" ||
          state === "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK" ||
          state === "EK_WAITING_FOR_CLIENT" ||
          state === "EK_PHYSICAL_FOLDER_PICKEDUP" ||
          state === "EK_PHYSICAL_FOLDER_IN_DELIVERY" ||
          state === "EK_PHYSICAL_FOLDER_DELIVERED" ||
          state === "EK_PHYSICAL_FOLDER_IN_PROGRESS" ||
          state === "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY" ||
          state === "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS" ||
          state === "EK_BANK_PHYSICAL_FOLDER_ACCEPTED" ||
          state === "EK_BANK_PHYSICAL_FOLDER_RESERVE" ||
          state === "EK_BANK_REFUSED" ||
          state === "EK_FOLDER_REFUSED" ||
          state === "EK_ADMIN_FOLDER_CANCELED" ||
          state === "EK_CLIENT_NO_RESPONSE" ||
          state === 'FINANCIAL_FOLDER'
          state === "EK_FOLDER_PICKUP_FAILED";
    });

    this.orderService.orderSplit$.subscribe((res) => {
      if (res) {
        this.orderService.getCredit(res.id).subscribe((cr) => {
          this.credit = cr;
        });

        this.folderService.getJiraByFolderId(res.folderId).subscribe({
          next: (jira) => {
            this.jira = jira;
          },
        });

        this.categoriess = [];

        res.orderItems.forEach((config) => {
          this.productsService
            .getById(config.configurationTO.productId)
            .subscribe((product) => {
              if (product) {
                this.categoryService
                  .getParentCategoryById(product.categoryId)
                  .subscribe((category) => {
                    if (category) {
                      this.categoriess.push(category);
                    }
                  });
              }
            });
        });
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.orderId = Number(params.get("idOrder"));
      this.folderId = Number(params.get("idFolder"));
    });
    this.orderService.getCredit(this.orderId).subscribe((cr) => {
      this.credit = cr;
    });

    this.documentService
      .downloadDocuments("GUARANTEES_EMPTY", this.orderId)
      .subscribe({
        next: (res: any) => {
          const obj = res.body.find((o: any) => {
            switch (o.documentsTitle) {
              case "TICKET_TO_ORDER": {
                this.ticketToOrder = o.documentUrlPreSigned;
                break;
              }
              case "CHECK_ACCOUNT_AGREEMENT": {
                this.checkAccountAgreement = o.documentUrlPreSigned;
                break;
              }
              case "FINANCING_AGREEMENT": {
                this.financingAgreement = o.documentUrlPreSigned;
                break;
              }
              case "SALES_CONTRACT_BY_FACILITY": {
                this.salesContract = o.documentUrlPreSigned;
                break;
              }
            }
          });
        },
      });

    this.documentService
      .getDocumentsByFolderId(this.folderId)
      .subscribe((res: any) => {
        this.guaranteesDocuments$.next(
          res.body.filter(
            (document: DocumentModel) => document.documentsType === "GUARANTEES"
          )
        );
        this.invoicessDocuments$.next(
          res.body.filter(
            (document: DocumentModel) => document.documentsType === "INVOICE"
          )
        );
      });

    this.documentService.getNotifDate(this.folderId).subscribe({
      next: (res) => {
        if (res.body) {
          this.notificationDate = new Date(res.body);
          this.addDays();
        }
      },
      error: (err) => {
        console.log("error :", err);
      },
    });


  }
}
