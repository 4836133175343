import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ImagesService} from "../ek-services/images.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as ImageActions from "../ek-actions/image.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import * as CategoryActions from "../ek-actions/category.action";


@Injectable()
export class ImageEffects {


    constructor(private actions$: Actions, private imageService: ImagesService, private store: Store<AppState>) {
    }

    ImagePageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImageActions.ImagesPageRequested),
            switchMap((action) =>
                this.imageService.getAllPaged(action.page, action.size)
                    .pipe(
                        map(imagePage => ImageActions.ImagesPageLoadedSuccessfully(
                            {
                                images: imagePage.content,
                                page: imagePage.totalElements,
                                totalCount: imagePage.totalElements
                            })),
                        catchError(error => of(ImageActions.ImagesPageLoadFailed({error})))
                    ))));

    ImageCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImageActions.ImageCreated),
            switchMap((action) =>
                this.imageService.save(action.image)
                    .pipe(
                        map(data => ImageActions.ImageCreatedSuccessfully({image: data})),
                        catchError(error => of(ImageActions.ImageCreationFailed({error})))
                    ))));

    ImageUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImageActions.ImageUpdated),
            switchMap((action) =>
                this.imageService.update(action.image)
                    .pipe(
                        map(image => ImageActions.ImageUpdatedSuccessfully({
                            image,
                            partialImage: action.partialImage
                        })),
                        catchError(error => of(ImageActions.ImageUpdateFailed({error})))
                    ))));

    ImageDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImageActions.ImageDeleted),
            switchMap((action) =>
                this.imageService.delete(action.imageId)
                    .pipe(
                        map(() => ImageActions.ImageDeletedSuccessfully({imageId: action.imageId})),
                        catchError(error => of(ImageActions.ImageDeleteFailed({error})))
                    ))));
    ImageUpdatedOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImageActions.ImageUpdatedOrder),
            switchMap((action) =>
                this.imageService.changeOrderImage(action.image.id,action.order,action.position)
                    .pipe(
                        map(image => ImageActions.ImageUpdatedOrderSuccessfully({
                            image:action.image,
                            partialImage: action.partialImage,
                            order:action.order
                        })),
                        catchError(error => of(ImageActions.ImageUpdatedOrderFailed({error})))
                    ))));
}
