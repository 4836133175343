import {BaseModel} from "../../_base/crud";

export class GlobalVariableModel extends BaseModel {

     annualRate:number;

     startDate:string;

     endDate:string;

     id: number;
}