// Angular
import {ApplicationRef, Injectable} from "@angular/core";
// RxJS
import {BehaviorSubject, Subject} from "rxjs";
import {CountFolderState} from "../../../ek-e-commerce/ek-models/count-statistics.model";
import {HttpClient} from "@angular/common/http";
import {FOLDER_STATES} from "../../../../views/pages/apps/Shared/Constants/folderStates";
import {FoldersService} from "../../../ek-e-commerce/ek-services/folders.service";

export interface SUBMENU {
    title: string;
    page: string;
    disabled: boolean;
    order: number;
    bullet: string;
    permission: string[];
}

@Injectable()
export class MenuConfigService {

    // Public properties
    onConfigUpdated$: Subject<any>;

    // Private properties
    private menuConfig: any;
    statisticsSubject$ = new BehaviorSubject<CountFolderState[]>([]);
    GlobalFolderStatisticsSubject$ = new BehaviorSubject<CountFolderState[]>([]);

    currentRole: string = '';

    /**
     * Service Constructor
     */
    constructor(
        protected http: HttpClient,
        private folderService: FoldersService,
        private ref: ApplicationRef
    ) {
        // register on config changed event and set default config
        this.onConfigUpdated$ = new Subject();

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

    }

    /**
     * Returns the menuConfig
     */
    getMenus() {
        return this.menuConfig;
    }

    /**
     * Load config
     *
     * @param config
     */
    loadConfigs(config: any) {

        //load folder states list
        this.getFolderStates();

        this.getGlobalFolderStates();

        //add folder states list to menu config
        this.menuConfig = this.addFolderStatesToFolderSubmenu(config);
        this.onConfigUpdated$.next(this.menuConfig);
    }

    addFolderStatesToFolderSubmenu(config: any) {

        //all current user folder states
        this.statisticsSubject$.subscribe((data) => {
            if (data && data.length > 0) {

                let subMenuSet = [
                    {
                        title: "Tous",
                        page: "/ek-ecommerce/folders",
                        disabled: false,
                        permission: ["ALL_FOLDER", "READ_FOLDER", "CREATE_FOLDER",'ONLY_READ', 'READ_FOLDER'],
                    },
                ];

                data.forEach((item) => {
                    let submenu: SUBMENU = {
                        title:
                            this.transFolderState(item.folderState) +
                            " (" +
                            item.totalCount +
                            ")",
                        page: "/ek-ecommerce/folders?folderState=" + item.folderState,
                        order: this.getFolderStateOrder(item.folderState),
                        bullet: 'number',
                        disabled: false,
                        permission: ["ALL_FOLDER", "READ_FOLDER", "CREATE_FOLDER",'ONLY_READ','READ_FOLDER'],
                    };

                    subMenuSet.push(submenu);
                });

                // Add submenus to the "Dossiers" menu dynamically based on the API response
                let foldersSubMenu = config.aside.items.find(
                    (item) => item.title === "Dossiers"
                ).submenu;

                subMenuSet.forEach((item) => {
                    foldersSubMenu.push(item);
                });

                //sort folder states by their order number
                foldersSubMenu = this.sortByOrder(foldersSubMenu);

                config.aside.items.find(
                    (item) => item.title === "Dossiers"
                ).submenu = foldersSubMenu;
                this.ref.tick();

                return config;

            } else {
                config.aside.items.find(
                    (item) => item.title === "Dossiers"
                ).submenu = [];
                this.ref.tick();
            }
        });


        //states of all users except banker states
        this.GlobalFolderStatisticsSubject$.subscribe((data) => {

            if (data && data.length > 0) {

                let subMenuSet = [];

                data.forEach((item) => {

                    let submenu: SUBMENU = {
                        title:
                            this.transFolderState(item.folderState) +
                            " (" +
                            item.totalCount +
                            ")",
                        page: "/ek-ecommerce/folders?folderState=" + item.folderState,
                        order: this.getFolderStateOrder(item.folderState),
                        bullet: 'number',
                        disabled: false,
                        permission: ["ALL_FOLDER", "READ_FOLDER", "CREATE_FOLDER",'ONLY_READ','READ_FOLDER'],
                    };

                    subMenuSet.push(submenu);
                });

                // Add submenus to the "Dossiers" menu dynamically based on the API response
                let foldersSubMenu = config.aside.items.find(
                    (item) => item.title === "Statuts Global"
                ).submenu;

                subMenuSet.forEach((item) => {
                    foldersSubMenu.push(item);
                });

                //sort folder states by their order number
                foldersSubMenu = this.sortByOrder(foldersSubMenu);

                config.aside.items.find(
                    (item) => item.title === "Statuts Global"
                ).submenu = foldersSubMenu;
                this.ref.tick();

                return config;

            } else {

                if (this.currentRole !== 'ROLE_ADMIN'
                    && this.currentRole !== 'ROLE_SUPERADMIN'
                    && this.currentRole !== 'ROLE_BANKER_EK'
                ) {
                    config.aside.items = config.aside.items.filter((item) => item.title !== "Statuts Global");
                } else {
                    config.aside.items.find(
                        (item) => item.title === "Statuts Global"
                    ).submenu! = [];
                }
                this.ref.tick();
            }
        });


        return config;
    }


    sortByOrder(items) {
        return items.sort((a, b) => a.order - b.order);
    }

    getFolderStates() {
        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                if (res) this.statisticsSubject$.next(res.countFolderState);
            },
        });
    }

    getGlobalFolderStates() {
        this.folderService.GlobalFolderStatistics().subscribe({
            next: (res) => {
                if (res) this.GlobalFolderStatisticsSubject$.next(res.countFolderState);
            },
        });
    }

    transFolderState(folderState: string) {
        return FOLDER_STATES?.find(item => item.value === folderState)?.name;
    }

    getFolderStateOrder(folderState: string) {
        return FOLDER_STATES?.find(item => item.value === folderState)?.order;
    }

}
