import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RegionService} from "../../../../../../core/ek-e-commerce/ek-services/region.service";

@Component({
    selector: 'kt-pos-list-pop-up',
    templateUrl: './pos-list-pop-up.component.html',
    styleUrls: ['./pos-list-pop-up.component.scss']
})
export class PosListPopUpComponent implements OnInit {

    listOfPOS = [];
    constructor(private dialogRef: MatDialogRef<PosListPopUpComponent>,
                private regionService: RegionService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.regionService.getPOSbyRegion(this.data.idRegion).subscribe({
            next : (data) => {
                this.listOfPOS = data;
            }
        });
    }

    close() {
        this.dialogRef.close();
    }
}
