import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {PromoCodeService} from "../_services/promoCode.service";
import * as PromoCodeActions from "../_actions/promoCode.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";

@Injectable()
export class PromoCodeEffects {


    constructor(private actions$: Actions,
                private promoCodeService: PromoCodeService,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
    ) {
    }


    PromotionCodePageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodeActions.PromotionCodesPageRequested),
            switchMap((action) =>
                this.promoCodeService.getAllPagesSearch( action.page.filter, action.page.sortField, action.page.sortOrder,action.page.pageNumber, action.page.pageSize, action.promotionCodeType, action.promoState , action.startingDate, action.endingDate)

                    .pipe(
                        map(promoCodePage => PromoCodeActions.PromotionCodesLoadedSuccessfully(
                            {
                                promotionCodes: promoCodePage.content,
                                page: promoCodePage.totalElements,
                                totalCount: promoCodePage.totalElements
                            })),
                        catchError(error => of(PromoCodeActions.PromotionCodesLoadFailed({error})))
                    ))));

    PromoCodeCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodeActions.PromoCodeCreated),
            switchMap((action) =>
                this.promoCodeService.savePromoCode(action.promoCode)
                    .pipe(
                        map(data => this.isUserCanCreatePromoCode(data)
                        ),
                        catchError(error => of(PromoCodeActions.PromoCodeCreationFailed({error})))
                    ))));

    PromoCodeUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodeActions.PromoCodeUpdated),
            switchMap((action) =>
                this.promoCodeService.update(action.promoCode)
                    .pipe(
                        map(promoCode => {
                            return PromoCodeActions.PromoCodeUpdatedSuccessfully({
                                promoCode,
                                partialPromoCode: action.partialPromoCode
                            });
                        }),
                        catchError(error => of(PromoCodeActions.PromoCodeUpdateFailed({error})))
                    ))));


    PromoCodeDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodeActions.PromoCodeDeleted),
            switchMap((action) =>
                this.promoCodeService.deletePromoCode(action.promoCodeId)
                    .pipe(
                        map(res => this.deletePromoCodeProcessing(res.message, action.promoCodeId)),
                        catchError(error => of(PromoCodeActions.PromoCodeDeleteFailed({error})))
                    ))));

    PromoCodeUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodeActions.PromoCodeUpdatedActivate),
            switchMap((action) =>
                this.promoCodeService.changeActivatePromoCode(action.promoCode.id, action.activate, action.updater)
                    .pipe(
                        map(res => this.message(res.message, action.promoCode, action.partialPromoCode, action.activate, action.updater)
                        ),
                        catchError(error => of(PromoCodeActions.PromoCodeUpdateActivateFailed({error})))
                    ))));


    message(message, promoCode, partialPromoCode, activate, updater) {

        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
        return PromoCodeActions.PromoCodeUpdatedActivateSuccessfully({
            promoCode: promoCode,
            partialPromoCode: partialPromoCode,
            activate: activate,
            updater: updater,

        })

    }

    //

    isUserCanCreatePromoCode(data) {

        switch (data.message) {
            case "can not create promo code ,there is a discount active":
                this.openDialoge(data.body, "discountCase");
                return PromoCodeActions.PromoCodeCreatedSuccessfully({response: data.body});
                break;
            case "can not create promo code ,there is a discount client active":
                this.openDialoge(data.body, "discountClientCase");
                return PromoCodeActions.PromoCodeCreatedSuccessfully({response: data.body});
                break;
            default:
                this.layoutUtilsService.showActionNotification("Promo Code a été creé avec succes", MessageType.Update, 10000, true, true);
                return PromoCodeActions.PromoCodeCreatedSuccessfully({response: data.body});
                break;
        }
    }

    openDialoge(data, case_) {


        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '500px';

        if (case_ === 'discountClientCase') {
            dialogConfig.data = {
                list: data,
                case: 'discountClientCase',
                title: 'Liste des discounts client',
                errorMs: 'Création du code promo Rejeté, une ou plusieurs remise client existe déjà durant cette période'
            };
        } else {
            dialogConfig.data = {
                list: data,
                case: 'discountCase',
                title: 'Liste des configurations',
                errorMs: 'Création du code promo Rejeté, une promotion existe déjà durant cette période'
            };
        }
    }

    deletePromoCodeProcessing(message, promoCodeId) {
        if (message === 'data.integrity.violation') {
            let msg = "Ce code ne peut pas etre supprimer car il a était appliquer sur une ou plusieurs commande, il par conséquent être désactiver!"
            this.layoutUtilsService.showActionNotification(msg, MessageType.Delete, 10000, true, true);
            return PromoCodeActions.PromoCodeDeleteFailed(message);
        }

        this.layoutUtilsService.showActionNotification("Code promo à été  supprimé avec succès!", MessageType.Delete, 10000, true, true);
        return PromoCodeActions.PromoCodeDeletedSuccessfully({promoCodeId: promoCodeId})
    }
}
