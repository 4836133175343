import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'kt-circular-slider',
    templateUrl: './circular-slider.component.html',
    styleUrls: ['./circular-slider.component.scss']
})
export class CircularSliderComponent implements OnInit {

    @Input() scoreValue: number = 5;

    red = "#ff6261";
    orange = "#ff893c";
    yellow = "#ffd127";
    blue = "#0294fa";
    green = "#3eda91";

    arc = 180;
    arcWidth = 200;
    arcCenter = 250
    strokeWidth = 16;
    circleRadius = 12;
    min = 0;
    max = 100;
    range = this.max - this.min;
    span1 = 18;
    span2 = 35;
    span3 = 70;
    span4 = 80;
    scoreColor = '';
    score = 5;
    margin = 3;
    scoreText = "";

    @ViewChild('sliderSvg') sliderSvg: ElementRef<SVGSVGElement>;
    @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;

    ngOnInit() {
        this.score = this.scoreValue;
    }

    constructor(private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        this.score = this.scoreValue;
        this.setSlides();
    }

    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        let angleInRadians = (angleInDegrees - 180) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    describeArc(x, y, radius, startAngle, endAngle) {

        let start = this.polarToCartesian(x, y, radius, endAngle);
        let end = this.polarToCartesian(x, y, radius, startAngle);

        let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        let d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;
    }

    moveCircle(x, y, radius, endAngle, color) {

        const svgElement = this.sliderSvg.nativeElement;
        const circle = svgElement.getElementById('circle');
        const textScore = svgElement.getElementById('score');
        const textStatus = svgElement.getElementById('status');

        let start = this.polarToCartesian(x, y, radius, endAngle);

        if (endAngle >= 0 || endAngle <= 180) {
            circle.setAttribute("cx", start.x);
            circle.setAttribute("cy", start.y);
            circle.setAttribute("r", "15");
            circle.setAttribute("stroke", color);
            circle.setAttribute("stroke-width", "8");

            textScore.innerHTML = this.scoreText;
            textStatus.innerHTML = this.scoreValue.toString();

        } else {
            this.renderer.setStyle(circle, 'display', 'none');
        }
    }

    filterRange(r) {

        r = r - this.min;
        r = Math.round(r / this.range * 180);
        return r;

    }

    alterArc(arc, color, start, end) {

        arc.setAttribute("d", this.describeArc(this.arcCenter, 300, this.arcWidth, start, end));
        arc.setAttribute("stroke", color);
        arc.setAttribute("stroke-width", this.strokeWidth.toString());

    }

    setSlides() {

        if (this.score < this.span1) {
            this.scoreColor = this.red;
            this.scoreText = "FAIBLE";
        } else if (this.score >= this.span1 && this.score < this.span2) {
            this.scoreColor = this.orange;
            this.scoreText = "PASSABLE";
        } else if (this.score >= this.span2 && this.score < this.span3) {
            this.scoreColor = this.yellow;
            this.scoreText = "MOYENNE";
        } else if (this.score >= this.span3 && this.score < this.span4) {
            this.scoreColor = this.blue;
            this.scoreText = "BIEN";
        } else if (this.score >= this.span4 && this.score <= this.max) {
            this.scoreColor = this.green;
            this.scoreText = "EXCELLENT";
        }


        this.span1 = this.filterRange(this.span1);
        this.span2 = this.filterRange(this.span2);
        this.span3 = this.filterRange(this.span3);
        this.span4 = this.filterRange(this.span4);
        this.max = this.filterRange(this.max);
        this.score = this.filterRange(this.score);

        let range1S = this.margin;
        let range1E = this.span1 - this.margin;
        let range2S = this.span1 + this.margin;
        let range2E = this.span2 - this.margin;
        let range3S = this.span2 + this.margin;
        let range3E = this.span3 - this.margin;
        let range4S = this.span3 + this.margin;
        let range4E = this.span4 - this.margin;
        let range5S = this.span4 + this.margin;
        let range5E = this.max - this.margin;

        // var arc0 = document.getElementById("arc0");
        let arc1 = document.getElementById("arc1");
        let arc2 = document.getElementById("arc2");
        let arc3 = document.getElementById("arc3");
        let arc4 = document.getElementById("arc4");
        let arc5 = document.getElementById("arc5");
        let circle = document.getElementById("circle");
        let textScore = document.getElementById("score");
        let textStatus = document.getElementById("status");
        this.moveCircle(this.arcCenter, 300, this.arcWidth, this.score, this.scoreColor);

        // alterArc(arc0, "black", 0, 180)
        this.alterArc(arc1, this.red, range1S, range1E)
        this.alterArc(arc2, this.orange, range2S, range2E)
        this.alterArc(arc3, this.yellow, range3S, range3E)
        this.alterArc(arc4, this.blue, range4S, range4E)
        this.alterArc(arc5, this.green, range5S, range5E)
    }

}