// SERVICES
export { AuthNoticeService } from './auth-notice/auth-notice.service';

// DATA SOURCERS
export { UsersDataSource } from './_data-sources/users.datasource';

// ACTIONS
import * as AuthActions from './_actions/auth.actions';
import * as PermissionActions from './_actions/permission.actions'
export {AuthActions,PermissionActions}  ;


// export {
//     RoleOnServerCreated,
//     RoleCreated,
//     RoleUpdated,
//     RoleDeleted,
//     RolesPageRequested,
//     RolesPageLoaded,
//     RolesPageCancelled,
//     AllRolesLoaded,
//     AllRolesRequested,
//     RoleActionTypes,
//     RoleActions
// } from './_actions/role.actions';
export {
    UserCreated,
    UserUpdated,
    UserPwdUpdated,
    UserDeleted,
    UserOnServerCreated,
    UsersPageLoaded,
    UsersPageCancelled,
    UsersPageToggleLoading,
    UsersPageRequested,
    UsersActionToggleLoading
} from './_actions/user.actions';

// EFFECTS
export { AuthEffects } from './_effects/auth.effects';
export { PermissionEffects } from './_effects/permission.effects';
export { UserEffects } from './_effects/user.effects';

// REDUCERS
 export { authReducer } from './_reducers/auth.reducers';
export { permissionsReducer } from './_reducers/permission.reducers';
// export { rolesReducer } from './_reducers/role.reducers';
export { usersReducer } from './_reducers/user.reducers';
import * as FromAuthReducer from './_reducers/auth.reducers';
export {FromAuthReducer}

// SELECTORS
export {
    isLoggedIn,
    isLoggedOut,
    isUserLoaded,
    isLoading,
    currentToken,
    currentUser,
    error,

 } from './_selectors/auth.selectors';
export {
    selectPermissionById,
    selectAllPermissions,
    selectAllPermissionsIds,
    allPermissionsLoaded
} from './_selectors/permission.selectors';

export {
    selectUserById,
    selectError,
    selectUsersPageLoading,
    selectLastCreatedUserId,
    selectUsersInStore,
    selectHasUsersInStore,
    selectUsersPageLastQuery,
    selectUsersActionLoading,
    selectUsersShowInitWaitingMessage
} from './_selectors/user.selectors';

// GUARDS
export { AuthGuard } from './_guards/auth.guard';
export { ModuleGuard } from './_guards/module.guard';

// MODELS
export { User } from './_models/user.model';
export { Permission } from './_models/permission.model';
export { Role } from './_models/role.model';
export { Address } from '../ek-e-commerce/ek-models/address.model';
export { SocialNetworks } from './_models/social-networks.model';
export { AuthNotice } from './auth-notice/auth-notice.interface';

export { AuthDataContext } from './_server/auth.data-context';
