import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {
    selectCategoriesActionLoading,
    selectCategoryProgress,
    selectlastAction,
    selectLastCreatedCategoryId
} from "../../../../../../core/ek-e-commerce/ek-selectors/ek-category.selector";
import {Update} from "@ngrx/entity";
// Actions
import * as CategoryActions from "../../../../../../core/ek-e-commerce/ek-actions/ek-category.action";
import {CategoriesService} from "../../../../../../core/ek-e-commerce/ek-services/categories.service";
import {NgxPermissionsService} from "ngx-permissions";
import {EkCategoryModel} from "../../../../../../core/ek-e-commerce/ek-models/ek-category.model";
import {FormsValidationService} from "../../../Shared/services/forms-validation.service";
import Keyboard from "simple-keyboard";
import layout from "simple-keyboard-layouts/build/layouts/arabic";

@Component({
    selector: 'kt-ek-category-edit',
    templateUrl: './ek-category-edit.component.html',
    styleUrls: ['./ek-category-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class EkCategoryEditComponent implements OnInit {


    //Observables
    progress$: Observable<number>;

    // Public properties
    categoryToAdd: EkCategoryModel = new EkCategoryModel();
    categoryToUpdate: EkCategoryModel = new EkCategoryModel();

    category: EkCategoryModel;
    categoryForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    parents: EkCategoryModel[];
    private updater: string;

    //Permissions
    PERMISSIONS = ['ALL_CATEGORY', 'UPDATE_CATEGORY'];
    canEdit = false;

    // Private properties
    private componentSubscriptions: Subscription[] = [];
    value = "";
    currentRole = '';
    keyboard: Keyboard;
    show: boolean = false;
    constructor(public dialogRef: MatDialogRef<EkCategoryEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                private categoriesService: CategoriesService,
                private formsValidationService: FormsValidationService,
                private ngxPermissionService: NgxPermissionsService) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.store.pipe(select(selectCategoriesActionLoading)).subscribe(res => this.viewLoading = res);
        this.category = this.data.category;
        this.categoryToUpdate.image = this.category.image;
        this.createForm();
        this.progress$ = this.store.select(selectCategoryProgress);
        this.getParents();
    }


    /**
     * Get Parents
     */
    private getParents() {
        const parentsSub = this.categoriesService.getAll().subscribe(
            res => {
                this.parents = res;
            }
        );
        this.componentSubscriptions.push(parentsSub);
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.componentSubscriptions.forEach(sub => sub.unsubscribe())
    }


    createForm() {
        this.categoryForm = this.fb.group({
            label: [this.category.label, Validators.required],
            labelArab: [this.category.labelArabic, Validators.compose([ Validators.required]) ],
            description: [this.category.description],
            parentId: [this.category.parentId],
            image: [this.category.image],
            order: [this.category.position],
            isVisible: [this.category.isVisible, Validators.required],
        });
        this.checkPermissionToUpdate();
    }


    /**
     * Returns page title
     */
    getTitle(): string {
        if (this.category.id > 0) {
            return `Modifier la catégorie '${this.category.label} '`;
        }

        return 'Nouvelle catégorie';
    }

    /**
     * Check control is invalid
     * @param controlName
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.categoryForm.controls[controlName];
        return control.invalid && control.touched;
    }


    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareCategory(): EkCategoryModel {
        const controls = this.categoryForm.controls;
        const _category = new EkCategoryModel();
        _category.id = this.category.id;
        _category.label = controls.label.value;
        _category.labelArabic = controls.labelArab.value;
        _category.description = controls.description.value;
        _category.parentId = controls.parentId.value;
        _category.image = controls.image.value;
        _category.position = controls.order.value;
        _category.isVisible = controls.isVisible.value;
        return _category;
    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            if (!hasPermission) {
                this.categoryForm.disable();
                this.canEdit = false;
            } else
                this.canEdit = true;
        });
    }

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.categoryForm.controls;
        /** check form */
        if (this.categoryForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedCategory = this.prepareCategory();
        editedCategory.updater = this.updater;

        if (editedCategory.id > 0) {
            this.updateCategory(editedCategory);
        } else {
            this.createCategory(editedCategory);
        }
    }

    /**
     * Update category
     *
     * @param _category: CategoryModel
     */
    updateCategory(_category: EkCategoryModel) {
        this.categoryToUpdate.id = _category.id;
        this.categoryToUpdate.label = _category.label;
        this.categoryToUpdate.labelArabic = _category.labelArabic;
        this.categoryToUpdate.description = _category.description;
        this.categoryToUpdate.parentId = _category.parentId;
        this.categoryToUpdate.position = _category.position;
        this.categoryToUpdate.isVisible = _category.isVisible;
        const updateCategory: Update<EkCategoryModel> = {
            id: this.categoryToUpdate.id,
            changes: this.categoryToUpdate
        };

        this.store.dispatch(CategoryActions.CategoryUpdated({
            partialCategory: updateCategory,
            category: this.categoryToUpdate
        }));

        this.store.select(selectlastAction).subscribe(res => {
            if (res === 'EDIT.UPDATE_MESSAGE')
                this.dialogRef.close({_category, isEdit: true});

        })
    }

    /**
     * Create category
     *
     * @param _category: CategoryModel
     */
    createCategory(_category: EkCategoryModel) {
        _category.id = undefined;
        this.categoryToAdd.label = _category.label;
        this.categoryToAdd.labelArabic = _category.labelArabic;
        this.categoryToAdd.parentId = _category.parentId;
        this.categoryToAdd.description = _category.description;
        this.categoryToAdd.position = _category.position;
        this.categoryToAdd.isVisible = _category.isVisible;

        this.store.dispatch(CategoryActions.CategoryCreated({category: this.categoryToAdd}));

        const lastCreation = this.store.pipe(
            select(selectLastCreatedCategoryId),
        ).subscribe(res => {
            if (!res) {
                return;
            }
            this.dialogRef.close({_category, isEdit: false});
        });
        this.componentSubscriptions.push(lastCreation);


    }
    ngAfterViewInit() {
        if (!this.keyboard)
        {
            this.keyboard = new Keyboard({
                onChange: input => this.onChange(input),
                onKeyPress: button => this.onKeyPress(button),
                ...layout,
                rtl: true
            });
        }
    }

    onChange = (input: string) => {
        this.value = input;
        this.categoryForm.get('labelArab').setValue(input)
    };

    onKeyPress = (button: string) => {
        if (button === "{shift}" || button === "{lock}") this.handleShift();
    };

    onInputChange = (event: any) => {
        this.keyboard.setInput(event.target.value);
    };

    handleShift = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    };
    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    imageInputChange(fileInputEvent: any) {
        this.categoryToAdd.file = fileInputEvent.target.files[0];
        this.categoryToUpdate.file = fileInputEvent.target.files[0];
    }


}
