import {createAction, props} from "@ngrx/store";
import {CategoryPopularModel} from "../_models/category-popular.model";
import {CategoryModel} from "../../ek-e-commerce/ek-models/category.model";
import {Update} from "@ngrx/entity";


export const CategoriesPopularPageRequested = createAction('[Category List Page] Categories Popular Page Requested ');
export const CategoriesPopularPageLoadedSuccessfully = createAction('[Category API] Categories Popular Page Loaded Successfully ',props<{categories: CategoryPopularModel[],totalCount: number}>());
export const CategoriesPopularPageLoadFailed = createAction('[Category API] Categories Popular Page Request Failed ',props<{error: any}>());

export const CategoryPopularUpdatedActivate = createAction('[Category List Page] Category Updated activate',props<{category: CategoryPopularModel, partialCategory: Update<CategoryPopularModel>, activate:boolean, updater: string}>());
export const CategoryPopularUpdatedActivateSuccessfully = createAction('[Category List Page] Category Updated Successfully ',props<{category: CategoryPopularModel, partialCategory: Update<CategoryPopularModel>,activate:boolean, updater: string}>());
export const CategoryPopularUpdateActivateFailed = createAction('[Category List Page] Category Update Failed ',props<{error: any}>());
