import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {GlobalVariableModel} from "../ek-models/globalVariable.model";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {
    List
} from "../../../views/pages/apps/ek-e-commerce/folders/folder-steps/folder-step-three/folder-step-three.component";


@Injectable()
export class GlobalVariableService extends AbstractService<GlobalVariableModel> {
    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}globalVariable`;
    }

   public createGlobalVariable(model: GlobalVariableModel):Observable<Response<GlobalVariableModel>>{
       // const url = `${ADMIN_CONFIG.apiEndpoint}`
       return this.http.post<Response<GlobalVariableModel>>(this.url, model)
   }
    public  getAllGlobalVariable(): Observable<Response<GlobalVariableModel[]>> {
      //  const url = `${this.url}`;
        return this.http.get<Response<GlobalVariableModel[]>>(this.url);
    }

}