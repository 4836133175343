import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Discount_CustomerModel} from '../_models/discount_Customer.model';
import {QueryParamsModel} from "../../_base/crud";
import {ConfigurationModel} from "../../ek-e-commerce/ek-models/configuration.model";


export const CustomerdiscountCreated = createAction('[Customerdiscount List Page] DiscountCustomer Created ',props<{discountCustomer: Discount_CustomerModel}>());
export const CustomerdiscountCreatedSuccessfully = createAction('[Customerdiscount List Page] DiscountCustomer Created Successfully ',props<{discountCustumer: any}>());
export const CustomerdiscountCreationFailed = createAction('[Customerdiscount List Page] DiscountCustomer Creation Failed ',props<{error: any}>());
export const CustomerdiscountCanNotBeCreated = createAction('[configurations List Page] DiscountCustomer can not be Created ',props<{configurations: ConfigurationModel[]}>());

export const CustomerdiscountUpdated = createAction('[Customerdiscount List Page] DiscountCustomer Updated ',
    props<{discountCustumer: Discount_CustomerModel ,partialDiscount:Update<Discount_CustomerModel> }>());



export const CustomerdiscountUpdatedSuccessfully = createAction('[Customerdiscount List Page] DiscountCustomer Updated Successfully ',
    props<{discountCustumer: Discount_CustomerModel, partialDiscount:Update<Discount_CustomerModel> }>());
export const CustomerdiscountUpdatedFailed = createAction('[Customerdiscount List Page] DiscountCustomer Update Failed ',props<{error: any}>());

export const CustomerdiscountDeleted = createAction('[Customerdiscount List Page] DiscountCustomer Deleted ',props<{discountCustumerId: number}>());
export const CustomerdiscountDeletedSuccessfully = createAction('[Customerdiscount List Page] DiscountCustomer deleted Successfully ',props<{discountCustumerId: number}>());
export const CustomerdiscountDeleteFailed = createAction('[Customerdiscount List Page] DiscountCustomer delete Failed ',props<{error: any}>());



export const CustomerDiscountUpdatedActivate = createAction('[DiscountCustomer Status] Change CustumerDiscount Status',
    props<{discountCustumer : Discount_CustomerModel; partialdiscountCustumer: Update<Discount_CustomerModel>, active:boolean, event:any}>());
export const CustomerDiscountUpdatedActivateSuccessfully = createAction('[Customerdiscount List Page] DiscountCustomer Updated Successfully ',
    props<{discountCustumer: Discount_CustomerModel, partialDiscountCustomer: Update<Discount_CustomerModel>, active:boolean,}>());
export const CustomerDiscountUpdateActivateFailed = createAction('[Category List Page] Category Update Failed ',
    props<{error: any}>());
export const CustomerdiscountById = createAction('[Customerdiscount List Page] DiscountCustomer By Id ',props<{discountCustumerId: number}>());
export const CustomerdiscountBuIdSuccessfully = createAction('[Customerdiscount List Page] DiscountCustomer getById Successfully ',props<{discountCustumers: Discount_CustomerModel}>());
export const CustomerdiscountByIdFailed = createAction('[Customerdiscount List Page] DiscountCustomer delete Failed ',props<{error: any}>());

export const DiscountSearchRequested = createAction('[CustomersDiscount List Page] CustomersDiscount Page Filtered By Date Requested ',props<{page: QueryParamsModel,  id:number,discountState:string}>());
export const DiscountSearchLoadedSuccessfully = createAction('[CustomersDiscount API] CustomersDiscount Page Filtered By Date Loaded Successfully ',props<{Discount_Customer: Discount_CustomerModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const DiscountSearchLoadFailed = createAction('[CustomersDiscount API] CustomersDiscount Page Filtered By Date Request Failed ',props<{error: any}>());


export const DiscountClientFilterByDate = createAction('[DiscountClient List Page] PromoCode Page Filtered By Date Requested ',props<{page: QueryParamsModel, startingDate: string, endingDate: string,clientId:number}>());
export const DiscountClientFilterByDateSuccessfully = createAction('[DiscountClient List Page] PromoCode Updated Successfully ',props<{Discount_Client: Discount_CustomerModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const DiscountClientFilterByDateFailed = createAction('[DiscountClient List Page] PromoCode Update Failed ',props<{error: any}>());
