import {BaseModel} from '../../_base/crud';

export class CharacteristicModel extends BaseModel{

    id: number;
    name: string;
    type: string;
    _isEditMode: boolean;


    clear(){
        this.id = undefined;
        this.name = '';
        this.type = '';
    }
}

