import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {CategoryModel} from "../../../../../../core/ek-e-commerce/ek-models/category.model";
import {BrandModel} from "../../../../../../core/ek-e-commerce/ek-models/brand.model";
import * as BrandActions from "../../../../../../core/ek-e-commerce/ek-actions/brand.action";
import * as ProductActions from "../../../../../../core/ek-e-commerce/ek-actions/ek-product.actions";
import {LayoutUtilsService, MessageType, QueryParamsModel, TypesUtilsService} from "../../../../../../core/_base/crud";
import {selectCategoriesInStore} from "../../../../../../core/ek-e-commerce/ek-selectors/category.selector";
import {selectLastCreatedProductId,selectProductById} from "../../../../../../core/ek-e-commerce/ek-selectors/ekproduct.selectors";
import {selectBrandsInStore} from "../../../../../../core/ek-e-commerce/ek-selectors/brand.selector";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {
    MatAutocomplete,
    MatDialog,
    MatAutocompleteSelectedEvent,
    MatChipInputEvent
} from "@angular/material";
import {LayoutConfigService, SubheaderService} from "../../../../../../core/_base/layout";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {delay, map, startWith} from "rxjs/operators";
import {Update} from "@ngrx/entity";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {EkConfiguration2AddComponent} from "../config/configuration2-add/configuration2-add.component";
import {ConfigurationModel} from "../../../../../../core/ek-e-commerce/ek-models/configuration.model";
import {EkConfiguration2TabComponent} from "../config/configuration2-tab/configuration2-tab.component";
import {DatePipe} from "@angular/common";
import {ClipboardService} from "ngx-clipboard";
import {EkBrandsEditComponent} from "../brands-edit/brands-edit.component";
import {NgxPermissionsService} from "ngx-permissions";
import {CategoriesService} from "../../../../../../core/ek-e-commerce/ek-services/categories.service";
import {SubCategoriesActive} from "../../../../../../core/ek-e-commerce/ek-actions/category.action";
import {ProductModel} from "../../../../../../core/ek-e-commerce/ek-models/product.model";
import {EkProductsService} from "../../../../../../core/ek-e-commerce/ek-services/ek-product.service";

@Component({
  selector: 'kt-products2-add',
  templateUrl: './products2-add.component.html',
  styleUrls: ['./products2-add.component.scss']
})
export class EkProducts2AddComponent implements OnInit {
    // Public properties
    isReadonly = false;
    product: ProductModel;
    productId$: Observable<number>;
    oldProduct: ProductModel;
    selectedTab = 0;
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    productForm: FormGroup;
    hasFormErrors = false;
    headerSentence:string ='';
    statusProduct:boolean;
    productId : number = 0;
    name: string;
    productName: string;
    private componentSubscriptions: Subscription[] = [];
    private headerMargin: number;
    private updater: string;
    configurations: ConfigurationModel[] = [];

    categories: CategoryModel[];
    availableBrands: BrandModel[];
    filteredBrands: Observable<BrandModel[]>;
    filteredCategory: Observable<CategoryModel[]>;
    chategoryFind:CategoryModel;

    /** chips **/
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new FormControl();
    filteredTags: Observable<string[]>;
    tags: string[] = ['Info'];
    allTags: string[] = ['Pc desctop', 'Info'];
   /**  **/
    @ViewChild('fruitInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
   // @Input() newID :number;
    @Input() newID :number;
    @ViewChild(EkConfiguration2TabComponent) child: EkConfiguration2TabComponent;
    updaterProduct:string;
    currentRole: string;
    canEdit = false;
    @Input() id: number;
    /**
     * Component constructor
     *
     * @param store
     * @param activatedRoute
     * @param router
     * @param typesUtilsService
     * @param productFB
     * @param dialog
     * @param subheaderService
     * @param layoutUtilsService
     * @param layoutConfigService
     * @param productService
     * @param cdr
     * @param datepipe
     * @param clipboardApi
     * @param ngxPermissionService
     * @param categoryService
     */
    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private typesUtilsService: TypesUtilsService,
        private productFB: FormBuilder,
        public dialog: MatDialog,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private layoutConfigService: LayoutConfigService,
        private productService: EkProductsService,
        private cdr: ChangeDetectorRef,
        public datepipe: DatePipe,
        private clipboardApi: ClipboardService,
        private ngxPermissionService: NgxPermissionsService,
        private  categoryService: CategoriesService

    ){
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) => fruit ? this.filter_(fruit) : this.allTags.slice()));
    }

    /**
     * On init
     */
    ngOnInit() {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
        this.loadCategories();
        this.loadBrands();
        this.loading$ = this.loadingSubject.asObservable();

        this.loadingSubject.next(true);

        this.activatedRoute.params.subscribe(params => {

            const productname = params.productname;
            const id = params.id;
            this.productId = id;
            this.productName = id;
           // this.name = name;
            if (id && id > 0) {
                this.isReadonly= true;
                this.callStoreProduct();

            } else {
                this.isReadonly= false;
                //this.generateReference();
                this.updaterProduct = this.updater;

                const newProduct = new ProductModel();
                newProduct.clear();
                this.loadProduct(newProduct);
                this.OpenAddConfiguration();

            }
        });
        this.getActiveTab();

        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };
    }

    private loadCategories() {
        this.categories = [];
        const queryParams = new QueryParamsModel(
            '',
            "desc",
            'id',
            0,
            200
        );
        this.store.dispatch(SubCategoriesActive({page:queryParams}));
        const categories$ = this.store.select(selectCategoriesInStore).subscribe(
            results => {
                this.categories = results.items;
            }
        );
    }
    filteredCategoryExecuted(){
        if (this.currentRole =='ROLE_GUEST_ADMIN'){
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        this.filteredCategory = this.productForm.get('category').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filterCategory(name.toString()) : this.categories.slice())
            );
    }
    getCategorySelected(event) {
        this.productForm.get('category').setValue('')

        if(this.productForm.controls.category.dirty){
            this.productForm.controls.category.enable();
            //this.isEditable = false;
            this.categories.find(value => {
                if (value.id == event.option.value){
                    this.chategoryFind = value;

                    const controls = this.productForm.controls;
                    controls.category.setValue(this.chategoryFind.label);
                }
            });
        }




    }
    private _filterCategory(name: any): CategoryModel[] {

        const filterValue = name.toLowerCase();

        return this.categories.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
    }
    private loadBrands() {
        this.store.dispatch(BrandActions.BrandsPageRequested({size: 500, page: 0}));
        const brands$ = this.store.select(selectBrandsInStore).subscribe(
            result => {
                this.availableBrands = result.items;
            }
        );
        this.componentSubscriptions.push(brands$)
    }

    loadProduct(_product, fromService: boolean = false) {
        if (!_product) {
            this.goBack('');
        }
        this.product = _product;

        this.statusProduct = this.product.activate;
        this.productId$ = of(_product.id);
        this.oldProduct = Object.assign({}, _product);
        this.initProduct();
        if (fromService) {
            this.cdr.detectChanges();
        }

        if(this.productId&&this.productId>0){

            this.updaterProduct = this.product.updater;
        }
    }

    // If product didn't find in store
    loadProductFromService(productId) {
        this.productService.getById(productId).subscribe(res => {
            this.loadProduct(res, true);
        });
    }

    getActiveTab() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.tab) {
                this.selectedTab = params.tab;
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy() {

        this.componentSubscriptions.forEach(sub => sub.unsubscribe())

    }

    /**
     * Init product
     */
    initProduct() {
        this.createForm();
        this.loadingSubject.next(false);

        if (!this.product.id) {
            this.subheaderService.setBreadcrumbs([
                {title: 'eCommerce', page: `/ecommerce`},
                {title: 'Products', page: `/ecommerce/products`},
                {title: 'Création d\'un produit', page: `/ecommerce/products2/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit product');
        this.subheaderService.setBreadcrumbs([
            {title: 'eCommerce', page: `/ecommerce`},
            {title: 'Products', page: `/ecommerce/products`},
            {title: 'Modification d\'un produit', page: `/ecommerce/products2/edit`, queryParams: {id: this.product.id}}
        ]);
    }

    /**
     * Create form
     */
    createForm() {
        this.productForm = this.productFB.group({
            name: [this.product.name, Validators.required],
            category: [this.getCategoryName(this.product.categoryId),this.forbiddenCategoryValidator()],
            brand: [this.product.brand.id, Validators.required],
            refConstructor: [this.product.refeConstructor, Validators.required],
            manufactureName: [this.product.manufactureName ? this.product.manufactureName : '', Validators.required],
            reference: [this.product.referenc],
            codeEAN: [this.product.codEAN],
            description: [this.product.description],
        });


    }


    /**
     * Go back to the list
     *
     * @param id: any
     */
    goBack(id) {
        this.loadingSubject.next(false);
        const url = `/ecommerce/products?id=${id}`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    goBackWithoutId() {
        this.router.navigateByUrl('/ecommerce/products', {relativeTo: this.activatedRoute});
    }

    /**
     * Refresh product
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshProduct(isNew: boolean = false, id = 0) {
        this.loadingSubject.next(false);
        let url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }

        url = `/ecommerce/products2/edit/${id}`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    /**
     * Reset
     */
    reset() {
        this.product = Object.assign({}, this.oldProduct);
        this.createForm();
        this.hasFormErrors = false;
        this.productForm.markAsPristine();
        this.productForm.markAsUntouched();
        this.productForm.updateValueAndValidity();
    }

    /**
     * Save data
     *
     * @param withBack: boolean
     */
    onSumbit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.productForm.controls;
        /** check form */
        if (this.productForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }

        // tslint:disable-next-line:prefer-const
        let editedProduct = this.prepareProduct();
        editedProduct.updater=this.updater;


        if (editedProduct.id > 0) {
            this.updateProduct(editedProduct, withBack);
            return;
        }
        this.addProductValide(editedProduct, withBack);

    }

    /**
     * Returns object for saving
     */
    prepareProduct(): ProductModel {

        const controls = this.productForm.controls;
        const _product = new ProductModel();
        _product.id = this.product.id;
        _product.name = controls.name.value;
        _product.categoryId = this.getCategoryId(controls.category.value);
        _product.referenc = null;
        _product.codEAN = null;
        _product.manufactureName = controls.manufactureName.value;
        const brand = new BrandModel();
        brand.name = this.getBrandId(controls.brand.value);
        brand.id = controls.brand.value;
        _product.brand = brand;

        _product.description = controls.description.value;
        _product.refeConstructor = controls.refConstructor.value;
        return _product;
    }

    /**
     * Test if productName is valide
     *

     */
    addProductValide(_product: ProductModel, withBack: boolean = false){
        _product.configurations= this.child.configurations;

        if(_product.configurations.length > 0 && _product.configurations){

        this.productService.nameExists(_product.name).subscribe(
            (e) => {
                if (!e) {
                    this.addProduct(_product,withBack);
                } else {
                    this.headerSentence = 'Nom déjà existant !';
                }
            }
        );}
        else{
                const message = `le prroduit doit avoir une configuration au minimum.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
        }
    }
    /**
     * Add product
     *
     * @param _product: ProductModel
     * @param withBack: boolean
     */
    addProduct(_product: ProductModel, withBack: boolean = false) {
        _product.activate = false;
        this.loadingSubject.next(true);
        _product.updater= this.updater;
        this.store.dispatch(ProductActions.ProductCreated({product: _product}));
        const storesub$ = this.store.pipe(
            // delay(1000),
            select(selectLastCreatedProductId)
        ).subscribe(newId => {
            /**
             *the product not create !
             */
            if (!newId) {

                this.updaterProduct = this.updater;

                const newProduct = new ProductModel();
                newProduct.clear();
                this.child.dataSource.data = [];
                this.loadProduct(newProduct);
                this.loadingSubject.next(false);
                this.router.navigateByUrl(`/ek-ecommerce/products2/add`);



                return;
            }
            this.loadingSubject.next(false);
            if (withBack) {
                this.goBack(newId);
            } else {
                this.newID = newId;
                this.child.updaterProduct = _product.updater;
                this.router.navigateByUrl(`/ek-ecommerce/products2/edit/${newId}`);

                //this.child.addTest(newId);

            }
        });

        this.componentSubscriptions.push(storesub$);
        // }
        // else{
        //     const message = `le prroduit doit avoir une configuration au minimum.`;
        //     this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
        // }
    }

    /**
     * Update product
     *
     * @param _product: ProductModel
     * @param withBack: boolean
     */
    updateProduct(_product: ProductModel, withBack: boolean = false) {
        this.loadingSubject.next(true);
        _product.activate = this.statusProduct;
        _product.updater =this.updater;

        const updateProduct: Update<ProductModel> = {
            id: _product.id,
            changes: _product
        };

        this.store.dispatch(ProductActions.ProductUpdated({
            partialProduct: updateProduct,
            product: _product
        }));

        of(undefined).pipe(delay(3000)).subscribe(() => { // Remove this line
            if (withBack) {
                this.goBack(_product.id);
            } else {
                const message = `Product successfully has been saved.`;
                this.child.updaterProduct = _product.updater;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
                this.refreshProduct(false);
            }
        }); // Remove this line
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        let result = 'Création d\'un produit';
        if (!this.product || !this.product.id) {
            return result;
        }

        result = `Modification d'un produit- ${this.product.name}`;
        return result;
    }

    /**
     * Close alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    //autocomplete for brands
    private _filter(value: string): BrandModel[] {
        const filterValue = value.toLowerCase();
        const res = this.availableBrands.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        return res;
    }

    getBrandId(brandId: number): string {
        const brand = this.availableBrands.filter(res => res.id == brandId);
        if (brand[0])
            return brand[0].name;
    }
    getCategoryId(categoryName: string): number {
        const category = this.categories.filter(res => res.label == categoryName);
        if (category[0])
            return category[0].id;
    }
    getCategoryName(categoryID: number): string {
        const category = this.categories.filter(res => res.id == categoryID);
        if (category[0])
            return category[0].label;
    }

    editBrand(brands: BrandModel[]) {
        const dialogRef = this.dialog.open(EkBrandsEditComponent, {
            maxHeight: '90vh',
            data: {
                brands: this.availableBrands
            }

        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.loadBrands();
        });
    }
    /**/

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.tagCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.tags.push(event.option.viewValue);
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
    }

    private filter_(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allTags.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
    }
    generateReference() {
        if (!this.isReadonly){
            this.productService.generateRefrence().subscribe(res=>
            {
                this.clipboardApi.copyFromContent(res["body"]);
                this.productForm.controls['refConstructor'].setValue(res['body']);
            });
        }
    }
    OpenAddConfiguration(){
        const _configuration2 = new ConfigurationModel();
        _configuration2.productId = this.productId;
        const dialogRef = this.dialog.open(EkConfiguration2AddComponent, {
            data: _configuration2

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if(this.productId&&this.productId>0){
                    this.callStoreProduct();

                }else{
                this.configurations.push(result.data);
                this.child.configurations = this.configurations;
                this.child.dataSource.data = this.configurations;
                this.productForm.controls['name'].setValue(this.configurations[0].name);}
            }
        });
    }
    OnChangeActivateProduct( value: boolean ){
        const _product = new ProductModel();
        _product.id = this.productId;
        _product.activate = value;
        _product.updater = this.updater;
        var today= new Date();
        _product.updatedAt= this.datepipe.transform(today, 'yyyy-MM-dd');
        const updateProduct: Update<ProductModel> = {
            id: this.productId,
            changes: _product
        };


        this.store.dispatch(ProductActions.ProductUpdatedActivate({
            product: _product,
            partialProduct: updateProduct,
            activate: value,
            updater: this.updater
        }));



    }

   callStoreProduct(){
       this.store.pipe(
           select(selectProductById(this.productId))
       ).subscribe(result => {
           if (result == undefined) {
               this.loadProductFromService(this.productId);
               return;
           }

           this.loadProduct(result);
       });
   }
    public reloadStatusProduct() {

        this.callStoreProduct();

    }
    public reloadNameProduct(){
        if(this.productId>0&&this.productId){
            this.callStoreProduct();
        }else{
        this.productForm.controls['name'].setValue(this.configurations[0].name);}

}

    forbiddenCategoryValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // below findIndex will check if control.value is equal to one of our options or not

            const index = this.categories.findIndex(category => category.label === control.value);
            return index < 0 ? { 'forbiddenCategory': { value: control.value } } : null;
        }};
}

