import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'kt-feedback-cremba',
    templateUrl: './feedback-cremba.component.html',
    styleUrls: ['./feedback-cremba.component.scss']
})
export class FeedbackCrembaComponent implements OnInit {

    currentRole: string = '';
    feedbackForm = new FormControl('');

    @Input() selectionCount = 0;
    @Input() selectedState = '';
    @Input() spinnerLoading = false;
    @Output() closePopUp = new EventEmitter<boolean>();
    @Output() sendBulkFeedBackEmitter: EventEmitter<string> = new EventEmitter<string>();

    feedbackOptions = [
        {name: "Non financable", value: 'NON_FINANCEABLE_CLIENT'},
        {name: "Financable", value: 'FINANCEABLE_CLIENT'},
        {name: "Demande complément d’information", value: 'COMPLEMENT_REQUEST'},
        {name: "Rejetée", value: 'REJECTION'}
    ]

    constructor() {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
    }

    close() {
        this.closePopUp.emit(true);
    }

    sendBulkFeedBack()
    {
        this.sendBulkFeedBackEmitter.emit(this.feedbackForm.value);
    }
}
