import { SectionType } from './../ek-e-commerce/ek-models/section-type.model';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
// import { AppConfig, APP_CONFIG } from './config';
// @ts-ignore
import {BehaviorSubject, Observable} from "rxjs";
import {ADMIN_CONFIG} from "../../../environments/environment";
import {ocrInfo} from "../ek-e-commerce/ek-models/document-ocr-information";
import {DocumentModel} from "../ek-e-commerce/ek-models/document.model";
import {Response} from "../ek-e-commerce/ek-models/response.model";
import {VerificationFullObject} from "../ek-e-commerce/ek-models/ocr-validations";

// import {ShippingAddressFolder} from "../Models/shipping-address-folder";

@Injectable({providedIn: "root"})
export class DocumentService {
    _profSituation!: string;
    _personnalSituation!: string;
    currentRole = '';


     docStateSbj$ = new BehaviorSubject<string>(null)
    public docStateObs$ = this.docStateSbj$.asObservable()

    set profSituation(value: string) {
        this._profSituation = value;
    }

    get profSituation(): string {
        return this._profSituation;
    }

    set personnalSituation(value: string) {
        this._personnalSituation = value;
    }

    get personnalSituation(): string {
        return this._personnalSituation;
    }

    basicUrl: string;
    documentUrl: string;
    documentClientUrl: string;
    folderUrl: string;

    private isFilesChanges$ = new BehaviorSubject<boolean>(false);
    public filesChange$ = this.isFilesChanges$.asObservable();

    // Subjects
    public ocrSubject: BehaviorSubject<ocrInfo> = new BehaviorSubject<ocrInfo>(
        null as any
    );


    isBankDocCheckedSubject = new BehaviorSubject<boolean>(false);

    allOneByOneIsCheckedSubject = new BehaviorSubject<boolean>(false);


    currentDocSbj$ = new BehaviorSubject<DocumentModel>(null);
    currentDocumentObs$ = this.currentDocSbj$.asObservable()

    constructor(private http: HttpClient) {
        this.basicUrl = `${ADMIN_CONFIG.apiEndpoint}`;
        this.documentUrl = `${ADMIN_CONFIG.apiEndpoint}document`;
        this.documentClientUrl = `${ADMIN_CONFIG.apiEndpoint}documentClient`;
        this.folderUrl = `${ADMIN_CONFIG.apiEndpoint}shippingAddressFolder`;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    public uploadDocument(file: File, document: DocumentModel) {
        const url = `${this.documentUrl}/new/save`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        this.isFilesChanges$.next(true);
        return this.http.post<Response<any>>(url, formData);
    }

    public uploadProfileDocument(file: File, document: DocumentModel) {
        const url = `${this.documentClientUrl}`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        return this.http.post<Response<any>>(url, formData);
    }

    public updateDocument(file: File, document: DocumentModel) {
        const url = `${this.documentUrl}/new`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        this.isFilesChanges$.next(true);
        return this.http.put<Response<any>>(url, formData);
    }

    public updateProfileDocument(file: File, document: DocumentModel) {
        const url = `${this.documentClientUrl}`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        return this.http.put<Response<any>>(url, formData);
    }

    public getPercentageByOrderId(id: number) {
        const url = `${this.documentUrl}/pourcentage/${id}`;
        return this.http.get<Response<any>>(url);
    }

    public getDocumentsByFolderId(id: number) {
        const url = `${this.documentUrl}/folder/${id}`;
        return this.http.get<Response<any>>(url);
    }

    public getDocumentsByClientId(id: number) {
        const url = `${this.documentClientUrl}/client/${id}`;
        return this.http.get<Response<any>>(url);
    }


    public verifyOcr(folderId: number, feedBack: boolean) {
        const url = `${this.documentUrl}/verifyOcr/${folderId}`;
        const params = new HttpParams().set('aBoolean', String(feedBack));
        return this.http.get<Response<any>>(url, {params});
    }

    public downloadDocuments(documentType: string , orderId:number ) {

        const url = `${this.documentUrl}/bank/${orderId}`;
        const params = new HttpParams().set('type', documentType);
        return this.http.get<Response<any>>(url,{params});
    }
    //   public sendfileShippingType(folderShipping : ShippingAddressFolder) : Observable<any>{
    //     const url = `${this.folderUrl}`
    //     return this.http.post(url, folderShipping);
    //   }
    //   public updatefileShippingType(folderId : number, folderShipping : ShippingAddressFolder) : Observable<any>{
    //     const url = `${this.folderUrl}/updateByFolderId/${folderId}`
    //     return this.http.put(url, folderShipping);
    //   }
    public checkDocuments(folderId: number) {
        const url = `${this.basicUrl}OCR/allDocument/${folderId}`;
        return this.http.get<Response<any>>(url);
    }

    public getShippingAddressFolder(folderId: number) {
        const url = `${this.folderUrl}/folder/${folderId}`;
        return this.http.get<Response<any>>(url);
    }

    public checkBankDocuments(folderId: number) {
        const params = new HttpParams().set("type", "BANK");
        const url = `${this.basicUrl}document/check/${folderId}`;
        return this.http.get<Response<any>>(url, {params});
    }

    public checkGuaranteesDocuments(folderId: number) {
        const params = new HttpParams().set("type", "GUARANTEES");
        const url = `${this.basicUrl}document/check/${folderId}`;
        return this.http.get<Response<any>>(url, {params});
    }

    public checkInvoiceDocuments(folderId: number) {
        const params = new HttpParams().set("type", "INVOICE");
        const url = `${this.basicUrl}document/check/${folderId}`;
        return this.http.get<Response<any>>(url, {params});
    }

    public getDocumentById(id: number): Observable<Response<DocumentModel>> {
        const url = `${this.documentUrl}/${id}`;
        return this.http.get<Response<DocumentModel>>(url);
    }

    public updateDocumentState(idDoc: number, state: string, openAt: string) {
        const url = `${this.documentUrl}/state/${idDoc}?state=${state}&openAt=${openAt}`;
        return this.http.patch<Response<any>>(url, {});
    }

    public getValidationResponse(folderId: number) {
        const url = `${this.basicUrl}OCR/reponse/${folderId}`;
        return this.http.get<Response<VerificationFullObject>>(url);
    }

    public getClientInfo(folderId: number) {
        const url = `${ADMIN_CONFIG.apiEndpoint}clientInformation/findClientInfoByIdFolder/${folderId}`;
        return this.http.get<Response<ocrInfo | any>>(url);
    }

    public verifyDocuments(folderId: number) {
        const url = `${ADMIN_CONFIG.apiEndpoint}OCR/checkDocument/admin/${folderId}`;
        return this.http.get<Response<any>>(url);
    }

    public downloadFiles(idFolder: number, sectionType: SectionType) {
        if (this.currentRole !== 'ROLE_POS_EK') {
            const url = `${this.documentUrl}/pdf/getAllFiles/${sectionType}/${idFolder}`;
            return this.http.get<any>(url);
        }
    }

    public getNotifDate(idFolder: number): Observable<Response<string>> {
        const url = `${this.basicUrl}historyStateFolder/bank-notification-date/${idFolder}`;
        return this.http.get<Response<string>>(url)
    }
}
