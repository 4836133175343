import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../core/reducers";
import {
  SmsHistoryDetailsDatasource
} from "../../../../../core/ek-e-commerce/ek-data-sources/smsHistoryDetails.datasource";
import {SmsHistoryDetailsPageRequested} from "../../../../../core/ek-e-commerce/ek-actions/sms-history-details.actions";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'kt-sms-history-details',
  templateUrl: './sms-history-details.component.html',
  styleUrls: ['./sms-history-details.component.scss']
})
export class SmsHistoryDetailsComponent implements OnInit , OnDestroy{

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  displayedColumns: string[] = ['phone', 'create-date', 'responseApi' ];
  //sorting table
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private subscriptions: Subscription[] = [];
  dataSource:SmsHistoryDetailsDatasource;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { folderId: number },
      public dialogRef: MatDialogRef<SmsHistoryDetailsComponent>,
      public dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {

    //init dataSource
    this.dataSource = new SmsHistoryDetailsDatasource(this.store);

    this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
      this.getAllPaged();
    });

  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

  getAllPaged() {

    this.store.dispatch(SmsHistoryDetailsPageRequested({
      idFolder: this.data.folderId,
    }));
  }

}
