import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as DiscountActions from "../_actions/discount.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {DiscountsService} from "../_services/discounts.service";
import {Injectable} from "@angular/core";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";

@Injectable()
export class DiscountEffect {

    constructor(private actions$: Actions,
                private discountsService: DiscountsService,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,) {
    }

    discountCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscountActions.DiscountCreated),
            switchMap((action) =>
                this.discountsService.saveDiscount(action.discount)
                    .pipe(
                        map(data => this.isUserCanCreateDiscount(data)),
                        catchError(error => of(DiscountActions.DiscountCreationFailed({error})))
                    ))));


    isUserCanCreateDiscount(data) {
        switch (data.message) {
            case "can not create discount  ,there is a promocode active":
                this.openDialoge(data.body, "promoCodeCase");
                return DiscountActions.DiscountCreatedSuccessfully({response: data});
                break;
            case "can not create promo code ,there is a discount client active":
                this.openDialoge(data.body, "discountClientCase");
                return DiscountActions.DiscountCreatedSuccessfully({response: data});
                break;
            default:
                this.layoutUtilsService.showActionNotification("discount a été creé avec succes", MessageType.Update, 10000, true, true);
                return DiscountActions.DiscountCreatedSuccessfully({response: data});
                break;
        }
    }

    openDialoge(data, case_) {


        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '500px';

        if (case_ === 'discountClientCase') {
            dialogConfig.data = {
                list: data,
                case: 'discountClientCase',
                title: 'Liste des discounts client',
                errorMs: 'Création du discount Rejeté, une ou plusieurs remise client existe déjà durant cette période'
            };
        } else {
            dialogConfig.data = {
                list: data,
                case: 'promoCodeCase',
                title: 'Liste des promo Codes',
                errorMs: 'Création du discount Rejeté, un code promo existe déjà durant cette période'
            };
        }

        //this.dialog.open(ShowPopUpComponent, dialogConfig);
    }


}
