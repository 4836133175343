import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormsValidationService {

    constructor() {
    }

    alphabeticCharacters(control) {

        const pattern = /^[a-zA-Z ]+$/; // Regular expression to match alphabetic characters and spaces


        if (pattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {alphabeticCharacters: true}; // Invalid input
        }
    }

    alphabeticCharactersWithHyphens(control) {

        const pattern = /^[a-zA-Z -]+$/; // Regular expression to match alphabetic characters, spaces, and hyphens

        if (pattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {alphabeticCharactersWithHyphens: true}; // Invalid input
        }
    }

    numericCharacters(control) {
        const pattern = /^[0-9]+$/; // Regular expression to match numeric characters

        if (pattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {numericCharacters: true}; // Invalid input
        }
    }

    arabicAlphabetValidator(control) {

        const arabicAlphabetPattern = /^[\u0600-\u06FF\s]+$/; // Regular expression to match arabic characters

        if (arabicAlphabetPattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {invalidArabicAlphabet: true}; // Invalid input
        }
    }

    arabicAdrAlphabetValidator(control) {

        const arabicWithNumbersPattern = /^[\u0600-\u06FF0-9\s]+$/; // Regular expression to match Arabic characters and numbers

        if (arabicWithNumbersPattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {invalidArabicAdrAlphabet: true}; // Invalid input
        }
    }

}
