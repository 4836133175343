import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ADMIN_CONFIG} from '../../../../environments/environment';
import {Page} from '../../_base/crud/models/page';
import {Response} from '../../_base/crud/models/response';
import {AbstractService} from '../../services/abstract-service.service';
import {ClientModel} from '../ek-models/client.model';

@Injectable()
export class ClientsService extends AbstractService<ClientModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}clients`;
    }

    public getAllClients(pageNumber: number = 0, pageSize: number = 100, direction: string, sortBy : string, query: string = '', clientState: string, firstDate: string, lastDate: string): Observable<Response<Page<ClientModel>>> {

        const url = `${this.url}/withOrdersCount`;

        let params = new HttpParams();

        params = params.append('pageSize', pageSize.toString());
        params = params.append('direction', direction);
        params = params.append('sortBy', sortBy);
        params = params.append('criteria', query);
        params = params.append('pageNo', pageNumber.toString());
        params = firstDate ? params.append('date', firstDate) : params;
        params = lastDate ? params.append('dateTwo', lastDate) : params;
        params = clientState ? params.append('clientStatus', clientState) : params;

        const options = {params};

        return this.http.get<Response<Page<ClientModel>>>(url, options);

    }

    public getClientById(clientId: number): Observable<ClientModel> {
        const url = this.url + `/${clientId}`;
        return this.http.get<Response<ClientModel>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            }));
    }

    public getGuestMail(firstname: string, lastname: string): Observable<Response<string>>  {
        const options = {
            params: new HttpParams()
                .append('firstname', firstname)
                .append('lastname', lastname)
        };
        const url = `${this.url}/guestmail`;
        return this.http.get<Response<string>>(url, options);
    }


    public changeActiveCustomer(blocked: boolean, id: number, reasonForBlocking?: string) {
        const clientState = {
            blocked,
            id,
            reasonForBlocking
        };
        const url = `${this.url}/blockingClients/`;
        return this.http.patch(url, clientState);
    }

    public getAllFilterByStatus(status: string = '', direction: string = 'desc', pageNo: number = 0, pageSize: number = 100, sortBy: string): Observable<Page<ClientModel>> {

        const options = {
            params: new HttpParams()
                .append('clientStatus', status)
                .append('direction', direction)
                .append('pageNo', pageNo.toString())
                .append('pageSize', pageSize.toString())
                .append('sortBy', sortBy)
        };

        const url = `${this.url}/filterByStatus`;
        return this.http.get<Response<Page<ClientModel>>>(url, options).pipe(map(({body}) => {
            return body;
        }));
    }


    public getAllFilterByDate( startDate: string, endDate: string, page: number = 0, size: number = 100): Observable<Page<ClientModel>> {
        let params = new HttpParams();

        params = startDate ? params.append('date', startDate) : params;
        params = endDate ? params.append('dateTwo', endDate) : params;
        params = page ? params.append('pageNo', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;

        const options = { params };

        const url = `${this.url}/filterByDate/`;
        return this.http.get<Response<Page<ClientModel>>>(url, options).pipe(map(({body}) => body));
    }

    public sendMail(idClient: number = 0, message: string, file: File[], subject: string): Observable<Response<string>> {
        const formData = new FormData();

        for (const files of file) {
            formData.append('file', files, files.name);
        }
        formData.append('message' , message);
        formData.append('id' , idClient.toString());
        formData.append('object' , subject);

        const url = `${this.url}/sendMail`;
        return this.http.post<Response<any>>(url , formData);
    }

    public sendMails(idsClient: number [], message: string, file: File[] , subject: string): Observable<Response<string>> {

        console.log('idsClient', idsClient);
        const formData = new FormData();

        for (const files of file) {
            formData.append('file', files, files.name);
        }
        formData.append('message' , message);
        formData.append('ids' , idsClient.toString());
        formData.append('object' , subject);
        const url = `${this.url}/sendMails`;
        return this.http.post<Response<any>>(url, formData);
    }


    public ExportExcelCustomers(): Observable<Response<any>> {
        const url = `${this.url}/export/excel`;
        return this.http.get<Response<any>>(url);
    }
    public resetPasswordRequest(email: string) {

        const url = `${this.url}/reset-password-request`;

        return this.http.post<Response<any>>(url, email).pipe(

            map((response: Response<any>) => {
                if (response.code === 0) {
                    return response;
                }
                if (response.message === 'entity.doesnt.exist') {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }
    public sendEmailActivation(id: number): Observable<Response<any>> {
        const url = `${this.url}/sendActivationEmailByAdmin?id=${id}`;
        let params = new HttpParams();
        params = params.append('id', id.toString());
        return this.http.post<Response<any>>(url, {params});
    }
    public getClientsWithSameOrderedProduct(pageNumber: number = 0, pageSize: number = 100, direction: string, query: string = '', productId: number): Observable<Page<ClientModel>> {
        const url = `${this.url}/getClients-With-Same-Ordered-Product/${productId}`;
        let params = new HttpParams();

        params = params.append('pageSize', pageSize.toString());
        params = params.append('direction', direction);
        params = params.append('sortBy', 'id');
        params = params.append('criteria', query);
        params = params.append('pageNo', pageNumber.toString());

        const options = {params};
        return this.http.get<Page<ClientModel>>(url, options);
    }

    public saveCustomerByAdmin(entity: ClientModel): Observable<ClientModel> {
        const url = `${this.url}/saveByadmin`;
        return this.http.post<Response<ClientModel>>(url, entity).pipe(map(({body}) => body));
    }

}


