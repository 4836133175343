import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription, timer} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OtpService} from '../../../../../../core/ek-e-commerce/ek-services/otp.service';

@Component({
  selector: 'kt-phone-number-validation-popup',
  templateUrl: './phone-number-validation-popup.component.html',
  styleUrls: ['./phone-number-validation-popup.component.scss']
})
export class PhoneNumberValidationPopupComponent implements OnDestroy {

  validationCodeForm: FormGroup;
  countdown = 60;
  countdownSubscription!: Subscription;
  FakeOtp = false;

  @ViewChild('digit1') digit1!: ElementRef<HTMLInputElement>;
  @ViewChild('digit2') digit2!: ElementRef<HTMLInputElement>;
  @ViewChild('digit3') digit3!: ElementRef<HTMLInputElement>;
  @ViewChild('digit4') digit4!: ElementRef<HTMLInputElement>;
  @ViewChild('digit5') digit5!: ElementRef<HTMLInputElement>;
  @ViewChild('digit6') digit6!: ElementRef<HTMLInputElement>;

  constructor(
      private fb: FormBuilder,
      private otpService: OtpService,
      private dialogRef: MatDialogRef<PhoneNumberValidationPopupComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {

    this.validationCodeForm = this.fb.group({
      digit1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit6: ['', [Validators.required, Validators.pattern(/^\d$/)]],
    });

    // Start countdown timer
    this.startCountdown();

    // generate otp code
    this.generateOTP();

  }

  generateOTP() {
    this.otpService.generateValidationCode(this.data.phone).subscribe();
  }

  focusNext(nextElement: HTMLInputElement) {
    nextElement.focus();
  }

  startCountdown() {
    this.countdownSubscription = timer(0, 1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.countdownSubscription.unsubscribe();
      }
    });
  }
  save() {
    const stringValue = Object.values(this.validationCodeForm.value).join('');

    this.otpService.checkCode(this.data.phone, stringValue).subscribe(
        {
          next : () => {
            this.dialogRef.close(stringValue);
            this.FakeOtp = false;
          },
          error : () => {
            this.FakeOtp = true;
            // Start the countdown again
            this.countdown = 0;
            this.validationCodeForm.reset();
          }
        });
  }

  resendCode() {
    // reset countdown
    this.countdown = 60;
    // Start countdown timer again
    this.startCountdown();

    this.FakeOtp = false;
    this.validationCodeForm.reset();
    // re generate otp code
    this.generateOTP();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

}
