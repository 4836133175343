import {createAction, props} from "@ngrx/store";
import {QueryParamsModel} from "../../_base/crud";
import {ProductModel} from "../../ek-e-commerce/ek-models/product.model";
import {Update} from "@ngrx/entity";

export const EkProductsPageRequested = createAction('[ek Product List Page] ekProducts Page Requested ',props<{page: QueryParamsModel}>());
export const EkProductsPageLoadedSuccessfully = createAction('[Product API] ekProducts Page Loaded Successfully ',props<{products: ProductModel[],totalCount: number, page: number}>());
export const EkProductsPageLoadFailed = createAction('[Product API] ekProducts Page Request Failed ',props<{error: any}>());
export const ProductCreated = createAction('[Product List Page] Product Created ',props<{product: ProductModel}>());
export const ProductCreatedSuccessfully = createAction('[Product List Page] Product Created Successfully ',props<{product: ProductModel}>());
export const ProductCreationFailed = createAction('[Product List Page] Product Creation Failed ',props<{error: any}>());

export const ProductUpdated = createAction('[Product List Page] Product Updated ',props<{product: ProductModel, partialProduct: Update<ProductModel>}>());
export const ProductUpdatedSuccessfully = createAction('[Product List Page] Product Updated Successfully ',props<{product: ProductModel, partialProduct: Update<ProductModel>}>());
export const ProductUpdateFailed = createAction('[Product List Page] Product Update Failed ',props<{error: any}>());

export const ProductUpdatedActivate = createAction('[Product List Page] Product Updated activate',props<{product: ProductModel, partialProduct: Update<ProductModel>, activate:boolean, updater:string}>());
export const ProductUpdatedActivateSuccessfully = createAction('[Product List Page] Product Updated Successfully ',props<{product: ProductModel, partialProduct: Update<ProductModel>,activate:boolean, updater:string}>());
export const ProductUpdateActivateFailed = createAction('[Product List Page] Product Update Failed ',props<{error: any}>());


export const ProductDeleted = createAction('[Product List Page] Product Deleted ',props<{productId: number}>());
export const ProductDeletedSuccessfully = createAction('[Product List Page] Product deleted Successfully ',props<{productId: number}>());
export const ProductDeleteFailed = createAction('[Product List Page] Product delete Failed ',props<{error: any}>());
