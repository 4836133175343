import { ApplicationRef, ChangeDetectorRef, Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  LayoutUtilsService,
  MessageType,
} from "../../../../../../core/_base/crud";
import { BehaviorSubject } from "rxjs";
import { FOLDER_STATES } from "../../../Shared/Constants/folderStates";
import { FoldersService } from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import { MenuConfigService } from "../../../../../../core/_base/layout/services/menu-config.service";

@Component({
  selector: "kt-folder-state-change",
  templateUrl: "./folder-state-change.component.html",
  styleUrls: ["./folder-state-change.component.scss"],
})
export class FolderStateChangeComponent {
  loadingSubject$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject$.asObservable();
  folderStates = FOLDER_STATES;

  event = "";
  chosenFolderState = "";
  loading = false;
  currentRole: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { idFolder: number; stateFolder: string },
    private folderService: FoldersService,
    public dialogRef: MatDialogRef<FolderStateChangeComponent>,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private ref: ApplicationRef,
    private menuConfigService : MenuConfigService,
  ) {
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
  }

  isStateHidden(OrderState): boolean {

    switch (OrderState) {

      case "EK_FOLDER_CREATED": {
        if (this.data.stateFolder === "EK_FOLDER_IN_PROGRESS") {
          return true;
        }
        return false;
      }

      case "EK_FOLDER_IN_PROGRESS": {
        if (
          this.data.stateFolder === "EK_CLIENT_FOLDER_CANCELED" ||
          this.data.stateFolder === "EK_FOLDER_RESERVE" ||
          this.data.stateFolder === "EK_FOLDER_REFUSED" ||
          this.data.stateFolder === "CENTRAL_RISK_CONSULTATION"
        ) {
          return true;
        }
        return false;
      }

      case "BANK_NOTIFICATION": {
        if (this.data.stateFolder === "EK_CONTROL_BEFORE_GUARANTEES_PRINTING") {
          return true;
        }
        return false;
      }

      case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING" :{
        if (this.data.stateFolder === "EK_GUARTENTEES_PENDING_SIGNATURE") {
          return true;
        }
        return false;
      }

      case "BANK_PAYMENT_CONFIRMATION": {
        if (this.data.stateFolder === "FINANCIAL_FOLDER") {
          return true;
        }
        return false;
      }




      case "EK_BANK_IN_PROGRESS": {
        if (
          this.data.stateFolder === "BANK_NOTIFICATION" ||
          this.data.stateFolder === "EK_BANK_RESERVE" ||
          this.data.stateFolder === "EK_BANK_REFUSED"
        ) {
          return true;
        }
        return false;
      }

      case "CLIENT_CENTRAL_RISK_NOTIFICATION": {
        if (
          this.data.stateFolder === "EK_BANK_IN_PROGRESS"
        ) {
          return true;
        }
        return false;
      }


      case "CENTRAL_RISK_CONSULTATION": {
        if (
          this.data.stateFolder === "EK_BANK_REFUSED" ||
          this.data.stateFolder === "EK_BANK_RESERVE" ||
          this.data.stateFolder === "CLIENT_CENTRAL_RISK_NOTIFICATION"
        ) {
          return true;
        }
        return false;
      }

      case "EK_GUARTENTEES_PENDING_SIGNATURE": {
        if (
          this.data.stateFolder === "EK_GUARTENTEES_SIGNED_IN_PROGRESS" ||
          this.data.stateFolder === "EK_CLIENT_FOLDER_CANCELED"
        ) {
          return true;
        }
        return false;
      }





      //yalidine or not ????????
      case "EK_PHYSICAL_FOLDER_PENDING": {
        if (
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK" ||
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_REFUSED"
        ) {
          return true;
        }
        return false;
      }

      case "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK": {
        if (
          this.data.stateFolder === "EK_WAITING_FOR_CLIENT" ||
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_PICKEDUP"
        ) {
          return true;
        }
        return false;
      }

      case "EK_WAITING_FOR_CLIENT": {
        if (
          this.data.stateFolder === "EK_CLIENT_NO_RESPONSE" ||
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_PICKEDUP"
        ) {
          return true;
        }
        return false;
      }

      case "EK_CLIENT_NO_RESPONSE": {
        if (
          this.data.stateFolder === "EK_FOLDER_PICKUP_FAILED" ||
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_PICKEDUP"
        ) {
          return true;
        }
        return false;
      }

      // case "EK_PHYSICAL_FOLDER_PICKEDUP": {
      //   if (this.data.stateFolder === "EK_PHYSICAL_FOLDER_IN_DELIVERY") {
      //     return true;
      //   }
      //   return false;
      // }

      case "EK_PHYSICAL_FOLDER_IN_DELIVERY": {
        if (this.data.stateFolder === "EK_PHYSICAL_FOLDER_IN_PROGRESS") {
          return true;
        }
        return false;
      }

      case "EK_PHYSICAL_FOLDER_IN_PROGRESS": {
        if (
          this.data.stateFolder === "EK_PHYSICAL_FOLDER_REFUSED" ||
          this.data.stateFolder === "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY"
        ) {
          return true;
        }
        return false;
      }

      case "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY": {
        if (this.data.stateFolder === "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS") {
          return true;
        }
        return false;
      }

      case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS": {
        if (
          this.data.stateFolder === "EK_BANK_PHYSICAL_FOLDER_ACCEPTED"
        ) {
          return true;
        }
        return false;
      }

      // case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED': {
      //     if (this.data.stateFolder === 'BANK_PAYMENT_CONFIRMATION'
      //     ) {
      //         return true;
      //     }
      //     return false;
      // }

      case 'FINANCIAL_FOLDER': {
        if (this.data.stateFolder === 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
        ) {
          return true;
        }
        return false;
      }
      case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS': {
        if (this.data.stateFolder === 'BANK_PAYMENT_CONFIRMATION'
        ) {
          return true;
        }
        return false;
      }

      case 'EK_GUARTENTEES_SIGNED_IN_PROGRESS': {
        if (this.data.stateFolder === 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
        ) {
          return true;
        }
        return false;
      }

    }
  }

  changeState() {

    console.log('ici this.data.stateFolder', this.data.stateFolder );
    console.log('ici this.chosenFolderState', this.chosenFolderState);
    if (!this.chosenFolderState) return;

    this.loadingSubject$.next(true);
    this.loading = true;

    switch (this.data.stateFolder) {
      //EK_FOLDER_IN_PROGRESS
      case "EK_FOLDER_IN_PROGRESS":
        switch (this.chosenFolderState) {
          case "EK_FOLDER_CREATED":
            this.event = "EK_REVERSE_PROGRESS_TO_CREATED";
            break;

          case "EK_FOLDER_RESERVE":
            this.event = "EK_REVERSE_PROGRESS_TO_RESERVE";
            break;
        }
        break;



      //EK_CLIENT_FOLDER_CANCELED
      case "EK_CLIENT_FOLDER_CANCELED":
        switch (this.chosenFolderState) {
          case "EK_FOLDER_IN_PROGRESS":
            this.event = "EK_REVERSE_CANCELED_TO_PROGRESS";
            break;

          case "EK_BANK_IN_PROGRESS":
            this.event = "EK_REVERSE_CLIENT_FOLDER_TO_BANK_IN_PROGRESS";
            break;

          case "EK_GUARTENTEES_PENDING_SIGNATURE":
            this.event =
              "EK_REVERSE_CLIENT_FOLDER_CANCELED_TO_GUARTENTEES_PENDING_SIGNATURE";
            break;
        }
        break;

      //EK_FOLDER_REFUSED

      case "EK_FOLDER_REFUSED":
        switch (this.chosenFolderState) {
          case "EK_FOLDER_IN_PROGRESS":
            this.event = "EK_REVERSE_REFUSED_TO_PROGRESS";
            break;

          case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
            this.event =
              "EK_REVERSE_FOLDER_REFUSED_TO_GUARTENTEES_SIGNED_IN_PROGRESS";
            break;
        }
        break;

      //CENTRAL_RISK_CONSULTATION
      case "CENTRAL_RISK_CONSULTATION":
        switch (this.chosenFolderState) {
          case "EK_FOLDER_IN_PROGRESS":
            this.event = "CENTRAL_RISK_CONSULTATION_TO_EK_FOLDER_IN_PROGRESS";
            break;
          case "EK_BANK_REFUSED":
            this.event = "CENTRAL_RISK_CONSULTATION_TO_EK_BANK_REFUSED";
            break;
        }
        break;

      //BANK_NOTIFICATION
      case "BANK_NOTIFICATION":
        switch (this.chosenFolderState) {
          case "EK_BANK_IN_PROGRESS":
            this.event = "BANK_NOTIFICATION_TO_EK_BANK_IN_PROGRESS";
            break;
        }
        break;

      //FINANCIAL_FOLDER
      case "FINANCIAL_FOLDER":
        switch (this.chosenFolderState) {
          case "BANK_PAYMENT_CONFIRMATION":
            this.event = "FINANCIAL_FOLDER_TO_BANK_PAYMENT_CONFIRMATION";
            break;
        }
        break;

      //EK_CONTROL_BEFORE_GUARANTEES_PRINTING
      case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING":
        switch (this.chosenFolderState) {
          case "BANK_NOTIFICATION":
            this.event = "EK_CONTROL_BEFORE_GUARANTEES_PRINTING_TO_BANK_NOTIFICATION";
            break;
        }
        break;

      //BANK_PAYMENT_CONFIRMATION
      case "BANK_PAYMENT_CONFIRMATION":
        switch (this.chosenFolderState) {
          case "EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS":
            this.event = "BANK_PAYMENT_CONFIRMATION_TO_EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS";
            break;
        }
        break;



      //EK_FOLDER_RESERVE
      case "EK_FOLDER_RESERVE":
        switch (this.chosenFolderState) {
          case "EK_FOLDER_IN_PROGRESS":
            this.event = "EK_FOLDER_RESERVE_TO_EK_FOLDER_IN_PROGRESS";
            break;
        }
        break;

      //EK_BANK_IN_PROGRESS
      case "EK_BANK_IN_PROGRESS":
        switch (this.chosenFolderState) {
          case "CLIENT_CENTRAL_RISK_NOTIFICATION":
            this.event = "EK_BANK_IN_PROGRESS_TO_CLIENT_CENTRAL_RISK_NOTIFICATION";
            break;
        }
        break;

      //CLIENT_CENTRAL_RISK_NOTIFICATION
      case "CLIENT_CENTRAL_RISK_NOTIFICATION":
        switch (this.chosenFolderState) {
          case "CENTRAL_RISK_CONSULTATION":
            this.event = "CLIENT_CENTRAL_RISK_NOTIFICATION_TO_CENTRAL_RISK_CONSULTATION";
            break;
        }
        break;

      //EK_BANK_RESERVE
      case "EK_BANK_RESERVE":
        switch (this.chosenFolderState) {
          case "EK_BANK_IN_PROGRESS":
            this.event = "EK_REVERSE_BANK_RESERVE_TO_BANK_IN_PROGRESS";
            break;

          case "CENTRAL_RISK_CONSULTATION":
            this.event = "EK_BANK_RESERVE_TO_CENTRAL_RISK_CONSULTATION";
            break;
        }
        break;

      //EK_BANK_REFUSED
      case "EK_BANK_REFUSED":
        switch (this.chosenFolderState) {
          case "EK_BANK_IN_PROGRESS":
            this.event = "EK_REVERSE_BANK_REFUSED_TO_BANK_IN_PROGRESS";
            break;

          case "CENTRAL_RISK_CONSULTATION":
            this.event = "EK_BANK_REFUSED_TO_CENTRAL_RISK_CONSULTATION";
            break;
        }
        break;

      //EK_GUARTENTEES_SIGNED_IN_PROGRESS
      case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
        switch (this.chosenFolderState) {
          case "EK_GUARTENTEES_PENDING_SIGNATURE":
            this.event =
              "EK_REVERSE_EK_GUARTENTEES_SIGNED_IN_PROGRESS_TO_GUARTENTEES_PENDING_SIGNATURE";
            break;
        }
        break;

      //EK_GUARTENTEES_PENDING_SIGNATURE
      case "EK_GUARTENTEES_PENDING_SIGNATURE":
        switch (this.chosenFolderState) {
          case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING":
            this.event =
              "EK_GUARTENTEES_PENDING_SIGNATURE_TO_EK_CONTROL_BEFORE_GUARANTEES_PRINTING";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_PENDING
      case "EK_PHYSICAL_FOLDER_PENDING":
        switch (this.chosenFolderState) {
          case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_PENDING_TO_GUARTENTEES_SIGNED_IN_PROGRESS";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_IN_PROGRESS
      case "EK_PHYSICAL_FOLDER_IN_PROGRESS":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_PENDING":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_IN_PROGRESS_TO_PHYSICAL_FOLDER_PENDING";
            break;

          case "EK_PHYSICAL_FOLDER_DELIVERED":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_PHYSICAL_FOLDER_DELIVERED";
            break;

           case "EK_PHYSICAL_FOLDER_IN_DELIVERY":
             this.event =
              "EK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_PHYSICAL_FOLDER_IN_DELIVERY";
             break;
        }
        break;

      //EK_PHYSICAL_FOLDER_WAITING_FOR_PICK
      case "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_PENDING":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_WAITING_FOR_PICK_TO_PHYSICAL_FOLDER_PENDING";
            break;
        }
        break;

      //EK_WAITING_FOR_CLIENT
      case "EK_WAITING_FOR_CLIENT":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK":
            this.event =
              "EK_REVERSE_WAITING_FOR_CLIENT_TO_PHYSICAL_FOLDER_WAITING_FOR_PICK";
            break;
        }
        break;

      //EK_CLIENT_NO_RESPONSE
      case "EK_CLIENT_NO_RESPONSE":
        switch (this.chosenFolderState) {
          case "EK_WAITING_FOR_CLIENT":
            this.event = "EK_REVERSE_CLIENT_NO_RESPONSE_TO_WAITING_FOR_CLIENT";
            break;
        }
        break;

      //EK_FOLDER_PICKUP_FAILED
      case "EK_FOLDER_PICKUP_FAILED":
        switch (this.chosenFolderState) {
          case "EK_CLIENT_NO_RESPONSE":
            this.event =
              "EK_REVERSE_FOLDER_PICKUP_FAILED_TO_CLIENT_NO_RESPONSE";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_PICKEDUP
      case "EK_PHYSICAL_FOLDER_PICKEDUP":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_PICKEDUP_TO_PHYSICAL_FOLDER_WAITING_FOR_PICK";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_IN_DELIVERY
      case "EK_PHYSICAL_FOLDER_IN_DELIVERY":
        switch (this.chosenFolderState) {
          case "FINANCIAL_FOLDER":
            this.event =
              "EK_PHYSICAL_FOLDER_IN_DELIVERY_TO_FINANCIAL_FOLDER";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_DELIVERED
      case "EK_PHYSICAL_FOLDER_DELIVERED":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_IN_DELIVERY":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_DELIVERED_TO_PHYSICAL_FOLDER_IN_DELIVERY";
            break;
        }
        break;

      //EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY
      case "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY":
        switch (this.chosenFolderState) {
          case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
            this.event =
              "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY";
            break;

          case "EK_PHYSICAL_FOLDER_IN_PROGRESS":
              this.event =
                  "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY_TO_EK_PHYSICAL_FOLDER_IN_PROGRESS";
              break;

          // case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
          //   this.event =
          //       "EK_REVERSE_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS";
          //   break;
        }
        break;

      // //GUARTENTEES_SIGNED_IN_PROGRESS_BANK
      // case "GUARTENTEES_SIGNED_IN_PROGRESS_BANK":
      //   switch (this.chosenFolderState) {
      //     case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
      //       this.event =
      //         "GUARTENTEES_SIGNED_IN_PROGRESS_BANK_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS";
      //       break;
      //   }
      //   break;

      //EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS
      case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
        switch (this.chosenFolderState) {
          case "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY":
            this.event =
              "EK_REVERSE_BANK_PHYSICAL_FOLDER_IN_PROGRESS_TO_EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY";
            break;
        }
        break;

      //EK_BANK_PHYSICAL_FOLDER_RESERVE
      case "EK_BANK_PHYSICAL_FOLDER_RESERVE":
        switch (this.chosenFolderState) {
          case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
            this.event =
              "EK_BANK_PHYSICAL_FOLDER_RESERVE_TO_EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS";
            break;
        }
        break;

      //EK_BANK_PHYSICAL_FOLDER_ACCEPTED
      case "EK_BANK_PHYSICAL_FOLDER_ACCEPTED":
        switch (this.chosenFolderState) {
          case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
            this.event =
              "EK_BANK_PHYSICAL_FOLDER_ACCEPTED_TO_EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS";
            break;
        }
        break;

      //EK_BANK_PHYSICAL_FOLDER_REFUSED
      case "EK_BANK_PHYSICAL_FOLDER_REFUSED":
        switch (this.chosenFolderState) {
          case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
            this.event =
              "EK_BANK_PHYSICAL_FOLDER_REFUSED_TO_EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS";
            break;
        }
        break;

      //EK_PHYSICAL_FOLDER_REFUSED
      case "EK_PHYSICAL_FOLDER_REFUSED":
        switch (this.chosenFolderState) {
          case "EK_PHYSICAL_FOLDER_PENDING":
            this.event =
              "EK_REVERSE_PHYSICAL_FOLDER_REFUSED_TO_PHYSICAL_FOLDER_PENDING";
            break;

          case "EK_PHYSICAL_FOLDER_IN_PROGRESS":
            this.event =
              "EK_REVERSE_EK_PHYSICAL_FOLDER_REFUSED_TO_PHYSICAL_FOLDER_IN_PROGRESS";
            break;
        }

      case "EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS" :
        switch (this.chosenFolderState) {
          case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
            this.event =
                "EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS";
            break;
        }
        break;
    }

    this.folderService.changeState(this.data.idFolder, this.event).subscribe({
      next: (res) => {
        if (res) {

          //reload menu configs
          this.getFoldersStatisticsForMenuConfigs();

          const message = `le statut de dossier a été bien modifié!`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            false,
            false
          );
          this.loadingSubject$.next(false);
          this.loading = false;
          this.dialogRef.close(true);
        }
      },
      error: () => {
        const message = `erreur lors de la modification de statu du dossier !`;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Create,
          5000,
          false,
          false
        );
        this.loadingSubject$.next(false);
        this.loading = false;
        this.dialogRef.close(false);
      },
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  getFoldersStatisticsForMenuConfigs() {

    this.folderService.FoldersStatistics().subscribe({
      next: (res) => {
        this.menuConfigService.statisticsSubject$.next(null as any);
        this.menuConfigService.statisticsSubject$.next(res.countFolderState);
        this.cdr.detectChanges();
        this.ref.tick();
      }
    });

    if (this.currentRole === 'ROLE_ADMIN'
        || this.currentRole === 'ROLE_SUPERADMIN'
        || this.currentRole === 'ROLE_BANKER_EK'
    ) {
      this.folderService.GlobalFolderStatistics().subscribe({
        next: (res) => {
          this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
          this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
          this.cdr.detectChanges();
          this.ref.tick();
        }
      });
    }


  }
}
