import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {EkWilayaService, Wilaya} from "../../../../../../core/ek-e-commerce/ek-services/ek-wilaya.service";
import {fromEvent, merge, Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ClipboardService} from "ngx-clipboard";
import {ActivatedRoute, Router} from "@angular/router";
import {LayoutUtilsService, QueryParamsModel} from "../../../../../../core/_base/crud";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {clientsPageRequested} from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {ClientsDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/clients.datasource";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'kt-clients-list-pop-up',
    templateUrl: './clients-list-pop-up.component.html',
    styleUrls: ['./clients-list-pop-up.component.scss']
})
export class ClientsListPopUpComponent implements OnInit {

    viewLoading$ = null;
    public OrdersTitle: String;
    loadingUpdatingState = false;
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;
    filterByDateActivated = false;
    showDatePicker = true;
    loadingData = false;
    //mat table
    dataSource: ClientsDatasource;
    selection = new SelectionModel<ClientModel>(true, []);
    clientsResult: ClientModel[] = [];
    displayedColumns: string[] = ['select', 'ID', 'Nom' , 'Prenom', 'creationDate', 'Source', 'ClientStatus', 'actions'];

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    wilayas: Wilaya[];
    isclientOrdresPage: boolean = false;
    private subscriptions: Subscription[] = [];

    fileShippingList = [
        {name: 'Toutes', value: ''},
        {name: 'Point de Vente', value: 'POINT_OF_SALE'},
        {name: 'Yalidine', value: 'RELAY_POINT'},
        {name: 'A Domicile', value: 'RESIDENCE'}
    ]
    selectedState = new FormControl();
    currentRole: string = '';

    error_message: string = '';

    constructor(public formatter: NgbDateParserFormatter,
                private calendar: NgbCalendar,
                private store: Store<AppState>,
                private clipboardApi: ClipboardService,
                private activatedRoute: ActivatedRoute,
                private wilayaService: EkWilayaService,
                private layoutUtilsService: LayoutUtilsService,
                private router: Router,
                public dialogRef: MatDialogRef<ClientsListPopUpComponent>
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.OrdersTitle = 'Choisissez un client';

        this.getAllPaged();

        this.paginator._changePageSize(100);

        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        this.dataSource = new ClientsDatasource(this.store);
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                this.getAllPaged();
            })).subscribe();
        this.subscriptions.push(searchSubscription);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.getAllPaged();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        this.fieldListener();

    }


    fieldListener() {
    }

    filterConfiguration(): string {
        return this.searchInput.nativeElement.value;
    }

    getAllPaged() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        this.store.dispatch(clientsPageRequested({
            page: queryParams,
            clientState: this.selectedState.value,
            firstDate: this.fromDate ? this.formatDate(this.fromDate) : null,
            lastDate: this.toDate ? this.formatDate(this.toDate) : null,
        }));
    }

    formatDate(date: NgbDate) {
        var month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.filterByDateActivated = true;
            this.showDatePicker = false;
            this.getAllPaged();
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    disableFilterByDate(event) {
        if (event.checked == false) {
            this.loadingData = false;
            this.filterByDateActivated = false;
            this.toDate = null;
            this.fromDate = null;
            this.getAllPaged();
        }
    }

    //Check all rows are selected
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.clientsResult.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.clientsResult.forEach(row => this.selection.select(row));
        }
    }

    addNewClient() {
        this.dialogRef.close();
        this.router.navigateByUrl('ek-ecommerce/customers/add' + '?newFolder=true');
        //this.router.navigate(['ecommerce/customers/add'], {relativeTo: this.activatedRoute});
    }

    close() {
        this.dialogRef.close();
    }

    navigateToClient(client) {
        this.dialogRef.close();
        // this.router.navigate(['ecommerce/customers/edit', client.id], {relativeTo: this.activatedRoute});
        this.router.navigateByUrl('ek-ecommerce/customers/edit/' + client.id + '?newFolder=true');

    }

    navigateTo(client: ClientModel) {

        if (client.clientStatus === 'WAITING') {
            this.error_message = `vous devez choisir un client actif pour continuer la sélection !`;
            return;
        }

        this.dialogRef.close();

        this.router.navigateByUrl('ek-ecommerce/folder/add/' + client.id + '?step=1');
    }

    getItemCssClassByBlocked(clientStatus): string {
        switch (clientStatus) {
            case 'BLOCKED':
                return 'danger ';
            case 'ACTIVE' :
                return 'succes';
            case 'WAITING':
                return 'metal';
        }
        return '';
    }

    getItemBlockedString(clientStatus): string {
        switch (clientStatus) {
            case 'BLOCKED':
                return 'Bloqué';
            case 'ACTIVE':
                return 'Activé';
            case 'WAITING':
                return 'En attente';
        }
        return '';
    }
}
