import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as CharacteristicActions from "../ek-actions/characteristic.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {CharacteristicsService} from "../ek-services/characteristics.service";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
@Injectable()
export class CharacteristicEffects {


    constructor(private actions$: Actions, private layoutUtilsService: LayoutUtilsService,private characteristicService: CharacteristicsService, private store: Store<AppState>) {
    }

    CharacteristicPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.CharacteristicsPageRequestedOfSearch),
            switchMap((action) =>
                this.characteristicService.search(action.page.pageNumber,action.page.pageSize,action.page.filter)
                    .pipe(
                        map(characteristicPage => CharacteristicActions.CharacteristicsPageLoadedSuccessfullyOfSearch(
                            {
                                characteristics: characteristicPage.content,
                                page: characteristicPage.totalElements,
                                totalCount: characteristicPage.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(CharacteristicActions.CharacteristicsPageLoadFailedOfSearch({error})))
                    ))));
    ConfigurationPageRequestedOfSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.CharacteristicsPageRequestedOfSearch),
            switchMap((action) =>
                this.characteristicService.search(action.page.pageNumber,action.page.pageSize,action.page.filter)
                    .pipe(
                        map(characteristicPage => CharacteristicActions.CharacteristicsPageLoadedSuccessfullyOfSearch(
                            {
                                characteristics: characteristicPage.content,
                                page: characteristicPage.totalElements,
                                totalCount: characteristicPage.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(CharacteristicActions.CharacteristicsPageLoadFailedOfSearch({error})))
                    ))));

    CharacteristicCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.CharacteristicCreated),
            switchMap((action) =>
                this.characteristicService.save(action.characteristic)
                    .pipe(
                        map(data => CharacteristicActions.CharacteristicCreatedSuccessfully({characteristic: data})),
                        catchError(error => of(CharacteristicActions.CharacteristicCreationFailed({error})))
                    ))));

    CharacteristicUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.CharacteristicUpdated),
            switchMap((action) =>
                this.characteristicService.update(action.characteristic)
                    .pipe(
                        map(characteristic => CharacteristicActions.CharacteristicUpdatedSuccessfully({
                            characteristic,
                            partialCharacteristic: action.partialCharacteristic
                        })),
                        catchError(error => of(CharacteristicActions.CharacteristicUpdateFailed({error})))
                    ))));

    CharacteristicDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.CharacteristicDeleted),
            switchMap((action) =>
                this.characteristicService.deleteCharacteristic(action.characteristicId)
                    .pipe(
                        map(res => this.message(res.message,action.characteristicId)),

                        catchError(error => of(CharacteristicActions.CharacteristicDeleteFailed({error})))
                    ))));

    message(message,characteristicId) {
    if(message=="data.integrity.violation"){
        this.layoutUtilsService.showActionNotification("Cette caractéristique existe sur un ou plusieurs produits!", MessageType.Update,10000,true,true);
        return CharacteristicActions.CharacteristicDeleteFailed(message);
    }
    else {
        this.layoutUtilsService.showActionNotification("la caractéristique à été  supprimé avec succès!", MessageType.Update, 10000, true, true);

        return CharacteristicActions.CharacteristicDeletedSuccessfully({characteristicId: characteristicId})
    }
    }
}
