import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationModel} from "../../../../../core/ek-e-commerce/ek-models/notification.model";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {RxStompService} from "@stomp/ng2-stompjs";
import {NotificationService} from "../../../../../core/e-commerce";
import {ConnectedPosition} from "@angular/cdk/overlay";
import {NotificationType} from "../../../../../core/e-commerce/_models/notificationType.model";

@Component({
  selector: 'kt-out-of-stock-notifications',
  templateUrl: './out-of-stock-notifications.component.html',
  styleUrls: ['./out-of-stock-notifications.component.scss']
})
export class OutOfStockNotificationsComponent implements OnInit {

  // Show dot on top of the icon
  @Input() dot: string;
  show:boolean=false;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';
  @Output() emitShow =  new EventEmitter<boolean>();

  @Input() type: 'brand' | 'success' = 'success';
//	private readonly notificationsSubject = new BehaviorSubject<NotificationModel[]>([]);

  oosNotifications: NotificationModel[]=[]; //Out Of Stock

  // Show pulse on icon
  pulse$ = new BehaviorSubject(false);
  public notOpenedOutOfStock$ = new BehaviorSubject(0);
  private notOpenedOutOfStock: number=0;


  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  constructor(private sanitizer: DomSanitizer, private rxStompService: RxStompService, private notificationService: NotificationService) {
  }

  ngOnInit() {

    this.findAllOutOfStock();

  }

  onSendMessage() {
    const message = `Message generated at ${new Date}`;
    this.rxStompService.publish({destination: '/topic/orders', body: message});
  }

  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }

    return 'url(' + this.bgImage + ')';
  }

  open(id: number, opened: boolean){
    this.notificationService.changeOpened(id, true).subscribe();
  }

  findAllOutOfStock(){
    this.notificationService.FindNotificationsByType(NotificationType.PRODUCT_OUT_OF_STOCK).subscribe(
        notifications=>{
          this.oosNotifications= notifications;
          this.notOpenedOutOfStock = notifications.length;
          this.notOpenedOutOfStock$.next(this.notOpenedOutOfStock);
          this.notificationService.outOfStock$.next(this.notOpenedOutOfStock);
    }
    )
  }

}
