import { EkSnackbarComponent } from './../../../../../../../../core/ek-e-commerce/ek-snackbar/ek-snackbar.component';
import { DocumentService } from './../../../../../../../../core/services/document.service';
import { SectionType } from './../../../../../../../../core/ek-e-commerce/ek-models/section-type.model';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from "@angular/common";
import { EkSnackBarService } from './../../../../../../../../core/services/ek-snackBar.service';
import {log} from "util";

const DATA: string[] = [
  'CIVIL_STATE',
  'ACCOUNT_OPENING_FORM',
  'FINANCIAL_FOLDER',
  'PROFESSIONAL_AND_FINANCIAL_DOCUMENTS',
  'FINANCE_OBJECT',
  'GARANTIES',
  'DOCS_CREMBA',
  'JPEG_FILES',


];

@Component({
  selector: 'kt-download-file-dialogu',
  templateUrl: './download-file-dialogu.component.html',
  styleUrls: ['./download-file-dialogu.component.scss']
})


export class DownloadFileDialoguComponent implements OnInit {
  displayedColumns: string[] = ['designation' , 'action'];
  dataSource = new MatTableDataSource<any>(null);

  isExporting: { [key: string]: boolean } = {};

  constructor(
      public dialogRef: MatDialogRef<DownloadFileDialoguComponent>,
      @Inject(MAT_DIALOG_DATA) public dataFromMain: any,
      private documentService: DocumentService,
      private EkSnackBar: EkSnackBarService) { }

  ngOnInit(): void {
    this.dataSource.data = DATA;
  }


  downloadFiles(item: SectionType, itemIndex : number): void {

    this.isExporting[item] = true;

    this.documentService.downloadFiles(this.dataFromMain.idFolder, item).subscribe({
      next : (res => {
        if(Object.keys(res.data).length !== 0) {
          const key = Object.keys(res.data);
          const value = Object.values(res.data);
          if(key[0].includes('pdf')){
            this.downloadPdf(value[0], key[0], this.dataFromMain.requestNumber)
            let msg = "Documents téléchargés avec succès";
            this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
            this.isExporting[item] = false;
          } else {
            this.downloadJpeg(value[0])
            this.isExporting[item] = false;
            let msg = "Documents téléchargés avec succès";
            this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
          }
        } else {
          let msg = "Aucun document à télécharger";
          this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
          this.isExporting[item] = false;
        }
      }),
      error : (err) => {
        let msg = "Une érreur est survenu, veuillez réessayer";
        this.EkSnackBar.openSnackBar(msg, "ok", "error-snackbar");
        this.isExporting[item] = false;
      }
    })
  }

  isAnyItemExporting(): boolean {
    // Check if any item is exporting
    return Object.values(this.isExporting).some(value => value);
  }

  close() {
    this.dialogRef.close();
  }
  downloadPdf(item: any, key : any , requestNumber: string) {
    const byteCharacters = window.atob(item);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers [i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const nav = (window.navigator as any);
    const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    const modifiedString = key.slice(0, - 10 );
    anchor.download = modifiedString + requestNumber  + '.pdf';
    anchor.href = url;

    anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    this.isExporting[item] = false;
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }, 100);
  }
  downloadJpeg(item: any) {
    const byteCharacters = atob(item);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'image.jpeg';

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  }

}
