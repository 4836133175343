import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ConfigurationModel} from "../ek-models/configuration.model";
import {Observable, of} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {catchError, map} from "rxjs/operators";
import {Page} from "../../_base/crud/models/page";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {CategoryModel} from "../ek-models/category.model";
import {OptimisedTrendingConfModel} from "../../e-commerce/_models/optimised-trending-conf.model";
import {DocumentModel} from "../ek-models/document.model";


@Injectable()
export class ConfigurationService extends AbstractService<ConfigurationModel> {

    url: string;
    url2: string;
    url3: string;

    constructor(protected http: HttpClient,
                private layoutUtilsService: LayoutUtilsService,) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}product-configurations`;
        this.url2 = `${ADMIN_CONFIG.apiEndpoint}products`
        this.url3 = `${ADMIN_CONFIG.apiEndpoint}EK/product-configurations`
    }

    public getActivatedConfiguration(): Observable<ConfigurationModel[]>{
        const url = `${this.url}`;
        return this.http.get<Response<ConfigurationModel[]>>(url).pipe(map(({body}) => body));
    }


    public uploadManufacturingCertificate(file: File, confId:number) {
        const url = `${this.url}/certificate/${confId}`;
        const formData = new FormData();
        formData.append("file", file, file.name);
        return this.http.patch<Response<any>>(url, formData);
    }

    public getByProductId(id: number): Observable<ConfigurationModel[]> {
        const url = `${this.url}/product/${id}`;
        return this.http.get<Response<ConfigurationModel[]>>(url).pipe(map(({body}) => body));
    }

    public upload(formData) {
        const url = `${this.url2}/`
        const headers = new HttpHeaders({"Content-Type": "multipart/form-data"})

        return this.http.post<any>(url, formData, {
            reportProgress: true,
            observe: 'events'
        })
    }

    public ExportExcelProducts(): Observable<Blob> {
        const url = `${this.url2}/export/excel`;
        const headers = new HttpHeaders({"Content-Type": "application/octet-stream"})
        return this.http.get(url, {headers, responseType: 'blob'});
    }

    public updateActivations(activation: boolean, config: ConfigurationModel[]): Observable<Response<ConfigurationModel[]>> {
        // let config = new Array<ConfigurationModel>();
        let httpParams = new HttpParams();

        httpParams = httpParams.append('activation', String(activation))
        config.forEach(configuration => {
            httpParams = httpParams.append('configurationIds', String(configuration.id));
        });
        const options = {
            params: httpParams
        };
        const url = `${this.url}/update-activations`;
        return this.http.get<Response<ConfigurationModel[]>>(url, options).pipe(
            map((response: Response<ConfigurationModel[]>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public changeActivateProduct(id: number, activate: boolean,updater:string, event: any): Observable<Response<ConfigurationModel>> {
        let message;
        const options = {
            params: new HttpParams()
                .append('activation', String(activate))
                .append('configurationId', String(id))
                .append('updater',String(updater))
        };
        const url = `${this.url}/update-activation`

        return this.http.get<Response<ConfigurationModel>>(url, options);
    }

    public changeDisplay(configurationId: number, display: boolean, updater: string): Observable<OptimisedTrendingConfModel> {
        const url = `${this.url}/${configurationId}/${display}/${updater}`;
        return this.http.patch<Response<OptimisedTrendingConfModel>>(url, "").pipe(
            map((response: Response<OptimisedTrendingConfModel>) => {
                if (response.body) {
                    return response.body;
                } else {
                    throw new Error();
                }
            })
        );
    }


    public getTrending(pageNumber: number = 0, pageSize: number = 10, display: boolean): Observable<Page<OptimisedTrendingConfModel>> {
        let params = new HttpParams();
        params = params.append('pageNumber', pageNumber ? pageNumber.toString() : '0');
        params = params.append('pageSize', pageSize ? pageSize.toString() : '10');
        params = display != null ? params.append('display',  String(display)) : params;
        const options = { params };
        const url = `${this.url}/trending`;
        return this.http.get<Response<Page<OptimisedTrendingConfModel>>>(url, options).pipe(map(({body}) => body));
    }

    public getBestSellers(pageNumber: number = 0, pageSize: number = 100, display: boolean): Observable<Response<Page<ConfigurationModel>>> {
        var options = {
            params: new HttpParams()
                .append('pageNumber', pageNumber.toString())
                .append('pageSize', pageSize.toString())
        };
        if (display != null)
            options = {
                params: new HttpParams()
                    .append('pageNumber', pageNumber.toString())
                    .append('pageSize', pageSize.toString())
                    .append('display', String(display))
            };
        const url = `${this.url}/best-sellers`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, options);
    }

    public getNew(pageNumber: number = 0, pageSize: number = 100): Observable<Response<Page<ConfigurationModel>>> {
        const options = {
            params: new HttpParams()
                .append('pageNumber', pageNumber.toString())
                .append('pageSize', pageSize.toString())
        };
        const url = `${this.url}/new`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, options);
    }
    public getAllDiscountPagesSearch(query: string = '',  sorting: string, direction: string = '', pageNumber: number = 0, pageSize: number = 100, date1:string ,date2:string,state:string): Observable<Page<ConfigurationModel>>{


        let params = new HttpParams();

        params = query ? params.append('query', query) : params;

        params = direction ? params.append('direction', direction) : params;
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sorting', sorting.toString());
        params = date1 ? params.append('startingDate', date1) : params;

        params = date2 ? params.append('endingDate', date2) : params;
        params=params.append('status',state)

        const url = `${this.url}/searchDiscount`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, {params}).pipe(map(({body}) => body));
    }

    public filterByStatus(page: number = 0, size: number = 100, sorting: string, direction: string, productState: String): Observable<Page<ConfigurationModel>> {
        const options = {
            params: new HttpParams()
                .append('direction', direction)
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('productState', productState.toString())
        };
        const url = `${this.url}/find-by-state`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, options).pipe(map(({body}) => body));
    }

    public filterByActive(page: number = 0, size: number = 100, sorting: string, direction: string, active: boolean): Observable<Page<ConfigurationModel>> {
        let options = {
            params: new HttpParams()
                .append('direction', direction)
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
        };
        if (active!=null)
            options = {
                params: new HttpParams()
                    .append('direction', direction)
                    .append('pageNo', page.toString())
                    .append('pageSize', size.toString())
                    .append('active', active.toString())
            };
        const url = `${this.url}/find-by-active`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, options).pipe(map(({body}) => body));
    }

    public filterByDate(direction: string,sortBy: string ,page: number = 0, size: number = 100, startDate: string, endDate: string): Observable<Page<ConfigurationModel>> {
        const options = {

            params: new HttpParams()
                .append('direction', direction)
                .append('sortBy', sortBy)
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('startDate',startDate)
                .append('endDate',endDate)
        };
        const url = `${this.url}/dates`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, options).pipe(map(({body}) => body));
    }

    public searchsorted(direction: string,sortBy: string ,page: number = 0, size: number = 100, query: any,categoryIdFiler:number): Observable<Page<ConfigurationModel>> {
        let params = new HttpParams()
            .append('direction', direction)
            .append('sortBy', sortBy)
            .append('pageNo', page.toString())
            .append('pageSize', size.toString())
            .append('query', query.query)
            .append('categoryId',(categoryIdFiler!=null) ? String(categoryIdFiler) : '')

        // .append('sortBy', sorting)

        const url = `${this.url}/search`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, {params}).pipe(map(({body}) => body));
    }
    public nameExists(name: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('name', name);
        const url = `${this.url}/name`;
        return this.http.get<Response<boolean>>(url, {params: params}).pipe(
            map((e) => {
                return e.body != null;
            }))
    }

    public generateReference() {
        const url = `${this.url}/reference`;
        return this.http.get(url);}

    public add(entity: ConfigurationModel): Observable<ConfigurationModel> {
        let url;
        if (ADMIN_CONFIG.apiEndpoint=='https://adminapi.wissalstore.com:442/'){
            url = 'https://api.wissalstore.com:444'+'/product-configurations'
        }
        else if(ADMIN_CONFIG.apiEndpoint=='http://devapi.wissalstore.com:8080/'){

            url = 'http://devapi.wissalstore.com'+'/product-configurations'
        }
        else{
            url = this.url;
        }
        const res = this.http.post<Response<ConfigurationModel>>(url, entity).pipe(map(({body}) => body));

        return res;
    }



    public search(page: number = 0, size: number = 100, sorting: string, direction: string, query: string, active :boolean,categoryId:number, includeInOrderSplit: boolean): Observable<Page<ConfigurationModel>> {



        let  params = new HttpParams();
        params =   params.append('pageNo', page.toString());
        params=  params.append('pageSize', size.toString());
        params= params.append('sortBy', sorting);
        params=   params.append('direction', direction);
        params=  params .append('query', query);
        params =  active !== null ?params.append('active',String(active)):params;
        params =  includeInOrderSplit !== null ?params.append('includeInOrderSplit',String(includeInOrderSplit)):params;
        params = categoryId? params.append('categoryId',categoryId.toString()):params;

        const url = `${this.url}/searchAll`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, {params}).pipe(map(({body}) => body));
    }

    public getAllVenteFlashPagesSearch(query: string = '',  sorting: string, direction: string = '', pageNumber: number = 0, pageSize: number = 100, date1:string ,date2:string,active:string): Observable<Page<ConfigurationModel>>{

        let params = new HttpParams();

        params = query ? params.append('query', query) : params;

        params = direction ? params.append('direction', direction) : params;
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sorting', sorting.toString());
        params = date1 ? params.append('startingDate', date1) : params;

        params = date2 ? params.append('endingDate', date2) : params;
        params = date2 ? params.append('active', active) : params;

        const url = `${this.url}/admin/ventFLASH`;
        return this.http.get<Response<Page<ConfigurationModel>>>(url, {params}).pipe(map(({body}) => body));
    }

    public changeActivatePromotion(discountId:  number  , activate: boolean,): Observable<Response<ConfigurationModel>> {
        const url = `${this.url}/updateDiscountStatus`;
        let  params = new HttpParams();
        params =   params.append('discountId', discountId.toString()).append('status', activate.toString());
        // params=  params.append('status', activate.toString());

        return this.http.patch<Response<ConfigurationModel>>(url,'',{params}).pipe(
            map((response: Response<ConfigurationModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public ExportExcelPromotionsMonthly(date:Date): Observable<Response<any>> {
        const url = `${this.url}/export/excel/${date}`;
        let  params = new HttpParams();
        params =   params.append('date', date.toString());

        return this.http.get<Response<any>>(url);
    }

    public displayInguichet(configurationId: number, display: boolean): Observable<boolean> {

        const url = `${this.url3}/display`;
        let  params = new HttpParams();
        params =   params.append('configurationId', configurationId.toString());
        params =   params.append('active', display.toString());
        return this.http.patch<Response<boolean>>(url, '',{params}).pipe(
            map((response: Response<boolean>) => {
                if (response.body) {
                    return response.body;
                } else {
                    throw new Error();
                }
            })
        );
    }
}
