import { Component, OnInit } from '@angular/core';
import {DisplayService} from "../../../../../../core/e-commerce/_services/display.service";
import {DisplayHomeSections} from "../../../../../../core/e-commerce/_models/display-home-sections";
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl} from "@angular/forms";

@Component({
  selector: 'kt-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {
    editDiscountLine :number= -1;
    formDisplay: FormControl=new FormControl();
    displayedColumns: string[] = ['id','displayName','action'];
    dataSource = new MatTableDataSource<DisplayHomeSections>();
    currentRole = '';

  constructor(private  displayService :DisplayService,private formBuilder: FormBuilder,
  ) {


  }

  ngOnInit(): void {
      this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
      this.displayService.getAll().subscribe(res=>{
          this.dataSource.data=res;
      })

  }

  onChange(event :any, display :DisplayHomeSections){
      display.display=event.checked;


      this.displayService.updateDisplay(display.id , display.display).subscribe(
          res=>res);
  }


    update(item){

        item.displayName=this.formDisplay.value;
        this.displayService.updateName(item.id , item.displayName ).subscribe(res=>res );
        this.editDiscountLine=-1;
        this.formDisplay.reset();
    }

    editDisplay (item:DisplayHomeSections){
        this.formDisplay.setValue(item.displayName);
        this.editDiscountLine=item.id;

    }
}
