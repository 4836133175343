export const ELSALAM_ENVIRONMENTS = {
    productionUrl: "https://alsalamalgeria.admin.ekiclik.dz",
    devUrl: "https://devadmin.ekiclik.com",
    stagingUrl: "https://staging.admin.ekiclik.com",
    localHostUrl: "http://localhost:4200",
};


export const PDVA_ENVIRONMENTS = {
    productionUrl: "https://pdva.ekiclik.com",
    stagingUrl: "https://pdva-stg.ekiclik.com",
    devUrl: "https://pdva-dev.ekiclik.com",
    localHostUrl: "http://localhost:4200",
};