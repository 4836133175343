import {Component, Input, OnInit} from '@angular/core';
import {ScoringModel} from "../../../../../../../core/ek-e-commerce/ek-models/scoring.model";

@Component({
  selector: 'kt-scoring-table',
  templateUrl: './scoring-table.component.html',
  styleUrls: ['./scoring-table.component.scss']
})
export class ScoringTableComponent implements OnInit {

  @Input () folderScore : ScoringModel;

  constructor() { }

  ngOnInit(): void {
  }

}
