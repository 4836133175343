import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'genderTranslate'
})
export class GenderTranslatePipe implements PipeTransform {

    transform(documentGender: string): string {
        let docName = '';
        switch (documentGender) {
            case 'FAMILY_SHEET':
                docName = 'Fiche Familliale';
                break;
            case 'FRONT_IDENTITY_CARD':
                docName = 'Carte d\'identité (recto)';
                break;
            case 'BACK_IDENTITY_CARD':
                docName = 'Carte d\'identité (verso)';
                break;
            case 'PHOTO_IDENTITY':
                docName = 'Photo d\'identité';
                break;
            case 'BIRTH_ACT':
                docName = 'Extrait de naissance';
                break;
            case 'RESIDENCE':
                docName = 'Residence';
                break;
            case 'PAY_SLIP':
                docName = 'Fiche de paie 1';
                break;
            case 'PAYSLIP_TWO':
                docName = 'Fiche de paie 2';
                break;
            case 'PAYSLIP_THREE':
                docName = 'Fiche de paie 3';
                break;
            case 'CROSSED_CHECK':
                docName = 'Chèque barré';
                break;
            case 'WORK_CERTIFICATE':
                docName = 'Attestation de travail';
                break;
            case 'BANK_STATEMENT':
                docName = 'Relevé bancaire';
                break;
            case 'CARD_CHIFA':
                docName = 'Carte chiffa';
                break;
            case 'INCOME_CERTIFICATE':
                docName = 'Attestation de Revenue';
                break;
            case 'TERMINATION_CERTIFICATE':
                docName = 'Attestation Radiation';
                break;
            case 'CORP_PRESENCE':
                docName = 'Présence au Corp';
                break;
            case 'RENA':
                docName = 'RENA';
                break;
            case 'FEES_STATEMENT':
                docName = 'Relevé des emoluments';
                break;
        }
        return docName;
    }

}
