// Anglar
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// Layout Directives
// Services
import {
    ContentAnimateDirective,
    FirstLetterPipe,
    RoundUpPipe,
    MonthlyPaymentPipe,
    RoundUpTo5,
    GetObjectPipe,
    HeaderDirective,
    IsUsedConverter,
    JoinPipe,
    MenuDirective,
    OffcanvasDirective,
    SafePipe,
    ScrollTopDirective,
    SparklineChartDirective,
    StickyDirective,
    TabClickEventDirective,
    TimeElapsedPipe,
    ToggleDirective,
    PhoneNumberMaxLengthDirective,
    OnlyNumbersDirective,
    DiscountRemainingDays,
    OnlyXdegitisDirective,
    ArabicInputDirective
} from './_base/layout';
import {UserService} from "./services/user.service";
import {JwtInterceptor} from "./_base/crud/utils/jwt.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {CustomersService} from "./ek-e-commerce/ek-services/customers.service";
import {AddressesService} from "./ek-e-commerce/ek-services/addresses.service";
import {CategoriesService} from "./ek-e-commerce/ek-services/categories.service";
import {BrandsService} from "./ek-e-commerce/ek-services/brands.service";
import {ConfigurationService} from "./ek-e-commerce/ek-services/confguration.service";
import {ConfigurationCharacteristicsService} from "./ek-e-commerce/ek-services/configuration-characteristics.service";
import {CharacteristicsService} from "./ek-e-commerce/ek-services/characteristics.service";
import {DiscountsService} from "./e-commerce/_services/discounts.service";
import {ImagesService} from "./ek-e-commerce/ek-services/images.service";
import {OrdersService} from "./e-commerce/_services/orders.service";
import {CarouselsService} from "./home-page/carousel/carousels.service";
import {OrderproductsService} from "./e-commerce/_services/orderproducts.service";
import {DeliveryAddressService} from "./e-commerce/_services/delivery-address.service";
import {CallService} from "./e-commerce/_services/calls.service";
import {DeliveryService} from "./e-commerce/_services/delivery.service";
import {FooterInformationService} from "./e-commerce/_services/footer-information.service";
import { EffectsModule } from '@ngrx/effects';
import { RegionEffects } from './e-commerce/_effects/region.effects';
import { ParcelEffects } from './e-commerce/_effects/parcel.effects';
import {ParcelStatesEffects} from "./e-commerce/_effects/parcelStates.effects";
import {CollaboratorService} from "./e-commerce/_services/collaborator.service";
import {CommentService} from "./e-commerce/_services/comment.service";
import {CustomerDiscountService} from "./e-commerce/_services/customer-discount.service";
import {FoldersService} from "./ek-e-commerce/ek-services/folders.service";
import {ClientsService} from "./ek-e-commerce/ek-services/clients.service";
import {EkFooterInformationService} from "./ek-e-commerce/ek-services/ek-footer-information.service";
import { FilterUrlPipe } from './_base/layout/pipes/filter-url.pipe';
import { GetIdPipe } from './_base/layout/pipes/get-id.pipe';
import { DragDirective } from './_base/layout/directives/drag.directive';
import {ClientOcrInformationService} from "./ek-e-commerce/ek-services/clientOcrInformation.service";
import {GenderTranslatePipe} from "./_base/layout/pipes/gender-translate.pipe";
import {ChecklistfilesTranslatePipe} from "./_base/layout/pipes/checklistfiles-translate.pipe";
import {FilterStatePipe} from "./_base/layout/pipes/filter-state.pipe";
import { DecimalFormatPipe } from './_base/layout/pipes/decimal-format.pipe';
import { TranslateFolderStatePipe } from './_base/layout/pipes/translate-folder-state.pipe';
import { ActivityTranslatePipe } from './_base/layout/pipes/domain-translate.pipe';
import { ProfessionTranslatePipe } from './_base/layout/pipes/profession-translate.pipe';
import { ScoringExceptionsInterpretPipe } from './_base/layout/pipes/scoring-exceptions-interpret.pipe';

import {SmsHistoryService} from "./ek-e-commerce/ek-services/smsHistory.service";
import {OrderStatePipe} from "./_base/layout/pipes/order-state.pipe";
import {TranslateFolderOrderStatePipe} from "./_base/layout/pipes/translate-folder-order-state.pipe";
import { TranslateNotificationPipe } from './_base/layout/pipes/translate-notification.pipe';
import {GlobalVariableService} from "./ek-e-commerce/ek-services/global-variable.service";
import { NumberInputDirective } from './_base/layout/directives/number-input.directive';
import {NameRolePipe} from "./_base/layout/pipes/name-role.pipe";

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([RegionEffects, ParcelEffects,ParcelStatesEffects])],
    declarations: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        PhoneNumberMaxLengthDirective,
        ArabicInputDirective,
        OnlyXdegitisDirective,
        OnlyNumbersDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        IsUsedConverter,
        FirstLetterPipe,
        RoundUpPipe,
        RoundUpTo5,
        DiscountRemainingDays,
        MonthlyPaymentPipe,
        FilterUrlPipe,
        FilterStatePipe,
        GetIdPipe,
        DragDirective,
        GenderTranslatePipe,
        ChecklistfilesTranslatePipe,
        OrderStatePipe,
        DecimalFormatPipe,
        TranslateFolderStatePipe,
        TranslateFolderOrderStatePipe,
        ProfessionTranslatePipe,
        ActivityTranslatePipe,
        ScoringExceptionsInterpretPipe,
        TranslateNotificationPipe,
        NumberInputDirective,
        NameRolePipe,
    ],
    exports: [
        // directives
        ScrollTopDirective,
        ProfessionTranslatePipe,
        HeaderDirective,
        ActivityTranslatePipe,
        PhoneNumberMaxLengthDirective,
        ArabicInputDirective,
        OnlyXdegitisDirective,
        OnlyNumbersDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        IsUsedConverter,
        FirstLetterPipe,
        RoundUpPipe,
        RoundUpTo5,
        DiscountRemainingDays,
        MonthlyPaymentPipe,
        FilterUrlPipe,
        FilterStatePipe,
        DecimalFormatPipe,
        GetIdPipe,
        GenderTranslatePipe,
        ChecklistfilesTranslatePipe,
        OrderStatePipe,
        DragDirective,
        ChecklistfilesTranslatePipe,
        TranslateFolderStatePipe,
        TranslateFolderOrderStatePipe,
        TranslateNotificationPipe,
        NumberInputDirective,
        NameRolePipe,
    ],
    providers: [
        CommentService,
        CustomerDiscountService,
        OrderproductsService,
        UserService,
        CustomersService,
        AddressesService,
        CategoriesService,
        BrandsService,
        CollaboratorService,
        ConfigurationService,
        FooterInformationService,
        CharacteristicsService,
        ConfigurationCharacteristicsService,
        DiscountsService,
        ImagesService,
        OrdersService,
        CallService,
        CarouselsService,
        DeliveryAddressService,
        DeliveryService,
        FoldersService,
        ClientsService,
        EkFooterInformationService,
        ClientOcrInformationService,
        SmsHistoryService,
        GlobalVariableService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    ]
})
export class CoreModule {
}
