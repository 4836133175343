import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FoldersService} from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {ActivatedRoute} from "@angular/router";
import {ScoringModel} from "../../../../../../core/ek-e-commerce/ek-models/scoring.model";
import {BehaviorSubject} from "rxjs";
import {
    ScoringExceptionsInterpretPipe
} from "../../../../../../core/_base/layout/pipes/scoring-exceptions-interpret.pipe";

@Component({
    selector: 'kt-scoring',
    templateUrl: './scoring.component.html',
    styleUrls: ['./scoring.component.scss'],
    providers: [ScoringExceptionsInterpretPipe]
})
export class ScoringComponent implements OnInit {

    isOpened = false;
    idFolder = 1;
    folderScoring: ScoringModel;
    errorMsg = " ";
    scoringSubject$ = new BehaviorSubject<ScoringModel>(null);
    readonly folderScoring$ = this.scoringSubject$.asObservable();

    constructor(private folderService: FoldersService,
                private activatedRoute: ActivatedRoute,
                private cdRef: ChangeDetectorRef,
                private scoringExceptionsInterpretPipe: ScoringExceptionsInterpretPipe
    ) {
    }

    ngOnInit(): void {

        this.activatedRoute.paramMap.subscribe((params) => {
            this.idFolder = Number(params.get("idFolder"));
        });

        //scoring details
        this.getScoringByIdFolder();

    }


    getScoringByIdFolder() {
        this.folderService.folderScoring(this.idFolder).subscribe({
            next: (response) => {
                this.folderScoring = response.data;
                this.scoringSubject$.next(response.data);
                this.cdRef.detectChanges();
            },
            error: (error) => {
                if (error.error.message) {
                    this.errorMsg = this.scoringExceptionsInterpretPipe.transform(error.error.message);
                }
                this.cdRef.detectChanges();
            }
        })
    }

}
