import { BaseModel } from '../../_base/crud';
import {BrandModel} from "./brand.model";
import {ConfigurationModel} from "./configuration.model";

export class ProductModel extends BaseModel {
	id: number;
	name: string;
	description: string;
	categoryId: number;
	brand: BrandModel;
	refeConstructor: string;
	referenc: string;
	codEAN: string;
	activate: boolean;
	configurations?: ConfigurationModel[];
	script: string;
	updater: string;
	target: string; //CASH, SPLIT_PAYMENT
	manufactureName?:string;

	clear() {
		this.name = '';
		this.description = '';
		this.brand = new BrandModel();
		this.refeConstructor = '';
		this.referenc = '';
		this.codEAN = '';
		this.brand.clear();
		this.activate = true;
		this.manufactureName = '';
	}
}
