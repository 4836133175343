import {Component, Inject, OnInit} from '@angular/core';
import wilaya from '../../../../../../../assets/JSON/Wilaya_Of_Algeria.json';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'kt-wilaya-list-pop-up',
    templateUrl: './wilaya-list-pop-up.component.html',
    styleUrls: ['./wilaya-list-pop-up.component.scss']
})
export class WilayaListPopUpComponent implements OnInit {

    wilayaList = wilaya;
    selectedWilayas: string [] = []; // Array to store the indices of selected wilayas
    preSelectedWilaya = [];

    constructor(private dialogRef: MatDialogRef<WilayaListPopUpComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if (this.data?.wilayaList) {
            this.selectedWilayas = this.data.wilayaList;
        }

        this.preSelectedWilaya = JSON.parse(localStorage.getItem('preSelectedWilaya'));
    }


    toggleWilaya(selectedWilaya: any) {
        const selectedIndex = this.selectedWilayas.indexOf(selectedWilaya.value);
        if (selectedIndex === -1) {
            // If the wilaya is not already selected, add it to the array
            this.selectedWilayas.push(selectedWilaya.value);
        } else {
            // If the wilaya is already selected, remove it from the array
            this.selectedWilayas = this.selectedWilayas.filter(item => item !== selectedWilaya.value);
        }
    }

    isWilayaSelected(wilaya: any): boolean {
        if (this.data?.wilayaList) {
            return this.data.wilayaList.includes(wilaya.value);
        } else {
            // Check if the wilaya is selected
            return this.selectedWilayas.includes(wilaya.value);
        }
    }

    save() {
        console.log('ici', this.selectedWilayas);
        this.dialogRef.close(this.selectedWilayas);
    }

    close() {
        this.dialogRef.close();
    }

    isWilayaAlreadySelected(wilaya: any): boolean {
        return this.preSelectedWilaya.includes(wilaya.value) && !this.data.wilayaList.includes(wilaya.value);
    }

}
